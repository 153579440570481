<template>
  <b-notification :type="klass" :closable="false">
    {{ title }}
  </b-notification>
</template>

<script>
export default {
  props: {
    klass: {
      type: String,
      default: 'is-info'
    },
    title: {
      type: String,
      required: true
    }
  }
}
</script>
