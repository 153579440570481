import page from '../js/page.js'
import Vue from 'vue'
import VueI18n from 'vue-i18n'
import messages from '../js/locales.pl.js'
import Buefy from 'buefy'
import api from '../js/api/AppApi'
import WelcomeSearch from '../components/WelcomeSearch.vue'

Vue.use(Buefy, {
  defaultIconPack: 'fas'
})

document.addEventListener('DOMContentLoaded', () => {
  if (page.notControlledBy('welcome', 'index')) {
    return
  }

  const $navbarBurgers = Array.prototype.slice.call(document.querySelectorAll('.navbar-burger'), 0)
  if ($navbarBurgers.length > 0) {
    $navbarBurgers.forEach(el => {
      el.addEventListener('click', () => {
        const target = el.dataset.target
        const $target = document.getElementById(target)
        el.classList.toggle('is-active')
        $target.classList.toggle('is-active')
      })
    })
  }

  Vue.use(VueI18n)

  const i18n = new VueI18n({
    locale: 'pl',
    messages
  })

  const app = new Vue({
    i18n,
    api,
    render: h => h(WelcomeSearch)
  }).$mount()
  document.getElementById('welcome-search').appendChild(app.$el)
})
