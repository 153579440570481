import { ToastProgrammatic as Toast } from 'buefy'

document.addEventListener('DOMContentLoaded', () => {
  var flash = document.getElementsByClassName('flash')[0]
  if (flash !== undefined) {
    const message = flash.innerText
    const isAlarm = flash.classList.contains('alert')
    if (isAlarm === true) {
      Toast.open({
        message: message,
        type: 'is-danger',
        duration: 5000
      })
    } else {
      Toast.open({
        message: message,
        type: 'is-info',
        duration: 5000
      })
    }
  }
})
