<template>
  <form action="/users/sign_out" method="post" class="logout-form">
    <input type="hidden" name="authenticity_token" :value="authenticity_token">
    <b-button
      icon-left="sign-out-alt"
      native-type="submit"
      type="is-warning is-fullwidth"
    >
      {{ $t('logout') }}
    </b-button>
  </form>
</template>
<script>

export default {
  data: function () {
    return {
      authenticity_token: ''
    }
  },
  mounted () {
    this.authenticity_token = document.querySelectorAll('meta[name=csrf-token]')[0].getAttribute('content')
  }
}
</script>
<style scoped>
</style>
