<template>
  <div class="action-buttons">
    <div class="left">
      <button v-can:modify="order" :disabled="formInvalid" class="button is-info" @click="$emit('update')">
        <b-icon
          icon="save"
          size="is-small"
        />
        <span>
          {{ $t('order.actions.update') }}
        </span>
      </button>
      <button v-if="!order.id" :disabled="formInvalid" class="button is-info" @click="$emit('create')">
        <b-icon
          icon="plus-circle"
          size="is-small"
        />
        <span>
          {{ $t('order.actions.create') }}
        </span>
      </button>
      <b-button
        v-for="state in order.valid_states"
        :key="state"
        v-can:manage="'Order'"
        :icon-left="icons[state]"
        :class="`status-${state}`"
        @click="$emit('state-change', state)"
      >
        {{ $t(`order.statuses.${state}`) }}
      </b-button>
    </div>
    <div class="right">
      <button v-can:cancel="order" class="button is-danger" @click="$emit('cancel')">
        <b-icon
          icon="trash"
          size="is-small"
        />
        <span>
          {{ $t('order.actions.cancel') }}
        </span>
      </button>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { orderStatusIcons } from 'js/constants'

export default {
  props: {
    order: {
      type: Object,
      required: true
    },
    vobj: {
      type: Object,
      required: true
    }
  },
  computed: {
    ...mapState(['user']),
    formInvalid () {
      return this.vobj.$invalid
    },
    icons () {
      return orderStatusIcons
    }
  }
}
</script>
