import Vue from 'vue'
import Vuex from 'vuex'

import appApi from '../js/api/AppApi'
import bundles from './modules/bundles'
import map from './modules/map'
import orders from './modules/orders'
import tables from './modules/tables'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    bundles,
    orders,
    map,
    tables
  },
  state: {
    userLoaded: false,
    userMeta: {},
    user: {
      admin: false,
      username: null
    }
  },
  mutations: {
    setUser (state, payload) {
      state.user = payload.data.attributes
      state.userMeta = payload.meta
      state.userLoaded = true
    }
  },
  actions: {
    fetchUser ({ commit }) {
      appApi.currentUser(
        (response) => {
          commit('setUser', response)
        }
      )
    }
  }
})
