<template>
  <div class="field">
    <div class="control">
      <FormLabel :attr="field" :required="obligatory" :scope="scope" />
      <input class="input" :class="{ 'is-danger': vobj.$error }" :type="type" :value="value" :placeholder="$t('form.enterString')" @input="$emit('input', $event.target.value)">
    </div>
    <FieldErrors :vform="vform" :field="field" />
  </div>
</template>

<script>
import FormLabel from './FormLabel'
import FieldErrors from './FieldErrors'

export default {
  components: {
    FormLabel, FieldErrors
  },
  props: {
    value: {
      type: String,
      required: true
    },
    field: {
      type: String,
      required: true
    },
    obligatory: {
      type: Boolean,
      default: false
    },
    vform: {
      type: Object,
      required: true
    },
    type: {
      type: String,
      default: 'text'
    },
    scope: {
      type: String,
      default: 'order'
    }
  },
  computed: {
    vobj () {
      return this.vform[this.field]
    },
    params () {
      return this.vobj.$params
    }
  }
}
</script>
