import Vue from 'vue'
import VueRouter from 'vue-router'

import Account from '../components/AccountForm.vue'
import AdminMap from '../components/AdminMap.vue'
import AppNotifications from '../components/AppNotifications.vue'
import Bundle from '../components/Bundle.vue'
import Bundles from '../components/Bundles.vue'
import Dashboard from '../components/Dashboard.vue'
import DispatchNote from '../components/DispatchNote.vue'
import DispatchNoteProducer from '../components/DispatchNoteProducer.vue'
import DispatchNotes from '../components/DispatchNotes.vue'
import OrderEdit from '../components/OrderEdit.vue'
import OrderNew from '../components/OrderNew.vue'
import Password from '../components/PasswordForm.vue'
import Printnode from '../components/Printnode.vue'
import PrintSettings from '../components/PrintSettings.vue'
import Settings from '../components/Settings.vue'
import SmsService from '../components/SmsService.vue'
import Storein from '../components/Storein.vue'
import Storeout from '../components/Storeout.vue'
import Users from '../components/Users.vue'
import UserEdit from '../components/UserEdit.vue'
import Routes from '../components/Routes.vue'
import Route from '../components/Route.vue'
import Settlement from '../components/Settlement.vue'
import SettlementProducer from '../components/SettlementProducer.vue'
import Settlements from '../components/Settlements.vue'

Vue.use(VueRouter)

const router = new VueRouter({
  routes: [
    { name: 'root', path: '/', redirect: '/dashboard' },
    { name: 'dashboard', path: '/dashboard', component: Dashboard },
    { name: 'orderEdit', path: '/orders/:id', component: OrderEdit, props: true },
    { name: 'orderNew', path: '/orders', component: OrderNew },
    { name: 'account', path: '/account', component: Account },
    { name: 'password', path: '/password', component: Password },
    { name: 'userEdit', path: '/user/:id', component: UserEdit, props: true },
    { name: 'users', path: '/users', component: Users },
    { name: 'map', path: '/map', component: AdminMap },
    { name: 'sms', path: '/sms', component: SmsService },
    { name: 'routes', path: '/routes', component: Routes },
    { name: 'routeEdit', path: '/route/:id', component: Route, props: true },
    { name: 'routeNew', path: '/route', component: Route },
    { name: 'settlements', path: '/settlements', component: Settlements },
    { name: 'settlementProducer', path: '/settlement-producer/:id', component: SettlementProducer, props: true },
    { name: 'settlementEdit', path: '/settlement/:id', component: Settlement, props: true },
    { name: 'settlementNew', path: '/settlement-new/:userId', component: Settlement, props: true },
    { name: 'dispatchNotes', path: '/dispatch-notes', component: DispatchNotes },
    { name: 'dispatchNoteProducer', path: '/dispatch-note-producer/:id', component: DispatchNoteProducer, props: true },
    { name: 'dispatchNoteEdit', path: '/dispatch-note/:id', component: DispatchNote, props: true },
    { name: 'dispatchNoteNew', path: '/dispatch-note-new/:userId', component: DispatchNote, props: true },
    { name: 'storein', path: '/storein', component: Storein },
    { name: 'storeout', path: '/storeout', component: Storeout },
    { name: 'printnode', path: '/printnode', component: Printnode },
    { name: 'settings', path: '/settings', component: Settings },
    { name: 'bundles', path: '/bundles', component: Bundles },
    { name: 'bundleEdit', path: '/bundle/:id', component: Bundle, props: true },
    { name: 'bundleNew', path: '/bundle', component: Bundle },
    { name: 'printSettings', path: '/print-settings', component: PrintSettings },
    { name: 'appNotifications', path: '/app-notifications', component: AppNotifications }
  ]
})

export default router
