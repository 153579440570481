<template>
  <form class="search">
    <div class="field">
      <label class="label">{{ $t('form.search') }}</label>
      <div class="control">
        <input
          v-model="pattern"
          class="input"
          type="text"
          :placeholder="$t('form.enterPattern')"
          @keydown.enter="search"
        >
      </div>
    </div>
    <b-field :label="$t('form.searchFrom')">
      <b-datepicker
        v-model="searchFrom"
        :first-day-of-week="1"
        :show-week-number="true"
        :placeholder="$t('form.selectCreatedFromDate')"
        icon="calendar-day"
        :month-names="monthNames"
        :day-names="dayNames"
      />
    </b-field>
    <b-field :label="$t('form.searchTo')">
      <b-datepicker
        v-model="searchTo"
        :first-day-of-week="1"
        :show-week-number="true"
        :placeholder="$t('form.selectCreatedToDate')"
        icon="calendar-day"
        :month-names="monthNames"
        :day-names="dayNames"
      />
    </b-field>
    <div v-if="statuses" class="field">
      <b-field :label="$t('form.status')" />
      <div v-for="status in orderStatuses" :key="status" class="field">
        <b-radio
          v-model="choosenStatus"
          :native-value="status"
        >
          {{ $t(`order.statuses.${status}`) }}
        </b-radio>
      </div>
    </div>
    <div class="field">
      <b-button
        class="is-fullwidth"
        icon-left="search"
        type="is-primary"
        @click="search"
      >
        {{ $t('form.searchAction') }}
      </b-button>
    </div>
    <div class="field">
      <b-button
        class="is-fullwidth"
        icon-left="backspace"
        type="is-warning"
        @click="clear"
      >
        {{ $t('form.clearAction') }}
      </b-button>
    </div>
  </form>
</template>

<script>
import { FormatDate } from 'mixins/formatDate.js'
import locales from 'js/locales.pl.js'
import { orderStatuses } from 'js/constants.js'

export default {
  mixins: [FormatDate],
  props: {
    statuses: {
      type: Boolean,
      default: false
    }
  },
  data: function () {
    return {
      monthNames: locales.pl.monthNames,
      dayNames: locales.pl.dayNames,
      orderStatuses: ['all', ...orderStatuses],
      pattern: null,
      searchFrom: null,
      searchTo: null,
      choosenStatus: 'all'
    }
  },
  methods: {
    search () {
      const queryObj = {
        pattern: this.pattern,
        searchFrom: this.formatDate(this.searchFrom),
        searchTo: this.formatDate(this.searchTo)
      }
      if (this.statuses) {
        Object.assign(queryObj, { status: this.choosenStatus })
      }
      this.$emit('search', queryObj)
    },
    clear () {
      this.pattern = null
      this.choosenStatus = 'all'
      this.searchFrom = null
      this.searchTo = null
      this.search()
    }
  }
}
</script>

<style scoped>
.search {
  margin-bottom: .75em;
}
</style>
