<template>
  <div class="field">
    <div class="control refid-search-box">
      <input v-model="number" class="input is-large" type="text" :placeholder="$t('welcomeSearch.placeholder')">
    </div>
    <div class="control">
      <a class="button is-info is-large is-fullwidth" @click="search">
        <span class="icon">
          <i class="fas fa-search" />
        </span>
        <span>
          {{ $t('welcomeSearch.action') }}
        </span>
      </a>
    </div>
    <b-modal :active.sync="isModalActive" has-modal-card trap-focus>
      <ModalForm v-bind="modalProps" />
    </b-modal>
  </div>
</template>

<script>
import ModalForm from './WelcomeSearchModal.vue'

export default {
  components: {
    ModalForm
  },
  data () {
    return {
      number: '',
      isModalActive: false,
      fetching: false,
      modalProps: {
        klass: '',
        title: ''
      }
    }
  },
  computed: {
    numberIsPresent () {
      return this.number.length > 10
    }
  },
  methods: {
    search () {
      if (!this.numberIsPresent) {
        this.modalProps.klass = 'is-warning'
        this.modalProps.title = this.$t('welcomeSearch.noNumber')
        this.isModalActive = true
        return
      }
      if (this.fetching) {
        return
      }
      this.fetching = true
      this.$api.searchPackage(this.number,
        (response) => {
          this.modalProps = response.data
          this.isModalActive = true
          this.number = ''
          this.fetching = false
        }
      )
    }
  }
}
</script>

<style scoped>
.refid-search-box {
  margin-bottom: 1em;
}
</style>
