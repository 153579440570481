<template>
  <section class="search mb-3">
    <b-field :label="$t('searchFilter.title')">
      <div class="control">
        <input
          v-model="form.pattern"
          class="input"
          type="text"
          :placeholder="$t('searchFilter.placeholder')"
          @keydown.enter="search"
        >
      </div>
    </b-field>
    <b-field>
      <b-button
        class="is-fullwidth"
        icon-left="search"
        type="is-primary"
        @click="search"
      >
        {{ $t('searchFilter.action') }}
      </b-button>
    </b-field>
    <b-field>
      <b-button
        class="is-fullwidth"
        icon-left="backspace"
        type="is-warning"
        @click="clear"
      >
        {{ $t('searchFilter.clear') }}
      </b-button>
    </b-field>
  </section>
</template>

<script>
import { FormatDate } from 'mixins/formatDate.js'
import locales from 'js/locales.pl.js'
import { orderStatuses } from 'js/constants.js'

const initialForm = () => {
  return {
    pattern: '',
    searchFrom: '',
    searchTo: '',
    choosenStatus: 'all'
  }
}

export default {
  mixins: [FormatDate],
  props: {
    statuses: {
      type: Boolean,
      default: false
    }
  },
  data: function () {
    return {
      monthNames: locales.pl.monthNames,
      dayNames: locales.pl.dayNames,
      orderStatuses: ['all', ...orderStatuses],
      form: initialForm()
    }
  },
  methods: {
    query () {
      const queryObj = {}
      if (this.form.pattern.length > 0) {
        Object.assign(queryObj, { pattern: this.form.pattern })
      }
      return queryObj
    },
    search () {
      this.$emit('search', this.query())
    },
    clear () {
      this.form = initialForm()
      this.search()
    }
  }
}
</script>
