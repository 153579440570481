<template>
  <div class="columns">
    <h1 v-if="record.id" class="is-size-4">
      {{ $t(`${scope}.titles.edit`) }}
    </h1>
    <h1 v-else class="is-size-4">
      {{ $t(`${scope}.titles.new`) }}
    </h1>
  </div>
</template>

<script>
export default {
  props: {
    record: {
      type: Object,
      required: true
    },
    scope: {
      type: String,
      required: true
    }
  }
}
</script>
