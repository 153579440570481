const orderStatuses = ['producer', 'storedin', 'storedout', 'done', 'canceled']

const orderStatusIcons = {
  producer: 'user',
  storedin: 'home',
  storedout: 'truck',
  done: 'check',
  canceled: 'times-circle'
}

const dialogProps = {
  none: {
    klass: '',
    icon: ''
  },
  cancel: {
    klass: 'is-danger',
    icon: 'trash'
  },
  destroy: {
    klass: 'is-danger',
    icon: 'trash'
  },
  removeItems: {
    klass: 'is-warning',
    icon: 'check'
  },
  storeoutItems: {
    klass: 'status-storedout',
    icon: 'truck'
  },
  doneItems: {
    klass: 'status-done',
    icon: 'check'
  },
  stateChange: {
    storedout: {
      klass: 'status-storedout',
      icon: 'truck'
    },
    storedin: {
      klass: 'status-storedin',
      icon: 'home'
    },
    done: {
      klass: 'status-done',
      icon: 'check'
    }
  }
}

const kindOfPayments = [
  {
    id: 'payment_on_delivery',
    value: 'paymentOnDelivery'
  },
  {
    id: 'payment_prepaid',
    value: 'paymentPrepaid'
  }
]

const transportKinds = ['delivery', 'pickup', 'exchange']
const countries = ['pl', 'cz']
const docConfigs = ['doc_default', 'doc_info']

export {
  orderStatuses,
  kindOfPayments,
  dialogProps,
  orderStatusIcons,
  transportKinds,
  countries,
  docConfigs
}
