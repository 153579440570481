<template>
  <div class="container">
    <div class="columns">
      <div class="column is-one-fifth">
        <Counters :items="counters" />
        <Search @search="search" />
      </div>
      <div class="column">
        <b-table
          detailed
          detail-key="id"
          :data="data"
          :loading="loading"
          :paginated="paginated"
          backend-pagination
          pagination-position="both"
          :total="total"
          :per-page="perPage"
          :current-page.sync="page"
          aria-next-label="Next page"
          aria-previous-label="Previous page"
          aria-page-label="Page"
          aria-current-label="Current page"
          backend-sorting
          :default-sort-direction="defaultSortOrder"
          :default-sort="[sortField, sortOrder]"
          @page-change="onPageChange"
          @sort="onSort"
        >
          <b-table-column v-slot="props" field="created_at" :label="$t('users.fields.created_at')" sortable>
            {{ props.row.created_at }}
          </b-table-column>
          <b-table-column v-slot="props" field="email" :label="$t('users.fields.email')" sortable>
            <router-link :to="{ name: 'userEdit', params: { id: props.row.id }}">
              {{ props.row.email }}
              <b-tooltip v-if="props.row.admin ===true" label="Administrator">
                <b-icon
                  class="ml-2"
                  icon="users-cog"
                  size="is-small"
                  type="is-info"
                />
              </b-tooltip>
              <b-tooltip v-if="props.row.manager === true" label="Menadżer">
                <b-icon
                  class="ml-2"
                  icon="truck"
                  size="is-small"
                  type="is-info"
                />
              </b-tooltip>
            </router-link>
          </b-table-column>
          <b-table-column v-slot="props" field="email" :label="$t('users.fields.name')">
            {{ props.row.name }}
          </b-table-column>
          <b-table-column v-slot="props" field="company" :label="$t('users.fields.company')">
            {{ props.row.company }}
          </b-table-column>
          <b-table-column field="confirmed" centered>
            <template slot="header">
              <b-tooltip
                :label="$t('users.fields.confirmed')"
              >
                <b-icon
                  icon="envelope"
                  size="is-small"
                />
              </b-tooltip>
            </template>
            <template #default="props">
              <b-icon
                v-if="props.row.confirmed == true"
                icon="envelope"
                size="is-small"
                type="is-info"
              />
            </template>
          </b-table-column>
          <b-table-column field="configured" :label="$t('users.fields.configured')" centered>
            <template slot="header">
              <b-tooltip
                :label="$t('users.fields.configured')"
              >
                <b-icon
                  icon="cogs"
                  size="is-small"
                />
              </b-tooltip>
            </template>
            <template #default="props">
              <b-icon
                v-if="props.row.configured == true"
                icon="cogs"
                size="is-small"
                type="is-info"
              />
            </template>
          </b-table-column>
          <b-table-column field="orders_count" :label="$t('users.fields.orders_count')" centered>
            <template slot="header">
              <b-tooltip
                :label="$t('users.fields.culture_counters')"
              >
                <b-icon
                  icon="box-open"
                  size="is-small"
                />
              </b-tooltip>
            </template>
            <template #default="props">
              {{ props.row.orders_count }} / {{ props.row.notes_count }} / {{ props.row.settlements_count }}
            </template>
          </b-table-column>
          <b-table-column field="id" :label="$t('users.fields.destroy')" centered>
            <template slot="header">
              <b-tooltip
                :label="$t('users.fields.destroy')"
              >
                <b-icon
                  icon="trash"
                  size="is-small"
                />
              </b-tooltip>
            </template>
            <template #default="props">
              <b-button @click="destroyUser(props.row.id)">
                <b-icon
                  class="destroy"
                  icon="trash"
                  size="is-small"
                  type="is-danger"
                />
              </b-button>
            </template>
          </b-table-column>
          <template slot="detail" slot-scope="props">
            <article class="media">
              <div class="media-content">
                <div class="content">
                  <p>{{ $t('users.fields.company') }}: <strong>{{ props.row.company }}</strong></p>
                  <p>{{ $t('users.fields.phone_num') }}: <strong>{{ props.row.phone_num }}</strong></p>
                  <p>
                    {{ $t('users.fields.nip') }}:  <strong>{{ props.row.nip }}</strong>
                  </p>
                </div>
              </div>
            </article>
          </template>
          <template slot="empty">
            <section class="section">
              <div class="content has-text-grey has-text-centered">
                <p>
                  <b-icon
                    icon="times-circle"
                    size="is-large"
                  />
                </p>
                <p>{{ $t('users.noRecord') }}</p>
              </div>
            </section>
          </template>
        </b-table>
      </div>
    </div>
    <ConfirmationDialog
      v-show="dialog.show"
      :target="dialog"
      scope="user"
      @destroyConfirmed="destroyConfirmed"
    />
  </div>
</template>
<script>
import Counters from './Counters.vue'
import Search from './Search.vue'
import ConfirmationDialog from './ConfirmationDialog.vue'

export default {
  components: {
    Search, Counters, ConfirmationDialog
  },
  data: function () {
    return {
      data: [],
      total: 0,
      loading: false,
      sortField: 'created_at',
      sortOrder: 'desc',
      defaultSortOrder: 'desc',
      page: 1,
      perPage: 20,
      searchAttrs: {},
      dialog: {
        show: false,
        action: 'destroy',
        id: null,
        ident: null,
        notification: true
      }
    }
  },
  computed: {
    counters () {
      return [
        {
          label: this.$t('users.found'),
          value: this.total
        }
      ]
    },
    paginated () {
      return this.total > this.perPage
    },
    query () {
      const obj = {
        page: this.page,
        sortField: this.sortField,
        sortOrder: this.sortOrder,
        ...this.searchAttrs
      }
      Object.assign(obj, this.searchAttrs)
      return obj
    }
  },
  mounted () {
    this.loadAsyncData()
  },
  methods: {
    loadAsyncData () {
      this.loading = true
      this.$api.users.index({
        ...this.query
      },
      (response) => {
        this.data = response.data.map(elem => elem.attributes)
        const meta = response.meta
        this.total = meta.total
        this.page = meta.page
        this.perPage = meta.items
        this.loading = false
      })
    },
    onPageChange (page) {
      this.page = page
      this.loadAsyncData()
    },
    onSort (field, order) {
      this.sortField = field
      this.sortOrder = order
      this.loadAsyncData()
    },
    search (params) {
      this.searchAttrs = params
      this.page = 1
      this.loadAsyncData()
    },
    destroyUser (id) {
      this.dialog.id = id
      const user = this.data.find(user => user.id === id)
      if (user) {
        this.dialog.ident = `${user.company} (${user.email})`
        this.dialog.show = true
      }
    },
    destroyConfirmed () {
      this.$api.users.destroy(this.dialog.id,
        () => {
          this.loadAsyncData()
          this.$buefy.toast.open({
            message: this.$t('user.actionSuccess.destroy'),
            type: 'is-success'
          })
        }
      )
    },
    dialogClose () {
      this.dialog.show = false
    }
  }
}
</script>

<style scoped>
.destroy {
  cursor: pointer;
}
</style>
