<template>
  <div class="columns">
    <div class="column is-3">
      <simple-side-card
        :record="form"
        :attrs="attrs"
        scope="routes.fields"
      />
      <SideHelp :items="['form', 'currency_update', 'no_items']" scope="routes.help" />
      <a v-if="form.id" :href="spreadsheetUrl" target="_blank" rel="noopener" class="button is-info is-fullwidth">
        <b-icon
          icon="table"
          size="is-small"
        />
        <span>
          {{ $t('routes.actions.spreadsheet') }}
        </span>
      </a>
    </div>
    <div class="column">
      <record-title
        :record="form"
        scope="routes"
      />
      <div class="columns">
        <div class="column is-6">
          <TextField
            v-model="form.name"
            scope="routes"
            field="name"
            obligatory
            :vform="$v.form"
            @input="$v.form.name.$touch()"
          />
          <b-field v-if="!form.id" :label="$t('orders.bundle')">
            <b-autocomplete
              rounded
              :data="bundles.available"
              :placeholder="$t('orders.chooseBundle')"
              icon="search"
              field="name"
              :loading="bundles.isFetching"
              @typing="getBundlesLazy"
              @select="bundleSelected"
            >
              <template slot-scope="props">
                {{ props.option.name }}
              </template>
              <template slot="empty">
                {{ $t('orders.noBoundleFound') }}
              </template>
            </b-autocomplete>
          </b-field>
        </div>
      </div>
      <record-actions
        :vobj="$v"
        :record="form"
        scope="routes"
        @create="create"
        @update="update"
      />
      <div v-if="form.id" class="columns my-3">
        <route-items-table @fetch="fetchItems" @item-changed="updateCard" />
      </div>
    </div>
  </div>
</template>

<script>
import { dateToStr } from '../js/date_lib.js'
import Persisted from 'mixins/persisted'
import SimpleSideCard from './SimpleSideCard.vue'
import RecordTitle from './RecordTitle.vue'
import SideHelp from './SideHelp.vue'
import TextField from './TextField.vue'
import RecordActions from './RecordActions.vue'
import RouteItemsTable from './RouteItemsTable.vue'
import BundlesFetch from 'mixins/bundlesFetch'
import { required, minLength, maxLength, requiredUnless } from 'vuelidate/lib/validators'
import { createNamespacedHelpers, mapState } from 'vuex'
const { mapGetters, mapMutations } = createNamespacedHelpers('tables')

const initialForm = () => {
  return {
    name: '',
    bundleId: null
  }
}

export default {
  components: {
    SimpleSideCard, RecordTitle, SideHelp, TextField, RecordActions, RouteItemsTable
  },
  mixins: [
    Persisted,
    BundlesFetch
  ],
  data () {
    return {
      attrs: [
        { name: 'created_at', type: 'string' },
        { name: 'updated_at', type: 'string' },
        { name: 'from', type: 'string' },
        { name: 'to', type: 'string' },
        { name: 'items_count', type: 'string' },
        { name: 'payment_value', type: 'currency' },
        { name: 'transport_cost', type: 'currency' }
      ],
      form: initialForm()
    }
  },
  computed: {
    ...mapState([
      'user', 'userLoaded'
    ]),
    ...mapGetters(['query', 'total']),
    spreadsheetUrl () {
      return `/admin/routes/${this.form.id}/spreadsheet.xlsx`
    }
  },
  methods: {
    ...mapMutations(['setItems', 'reset']),
    dateFormater (date) {
      return dateToStr(date)
    },
    updateData (response, action = null) {
      const data = response.data.attributes
      this.form = Object.assign(data, { bundleId: 'fake' })
      if (action === 'create') {
        this.$router.replace({ name: 'routeEdit', params: { id: this.form.id } })
      }
      if (action) {
        this.$buefy.toast.open({
          message: this.$t(`routes.actionSuccess.${action}`),
          type: 'is-success'
        })
      }
    },
    load () {
      this.$api.routes.show(this.id,
        (response) => {
          this.updateData(response)
          this.reset()
          this.fetchItems()
        }
      )
    },
    fetchItems () {
      this.$api.routes.items(this.form.id, {
        ...this.query
      }, (response) => {
        this.setItems({
          items: response.data.map(elem => elem.attributes),
          meta: response.meta
        })
      })
    },
    create () {
      this.$api.routes.create(
        {
          name: this.form.name,
          bundle_id: this.form.bundleId
        },
        (response) => {
          this.updateData(response, 'create')
        })
    },
    update () {
      this.$api.routes.update(
        {
          id: this.form.id,
          name: this.form.name
        },
        (response) => {
          this.updateData(response, 'update')
        })
    },
    updateCard (data) {
      this.form = data
    },
    resetForm () {
      this.form = initialForm()
    },
    bundleSelected (option) {
      this.bundles.selected = option
      this.form.bundleId = option.id
    }
  },
  validations: {
    form: {
      name: {
        required,
        minLength: minLength(3),
        maxLength: maxLength(50)
      },
      bundleId: {
        required: requiredUnless(function (form) {
          return form.id
        })
      }
    }
  }
}
</script>
