<template>
  <div class="orders-for-store">
    <b-table
      v-if="anyItems"
      :data="orders"
      :columns="columns"
      :row-class="(row, index) => row.quantity === row.storedout && 'is-storedout'"
    />
    <b-message v-else type="is-warning">
      {{ $t('bundle.noOrders') }}
    </b-message>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'
const { mapState, mapGetters } = createNamespacedHelpers('orders')

export default {
  data () {
    return {
      columns: [
        {
          field: 'refid',
          label: this.$t('order.fields.refid')
        },
        {
          field: 'company',
          label: this.$t('order.fields.company')
        },
        {
          field: 'fullInfo',
          label: this.$t('order.fields.fullInfo')
        },
        {
          field: 'packages',
          label: this.$t('order.fields.packages')
        }
      ]
    }
  },
  computed: {
    ...mapState(['items']),
    ...mapGetters(['anyItems']),
    orders () {
      return this.items.map(function (item) {
        return {
          refid: item.refid,
          company: item.company,
          fullInfo: [item.first_name, item.last_name, item.street, item.postal_code, item.city].join(', '),
          packages: [item.quantity, item.storedin, item.storedout].join(' / '),
          quantity: item.quantity,
          storedin: item.storedin,
          storedout: item.storedout
        }
      })
    }
  }
}
</script>

<style scoped>
.orders-for-store {
  margin-top: 1em;
}
.subtitle {
  margin-bottom: 0;
}
</style>
