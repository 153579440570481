import debounce from 'js/debounce'

export default {
  data () {
    return {
      producers: {
        available: [],
        selected: null,
        isFetching: false
      }
    }
  },
  methods: {
    getProducersLazy: debounce(function (pattern) {
      this.getProducers(pattern)
    }, 250),
    getProducers (pattern) {
      if (!pattern.length) {
        this.producers.available = []
        return
      }
      this.producers.isFetching = true
      this.$api.users.producers({
        pattern
      }, (response) => {
        this.producers.available = []
        response.data.map(elem => elem.attributes).forEach((item) => this.producers.available.push(item))
      })
      this.producers.isFetching = false
    }
  }
}
