import page from '../js/page.js'
import Vue from 'vue'
import VueI18n from 'vue-i18n'
import Vuelidate from 'vuelidate'
import VueCurrencyInput from 'vue-currency-input'
import Vue2Filters from 'vue2-filters'
import messages from '../js/locales.pl.js'
import Buefy from 'buefy'
import App from '../components/App.vue'
import api from '../js/api/AppApi'
import VueCan from '../js/VueCan/Plugin.js'
import store from '../store/index'
import router from '../js/router'

Vue.use(Buefy, {
  defaultIconPack: 'fas'
})

Vue.use(VueI18n)
Vue.use(Vuelidate)
Vue.use(VueCan)
Vue.use(Vue2Filters)
Vue.config.productionTip = false

const currencyOptions = {
  globalOptions: {
    currency: 'PLN'
  }
}
Vue.use(VueCurrencyInput, currencyOptions)

const i18n = new VueI18n({
  locale: 'pl',
  messages
})

Vue.filter('prettify', function (value) {
  return JSON.stringify(value, undefined, 2)
})

document.addEventListener('DOMContentLoaded', () => {
  if (page.notControlledBy('dashboard', 'index')) {
    return
  }

  // eslint-disable-next-line no-unused-vars
  const app = new Vue({
    router,
    i18n,
    store,
    api,
    render: h => h(App)
  }).$mount()
  document.getElementById('page').appendChild(app.$el)
})
