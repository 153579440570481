import appApi from '../../js/api/AppApi'

// !!! Wspólny store dla zamówień i zestawień
// Stan dla zamówień w widoku klienta i administratora wybierany przy pomocy **scope**
// Stan dla zamówień w widoku Zestawienia (Bundle), jeżeli jest ustawione zestawienie **bundle**
const defaultState = () => {
  return {
    // wszystkie zamówienia
    items: [],
    // zamówienia wybrane na wszystkich stronach
    selectedItems: [],
    // zamówienia zazanczone na aktualnej stronie
    checkedItems: [],
    meta: {
      total: 0,
      page: 1,
      perPage: 20
    },
    loading: false,
    sortField: 'created_at',
    sortOrder: 'desc',
    searchAttrs: {},
    scope: 'none',
    bundle: null
  }
}

const state = defaultState()

const mutations = {
  reset (state) {
    Object.assign(state, defaultState())
  },
  setPage (state, page) {
    state.meta.page = page
  },
  setSearch (state, attrs) {
    state.searchAttrs = attrs
  },
  setSort (state, payload) {
    state.sortField = payload.field
    state.sortOrder = payload.order
  },
  setScope (state, scope) {
    state.scope = scope
  },
  setBundle (state, id) {
    state.bundle = id
    state.meta.page = 1
  },
  setItems (state, payload) {
    const items = payload.data.map(elem => elem.attributes)
    const meta = payload.meta
    state.items = items
    state.meta = meta
    state.checkedItems = []
    state.items.forEach(function (item) {
      const found = state.selectedItems.find(elem => elem.id === item.id)
      if (found) {
        state.checkedItems.push(item)
      }
    })
  },
  removeItem (state, item) {
    const index = state.selectedItems.findIndex(elem => elem.id === item.id)
    if (index !== -1) {
      state.selectedItems.splice(index, 1)
    }
  },
  addItem (state, item) {
    if (!state.selectedItems.find(elem => elem.id === item.id)) {
      state.selectedItems.push(item)
    }
  },
  clearSelected (state) {
    state.selectedItems = []
  },
  clearChecked (state) {
    state.checkedItems = []
  }
}

const getters = {
  query (state) {
    const meta = state.meta
    const queryObj = {
      page: meta.page,
      sortField: state.sortField,
      sortOrder: state.sortOrder,
      ...state.searchAttrs
    }
    if (state.bundle) {
      Object.assign(queryObj, { id: state.bundle })
    }
    return queryObj
  },
  total (state) {
    return state.meta.total
  },
  paginated (state) {
    return state.meta.total > state.items.length
  },
  anySelected (state) {
    return state.selectedItems.length > 0
  },
  selectedCount (state) {
    return state.selectedItems.length
  },
  selectedIds (state) {
    return state.selectedItems.map(item => item.id)
  },
  anyChecked (state) {
    return state.checkedItems.length > 0
  },
  anyItems (state) {
    return state.items.length > 0
  },
  checkedCount (state) {
    return state.checkedItems.length
  },
  checkedIds (state) {
    return state.checkedItems.map(item => item.id)
  }
}

const actions = {
  fetch ({ state, commit, getters }, options = {}) {
    if (options.reset) {
      commit('reset')
    }
    if (options.bundle) {
      commit('setBundle', options.bundle)
    }
    if (options.scope) {
      commit('setScope', options.scope)
    }

    // exec Api call depends on options.bundle
    if (state.bundle) {
      appApi.bundles.orders(state.bundle, {
        ...getters.query
      }, (response) => { commit('setItems', response) })
    } else {
      appApi.orders.index(state.scope, {
        ...getters.query
      }, (response) => { commit('setItems', response) }
      )
    }
  },
  onPage ({ commit, dispatch }, page) {
    commit('setPage', page)
    dispatch('fetch')
  },
  onSort ({ commit, dispatch }, payload) {
    commit('setSort', {
      field: payload.field,
      order: payload.order
    })
    dispatch('fetch')
  },
  onSearch ({ commit, dispatch }, payload) {
    commit('setPage', 1)
    commit('setSearch', payload.attrs)
    dispatch('fetch')
  },
  writeSelection ({ state, commit }, checked) {
    state.items.forEach(order => {
      const item = checked.find(elem => elem.id === order.id)
      if (item) {
        commit('addItem', item)
      } else {
        commit('removeItem', order)
      }
    })
  },
  clearAll ({ commit }) {
    commit('clearSelected')
    commit('clearChecked')
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions
}
