<template>
  <div class="columns mb-2">
    <div class="column is-one-fifth">
      <Counters :items="countersTop" />
      <Counters v-if="anySelected" :items="countersBottom" />
      <OrdersBatchOps />
      <Search statuses @search="searchChanged" />
    </div>
    <div class="column">
      <b-message v-can:manage.not="'Order'" type="is-info" has-icon>
        <p>Szanowni klienci!</p>
        <p>W związku z otwarciem nowego kierunku dostaw zmienił się układ formatki do wprowadzenia zleceń.</p>
        <p>Wybór docelowego kraju został umieszony jako pierwszy element do wyboru, ze względu, że wybór ten ma wpływ na weryfikację i prezentację pozostałych cech zlecenia. Domyślnie docelowym krajem jest Polska. Rodzaj transportu został przeniesiony niżej do sekcji opisującej przedmiot zlecenia.</p>
      </b-message>
      <OrdersTable />
    </div>
  </div>
</template>

<script>
import Counters from './Counters.vue'
import Search from './Search.vue'
import OrdersTable from './OrdersTable.vue'
import OrdersBatchOps from './OrdersBatchOps'
import { createNamespacedHelpers, mapState } from 'vuex'

const { mapGetters, mapActions } = createNamespacedHelpers('orders')

export default {
  components: {
    Search, Counters, OrdersTable, OrdersBatchOps
  },
  data: function () {
    return {
    }
  },
  computed: {
    ...mapState([
      'user', 'userLoaded'
    ]),
    ...mapState('orders', ['items']),
    ...mapGetters(['total', 'anySelected', 'selectedCount']),
    countersTop () {
      return [
        {
          label: this.$t('orders.found'),
          value: this.total
        }
      ]
    },
    countersBottom () {
      return [
        {
          label: this.$t('orders.selected'),
          value: this.selectedCount
        }
      ]
    }
  },
  watch: {
    user: {
      immediate: true,
      handler: function () {
        if (this.userLoaded) {
          this.fetch({
            reset: true,
            bundle: null,
            scope: this.user.scope
          })
        }
      }
    }
  },
  methods: {
    ...mapActions(['fetch', 'onSearch']),
    searchChanged (params) {
      this.onSearch({
        scope: this.user.scope,
        attrs: params
      })
    }
  }
}
</script>

<style scoped>
.field-label {
  display: inline-block;
  width: 150px;
  margin-right: .3em;
}
</style>
