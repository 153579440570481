<template>
  <div class="card">
    <div class="card-header">
      <p class="card-header-title">
        {{ $t('settings.preview.statuses') }}
      </p>
    </div>
    <div class="card-content">
      <div class="content">
        <ul>
          <li v-for="status in orderStatuses" :key="status">
            <StatusTag :status="status" icon />
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import StatusTag from './StatusTag.vue'
import { orderStatuses } from '../js/constants.js'

export default {
  components: {
    StatusTag
  },
  data () {
    return {
      orderStatuses
    }
  }
}
</script>
