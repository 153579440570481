<template>
  <b-table
    detailed
    detail-key="id"
    :data="items"
    :loading="loading"
    :paginated="paginated"
    backend-pagination
    pagination-position="both"
    :total="total"
    :per-page="perPage"
    :current-page.sync="page"
    checkable
    :checked-rows="checkedItems"
    aria-next-label="Next page"
    aria-previous-label="Previous page"
    aria-page-label="Page"
    aria-current-label="Current page"
    backend-sorting
    :default-sort-direction="sortOrder"
    :default-sort="[sortField, sortOrder]"
    @page-change="pageChanged"
    @sort="sortChanged"
    @check="itemChecked"
    @check-all="allChecked"
  >
    <b-table-column v-slot="props" field="quantity" :label="$t('order.fields.quantityShort')">
      <span class="tag is-info">
        {{ props.row.quantity }}
      </span>
    </b-table-column>
    <b-table-column v-slot="props" field="storedin" :label="$t('order.fields.storedinShort')">
      <span class="tag is-info">
        {{ props.row.storedin }}
      </span>
    </b-table-column>
    <b-table-column v-slot="props" field="storedout" :label="$t('order.fields.storedoutShort')">
      <span class="tag is-info">
        {{ props.row.storedout }}
      </span>
    </b-table-column>
    <b-table-column v-slot="props" field="aasm_state" :label="$t('order.fields.aasm_state')">
      <StatusTag :status="props.row.aasm_state" />
    </b-table-column>
    <b-table-column v-slot="props" field="client_name" :label="$t('order.fields.client_name')">
      {{ props.row.first_name }} {{ props.row.last_name }}
    </b-table-column>
    <b-table-column v-slot="props" field="address_short" :label="$t('order.fields.address_short')">
      {{ props.row.postal_code }}
      {{ props.row.city }}
    </b-table-column>
    <b-table-column v-slot="props" field="parcel_kind" :label="$t('order.fields.parcel_kind')">
      {{ props.row.parcel_kind }}
    </b-table-column>
    <b-table-column v-slot="props" field="parcel_name" :label="$t('order.fields.parcel_name')">
      {{ props.row.parcel_name }}
    </b-table-column>
    <b-table-column v-slot="props" field="sender" :label="$t('order.fields.sender')">
      {{ props.row.company }}
    </b-table-column>
    <b-table-column field="id" :label="$t('order.fields.edit')" centered>
      <template slot="header">
        <b-icon
          icon="edit"
          size="is-small"
        />
      </template>
      <template v-slot="props">
        <router-link :to="{ name: 'orderEdit', params: { id: props.row.id }}">
          <b-icon
            icon="edit"
          />
        </router-link>
      </template>
    </b-table-column>
    <template slot="detail" slot-scope="props">
      <article class="media">
        <div class="media-content">
          <div class="content">
            <FieldLabel field="client_name" :value="props.row.first_name + ' ' + props.row.last_name" />
            <FieldLabel
              field="address_detail"
              :value="addressDetail(props.row.street, props.row.building_num, props.row.flat_num, props.row.postal_code, props.row.city, props.row.state)"
            />
            <FieldLabel field="phone_num" :value="props.row.phone_num" />
            <FieldLabel field="kind_of_payment" :value="$t(`order.kind_of_payment.${props.row.kind_of_payment}`)" />
            <template v-if="props.row.kind_of_payment == 'payment_on_delivery'">
              <p>
                <span class="field-label">
                  {{ $t('order.fields.payment_value') }}:
                </span>
                <strong>
                  {{ props.row.payment_value| currency('zł', 2, { symbolOnLeft: false, spaceBetweenAmountAndSymbol: true}) }}
                </strong>
              </p>
            </template>
            <p v-if="user.admin">
              <FieldLabel field="sender" :value="props.row.company" />
            </p>
            <FieldLabel field="parcel" :value="props.row.parcel_kind + ', ' + props.row.parcel_name" />
          </div>
        </div>
      </article>
    </template>
    <template slot="empty">
      <section class="section">
        <div class="content has-text-grey has-text-centered">
          <p>
            <b-icon
              icon="times-circle"
              size="is-large"
            />
          </p>
          <p>{{ $t('order.noRecord') }}</p>
        </div>
      </section>
    </template>
  </b-table>
</template>

<script>
import StatusTag from './StatusTag.vue'
import FieldLabel from './shared/FieldLabel.vue'
import { createNamespacedHelpers, mapState } from 'vuex'
const { mapGetters, mapActions } = createNamespacedHelpers('orders')

export default {
  components: {
    StatusTag, FieldLabel
  },
  data () {
    return {
      page: 1
    }
  },
  computed: {
    ...mapState(['user']),
    ...mapState('orders', ['items', 'checkedItems', 'loading', 'perPage', 'sortField', 'sortOrder']),
    ...mapGetters(['total', 'paginated'])
  },
  methods: {
    ...mapActions(['onPage', 'onSort', 'writeSelection']),
    sortChanged (field, order) {
      this.onSort({
        field,
        order
      })
    },
    pageChanged (page) {
      this.onPage(page)
    },
    addressDetail (street, bNum, fNum, postalCode, city, state) {
      const num = [street, [bNum, fNum].filter(v => v.length > 0).join(' / ')].join(' ')
      return [num, postalCode, city, state].filter(v => v.length > 0).join(', ')
    },
    itemChecked (array) {
      this.writeSelection(array)
    },
    allChecked (array) {
      this.writeSelection(array)
    }
  }
}
</script>
