<template>
  <div class="container">
    <Order />
  </div>
</template>

<script>
import { mapState } from 'vuex'
import Order from './Order.vue'

export default {
  components: {
    Order
  },
  data () {
    return {
    }
  },
  computed: {
    ...mapState([
      'user'
    ])
  },
  beforeRouteEnter (to, from, next) {
    next(vm => {
      if (!vm.user.configured) {
        vm.$router.push({ name: 'account', params: { showMessage: true } })
      }
    })
  }
}
</script>
