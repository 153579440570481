<template>
  <div v-if="order.id" class="card">
    <div class="card-content">
      <div class="content">
        <ul>
          <li>
            {{ $t(`order.fields.company`) }}:
            {{ order.company }}
            <router-link :to="{ name: 'settlementProducer', params: { id: order.user_id }}">
              <b-icon
                icon="user-edit"
                size="is-small"
              />
            </router-link>
          </li>
          <li>
            {{ $t(`order.fields.phone_num`) }}: <strong>{{ order.company_phone_num }}</strong>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  props: {
    order: {
      type: Object,
      required: true
    }
  }
}
</script>

<style scoped>
ul {
  margin: 0;
  font-size: small;
  list-style: square;
}

.order-state {
  justify-content: end;
}
</style>
