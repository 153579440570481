export default class UsersDocs {
  constructor (parent) {
    this.parent = parent
  }

  baseUrl (id = null) {
    if (id) {
      return `admin/archives/${id}.json`
    } else {
      return 'admin/archives.json'
    }
  }

  index (onSuccess) {
    this.parent.send('GET', this.baseUrl(), {}, onSuccess)
  }

  create (onSuccess) {
    this.parent.send('POST', this.baseUrl(), {}, onSuccess)
  }

  destroy (id, onSuccess) {
    this.parent.send('DELETE', this.baseUrl(id), {}, onSuccess)
  }

  destroyFiles (id, onSuccess) {
    this.parent.send('DELETE', `admin/archives/${id}/files.json`, {}, onSuccess)
  }
}
