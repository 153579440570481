<template>
  <span class="tag" :class="`status-${status}`">
    <b-icon
      v-if="icon"
      :icon="orderStatusIcons[status]"
      size="is-small"
    />
    <span>
      {{ $t(`order.statuses.${status}`) }}
    </span>
  </span>
</template>

<script>
import { orderStatusIcons } from '../js/constants.js'

export default {
  props: {
    status: {
      type: String,
      required: true
    },
    icon: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      orderStatusIcons
    }
  }
}
</script>
