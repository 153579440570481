<template>
  <div>
    <h1 class="title">
      Ustawienia API
    </h1>
    <b-notification type="is-info" :closable="false">
      <p>
        Aplikacja umożliwia zarządzanie zleceniami poprzez interfejs <strong>API</strong>. Aby korzystać z <strong>API</strong>, należy wygenerować klucz <strong>APIKEY</strong> za pomocą przycisku poniżej. Przed użyciem <strong>API</strong> zalecamy zapoznać się z dokumentacją.
      </p>
      <p>
        <a href="/doc/api/">
          Dokumentacja API
        </a>
      </p>
    </b-notification>
    <b-notification
      type="is-warning"
      :closable="false"
      has-icon
    >
      <p>
        Ze względu bezpieczeństwa nie ma technicznej możliwości uzyskania aktualnego klucza. W przypadku utraty lub skompromitowania aktualnego klucza, wygeneruj nowy klucz.
      </p>
    </b-notification>
    <b-notification
      v-if="isPresent"
      type="is-success
      is-light"
      :closable="false"
    >
      <strong>APIKEY:</strong>
      <div class="api-key">
        <b-input id="apiKey" v-model="apiKey" />
        <b-tooltip label="Skopiuj klucz do schowka">
          <b-button
            type="is-success"
            icon-right="copy"
            class="ml-3"
            @click="copyApiKey"
          />
        </b-tooltip>
      </div>
    </b-notification>
    <b-button
      type="is-info"
      icon-left="cog"
      class="mb-5"
      @click="apiRenew"
    >
      Wygeneruj klucz <strong>APIKEY</strong>
    </b-button>
  </div>
</template>

<script>
export default {
  data () {
    return {
      apiKey: ''
    }
  },
  computed: {
    isPresent () {
      return this.apiKey.length > 0
    }
  },
  methods: {
    apiRenew () {
      this.$api.apiAccount.renew((response) => {
        this.apiKey = response.data.api_key
      })
    },
    copyApiKey () {
      const copyText = document.getElementById('apiKey')
      copyText.select()
      copyText.setSelectionRange(0, 99999) /* For mobile devices */
      document.execCommand('copy')
      this.$buefy.toast.open({
        message: 'Klucz został skopiowany do schowka',
        type: 'is-success'
      })
    }
  }
}
</script>

<style>
.api-key {
  display: flex;
}
.api-key .control {
  width: 500px;
}
</style>
