<template>
  <b-table
    :data="items"
    :loading="loading"
    :paginated="paginated"
    backend-pagination
    pagination-position="both"
    :total="total"
    :per-page="meta.perPage"
    :current-page.sync="page"
    aria-next-label="Next page"
    aria-previous-label="Previous page"
    aria-page-label="Page"
    aria-current-label="Current page"
    backend-sorting
    :default-sort-direction="sortOrder"
    :default-sort="[sortField, sortOrder]"
    @page-change="onPageChanged"
    @sort="onSortChanged"
  >
    <b-table-column v-slot="props" field="name" :label="$t('routes.fields.name')">
      <router-link :to="{ name: 'routeEdit', params: { id: props.row.id }}">
        {{ props.row.name }}
      </router-link>
    </b-table-column>
    <b-table-column v-slot="props" field="updated_at" :label="$t('routes.fields.updated_at')" sortable>
      {{ props.row.updated_at }}
    </b-table-column>
    <b-table-column v-slot="props" field="items_count" :label="$t('routes.fields.items_count')">
      {{ props.row.items_count }}
    </b-table-column>
    <b-table-column field="id" :label="$t('routes.fields.destroy')" centered>
      <template slot="header">
        <b-tooltip
          :label="$t('routes.fields.destroy')"
        >
          <b-icon
            icon="trash"
            size="is-small"
          />
        </b-tooltip>
      </template>
      <template v-slot="props">
        <b-button @click="$emit('destroy', props.row.id, props.row.name)">
          <b-icon
            class="destroy"
            icon="trash"
            size="is-small"
            type="is-danger"
          />
        </b-button>
      </template>
    </b-table-column>
    <template slot="empty">
      <section class="section">
        <div class="content has-text-grey has-text-centered">
          <p>
            <b-icon
              icon="times-circle"
              size="is-large"
            />
          </p>
          <p>{{ $t('routes.noRecord') }}</p>
        </div>
      </section>
    </template>
  </b-table>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'
const { mapState, mapGetters, mapActions } = createNamespacedHelpers('tables')

export default {
  data () {
    return {
      page: 1
    }
  },
  computed: {
    ...mapState(['items', 'loading', 'perPage', 'sortField', 'sortOrder']),
    ...mapGetters(['meta', 'total', 'paginated'])
  },
  methods: {
    ...mapActions(['onPage', 'onSort']),
    onSortChanged (field, order) {
      this.onSort({
        field,
        order
      })
      this.$emit('fetch')
    },
    onPageChanged (page) {
      this.onPage(page)
      this.$emit('fetch')
    }
  }
}
</script>
