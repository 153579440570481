export default class Waypoints {
  constructor (parent) {
    this.parent = parent
  }

  index (pattern, onSuccess) {
    const params = {
      pattern: pattern
    }
    this.parent.send('GET', `admin/waypoints.json?${this.parent.serializeParams(params)}`, {}, onSuccess)
  }

  save (params, onSuccess) {
    this.parent.send('POST', 'admin/waypoints.json', params, onSuccess)
  }
}
