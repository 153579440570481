<template>
  <div>
    <div class="columns mb-2">
      <div class="column is-one-fifth">
        <Counters :items="counters" />
        <search-filter @search="search" />
        <router-link :to="{ name: 'routeNew'}" class="button is-info is-fullwidth">
          <b-icon
            icon="plus"
            size="is-small"
          />
          <span>
            {{ $t('routes.addNew') }}
          </span>
        </router-link>
      </div>
      <div class="column">
        <routes-table @fetch="fetch" @destroy="destroy" />
      </div>
    </div>
  </div>
</template>

<script>
import SearchFilter from './SearchFilter.vue'
import Counters from './Counters.vue'
import { createNamespacedHelpers } from 'vuex'
import appApi from '../js/api/AppApi'
import RoutesTable from './RoutesTable'
const { mapGetters, mapMutations } = createNamespacedHelpers('tables')
export default {
  components: {
    RoutesTable, Counters, SearchFilter
  },
  data () {
    return {
      id: null,
      name: null
    }
  },
  computed: {
    ...mapGetters(['query', 'total']),
    counters () {
      return [
        {
          label: this.$t('routes.found'),
          value: this.total
        }
      ]
    }
  },
  mounted () {
    this.reset()
    this.fetch()
  },
  methods: {
    ...mapMutations(['setItems', 'reset', 'setSearch']),
    fetch () {
      appApi.routes.index({
        ...this.query
      }, (response) => {
        this.setItems({
          items: response.data.map(elem => elem.attributes),
          meta: response.meta
        })
      })
    },
    destroy (id, name) {
      this.id = id
      this.name = name
      const key = 'routes.dialog.destroy'
      this.$buefy.dialog.confirm({
        title: this.$t(`${key}.title`),
        message: this.$t(`${key}.message`, { name: this.name }),
        confirmText: this.$t(`${key}.confirmText`),
        cancelText: this.$t(`${key}.cancelText`),
        type: 'is-danger',
        hasIcon: true,
        onConfirm: () => this.destroyConfirmed()
      })
    },
    destroyConfirmed () {
      appApi.routes.destroy(this.id, (response) => {
        this.$buefy.toast.open({
          message: this.$t('routes.actionSuccess.destroy'),
          type: 'is-success'
        })
        this.reset()
        this.fetch()
      })
    },
    search (attrs) {
      this.setSearch(attrs)
      this.fetch()
    }
  }
}
</script>
