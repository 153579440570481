<template>
  <div class="modal">
    <div class="modal-background" @click="$parent.dialogClose()" />
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">
          {{ $t(`modal.${dialog.action}.title`) }}
        </p>
        <button class="delete" aria-label="close" @click="$parent.dialogClose()" />
      </header>
      <section v-if="isChangeState" class="modal-card-body">
        {{ $t(`modal.${dialog.action}.message`, { state: $t(`order.statuses.${dialog.targetState}`) }) }}
      </section>
      <section v-else class="modal-card-body">
        {{ $t(`modal.${dialog.action}.message`) }}
      </section>
      <footer class="modal-card-foot">
        <button class="button" :class="buttonKlass" @click="confirmation">
          <b-icon
            :icon="buttonIcon"
            size="is-small"
          />
          <span>
            {{ $t(`modal.${dialog.action}.button`) }}
          </span>
        </button>
        <button class="button" @click="$parent.dialogClose()">
          {{ $t('modal.common.cancel') }}
        </button>
      </footer>
    </div>
  </div>
</template>

<script>
import { dialogProps } from 'js/constants'

export default {
  props: {
    dialog: {
      type: Object,
      required: true
    }
  },
  computed: {
    dProps () {
      return dialogProps[this.dialog.action]
    },
    buttonKlass () {
      const dialog = this.dialog
      if (this.isChangeState) {
        return this.dProps[dialog.targetState].klass
      } else {
        return this.dProps.klass
      }
    },
    buttonIcon () {
      const dialog = this.dialog
      if (this.isChangeState) {
        return this.dProps[dialog.targetState].icon
      } else {
        return this.dProps.icon
      }
    },
    isChangeState () {
      return this.dialog.action === 'stateChange'
    }
  },
  methods: {
    confirmation () {
      this.$parent.dialogClose()
      this.$emit(`${this.dialog.action}Confirmed`)
    }
  }
}
</script>

<style scoped>
.modal {
  display: block;
}
.modal-card {
  margin-top: 3em;
}
.modal-card-foot {
  justify-content: space-between;
}
</style>
