import locales from './locales.pl.js'

const kindOfPayment = function (value) {
  return value === 'payment_on_delivery'
    ? locales.pl.order.kind_of_payment.payment_on_delivery
    : locales.pl.order.kind_of_payment.payment_prepaid
}

const contentField = function (klass, name, value) {
  const fieldName = name ? `${name}: ` : ''
  return `<div class="${klass}">${fieldName}<strong>${value}</strong></div>`
}

const less10 = function (number) {
  return number < 10 ? `0${number}` : `${number}`
}

const formatAtDate = function (str) {
  const date = new Date(str)
  const month = less10(date.getMonth() + 1)
  const day = less10(date.getDate())
  const hours = less10(date.getHours())
  const minutes = less10(date.getMinutes())
  return `${date.getFullYear()}-${month}-${day}/${hours}:${minutes}`
}

const orderContent = function (order) {
  return '<div class="order-content">' +
    contentField('client', null, order.first_name + ' ' + order.last_name) +
    contentField('updated_at', locales.pl.order.fields.updated_at, formatAtDate(order.updated_at)) +
    contentField('parcelKind', locales.pl.order.fields.parcel, order.parcel_kind) +
    contentField('quantity', locales.pl.order.fields.quantity, order.quantity)
}

export {
  kindOfPayment,
  contentField,
  orderContent
}
