import appApi from '../../js/api/AppApi'

const state = {
  items: [],
  meta: {
    total: 0,
    page: 1,
    perPage: 20
  },
  loading: false,
  sortField: 'created_at',
  sortOrder: 'desc',
  searchAttrs: {}
}

const mutations = {
  setItems (state, payload) {
    state.items = payload.items
    state.meta = payload.meta
  },
  setPage (state, page) {
    state.meta.page = page
  },
  setSearch (state, attrs) {
    state.searchAttrs = attrs
  },
  setSort (state, sort) {
    state.sortField = sort.field
    state.sortOrder = sort.order
  }
}

const getters = {
  query (state) {
    const meta = state.meta
    return {
      page: meta.page,
      sortField: state.sortField,
      sortOrder: state.sortOrder,
      ...state.searchAttrs
    }
  },
  total (state) {
    return state.meta.total
  },
  paginated (state) {
    return state.meta.total > state.items.length
  }
}

const actions = {
  fetch ({ commit, getters }) {
    appApi.bundles.index({
      ...getters.query
    },
    (response) => {
      commit('setItems', {
        items: response.data.map(elem => elem.attributes),
        meta: response.meta
      })
    })
  },
  onPage ({ commit, dispatch }, page) {
    commit('setPage', page)
    dispatch('fetch')
  },
  onSort ({ commit, dispatch }, payload) {
    commit('setSort', {
      field: payload.field,
      order: payload.order
    })
    dispatch('fetch')
  },
  onSearch ({ commit, dispatch }, attrs) {
    commit('setPage', 1)
    commit('setSearch', attrs)
    dispatch('fetch')
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions
}
