<template>
  <div class="container">
    <div class="columns">
      <div class="column is-3">
        <SideHelp :items="['help']" scope="sms" />
      </div>
      <div class="column">
        <h1 class="title">
          {{ $t('sms.title') }}
        </h1>
        <pre v-if="response" class="response-container">
          {{ response | prettify }}
        </pre>
        <div class="action-buttons actions-middle">
          <button disabled class="button is-primary" @click="checkService">
            <b-icon
              icon="check"
              size="is-small"
            />
            <span>
              {{ $t('sms.checkService') }}
            </span>
          </button>
        </div>
        <div class="columns">
          <div class="column is-5">
            <TextField
              v-model="sms.phone_number"
              field="phone_number"
              :vform="$v.sms"
              scope="sms"
              obligatory
              @input="$v.sms.phone_number.$touch()"
            />
            <TextField
              v-model="sms.message"
              field="message"
              :vform="$v.sms"
              scope="sms"
              obligatory
              @input="$v.sms.message.$touch()"
            />
          </div>
        </div>
        <div class="action-buttons actions-bottom">
          <button class="button is-primary" :disabled="formInvalid" @click="sendMessage">
            <b-icon
              icon="check"
              size="is-small"
            />
            <span>
              {{ $t('sms.sendMessage') }}
            </span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SideHelp from './SideHelp.vue'
import { required } from 'vuelidate/lib/validators'
import TextField from './TextField.vue'

export default {
  components: {
    SideHelp, TextField
  },
  data () {
    return {
      response: null,
      sms: {
        phone_number: '',
        message: '',
        sender_id: 'CynaTrans'
      }
    }
  },
  computed: {
    formInvalid () {
      return this.$v.$invalid
    }
  },
  methods: {
    checkService () {
      this.$api.smsCheckService(
        (response) => {
          this.response = response.data
        }
      )
    },
    sendMessage () {
      this.$api.smsSendMessage(this.sms,
        (response) => {
          console.log(response)
          this.response = response
          this.sms.phone_number = ''
          this.sms.message = ''
        }
      )
    }
  },
  validations: {
    sms: {
      phone_number: {
        required
      },
      message: {
        required
      }
    }
  }
}
</script>

<style scoped>
.response-container {
  border: 1px solid #ddd;
  padding: 1em;
  margin-bottom: 1em;
}
pre {
  white-space: break-spaces;
}
</style>
