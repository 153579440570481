<template>
  <section class="mb-3">
    <b-field :label="label">
      <b-input
        ref="input"
        :value="code"
        :placeholder="placeholder"
        @input="valueChanged"
      />
    </b-field>
    <b-field>
      <b-switch
        v-model="autocommit"
        size="is-small"
      >
        {{ $t('codeReader.autoCommit') }}
      </b-switch>
    </b-field>
    <div v-if="backable" class="field">
      <b-switch
        v-model="takeback"
        size="is-small"
      >
        {{ $t('store.takeback') }}
      </b-switch>
    </div>
    <transition name="fade">
      <div v-if="!autocommit">
        <b-button
          type="is-primary"
          icon-left="check"
          class="is-fullwidth"
          @click="manualCommit"
        >
          {{ $t('codeReader.manualCommit') }}
        </b-button>
      </div>
    </transition>
  </section>
</template>

<script>
import debounce from '../js/debounce'

export default {
  props: {
    label: {
      type: String,
      required: true
    },
    placeholder: {
      type: String,
      required: true
    },
    backable: {
      type: Boolean,
      required: false
    }
  },
  data () {
    return {
      code: '',
      autocommit: true,
      takeback: false
    }
  },
  watch: {
    code () {
      if (this.autocommit) {
        this.autoCommit()
      }
    }
  },
  methods: {
    valueChanged (value) {
      this.code = value
    },
    autoCommit: debounce(function () {
      this.codeSend()
    }, 250),
    manualCommit () {
      this.codeSend()
    },
    codeSend () {
      if (this.code.length > 0) {
        this.$emit('code', this.code, this.takeback)
        this.code = ''
      }
    }
  }
}
</script>
