<template>
  <div v-if="bundle.id" class="card-footer">
    <div class="card-footer-item">
      <router-link :to="{ name: 'bundleEdit', params: { id: bundle.id }}">
        <b-icon
          icon="list-alt"
          size="is-small"
        />
        <span>{{ bundle.name }}</span>
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    bundle: {
      type: Object,
      required: true
    }
  }
}
</script>

<style scoped>
.order-bundles {
  margin-bottom: 0.75em;
}
</style>
