export default class {
  constructor (parent) {
    this.parent = parent
  }

  baseUrl(url, id = null) {
    let suffix = id ? `/${id}` : ''
    return `/dispatch-note/${url}${suffix}.json`
  }

  index (params, onSuccess) {
    this.parent.send('GET', `${this.baseUrl('documents')}?${this.parent.serializeParams(params)}`, {}, onSuccess)
  }

  show (id, onSuccess) {
    this.parent.send('GET', this.baseUrl('documents', id), {}, onSuccess)
  }

  create(params, onSuccess) {
    this.parent.send('POST', this.baseUrl('documents'), params, onSuccess)
  }

  update(id, params, onSuccess) {
    this.parent.send('PATCH', this.baseUrl('documents', id), params, onSuccess)
  }

  destroy(id, onSuccess) {
    this.parent.send('DELETE', this.baseUrl('documents', id), {}, onSuccess)
  }

  items (id, params, onSuccess) {
    this.parent.send('GET', `/dispatch-note/documents/${id}/items.json?${this.parent.serializeParams(params)}`, {}, onSuccess)
  }

  createItem(params, onSuccess) {
    this.parent.send('POST', this.baseUrl('items'), params, onSuccess)
  }

  updateItem (id, params, onSuccess) {
    this.parent.send('PATCH', this.baseUrl('items', id), params, onSuccess)
  }

  destroyItem (id, onSuccess) {
    this.parent.send('DELETE', this.baseUrl('items', id), {}, onSuccess)
  }
}
