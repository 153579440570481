import { render, staticRenderFns } from "./BundleOrdersActions.vue?vue&type=template&id=0ec93db8&scoped=true&"
import script from "./BundleOrdersActions.vue?vue&type=script&lang=js&"
export * from "./BundleOrdersActions.vue?vue&type=script&lang=js&"
import style0 from "./BundleOrdersActions.vue?vue&type=style&index=0&id=0ec93db8&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0ec93db8",
  null
  
)

export default component.exports