export default class Settlements {
  constructor (parent) {
    this.parent = parent
  }

  baseUrl (id = null) {
    if (id) {
      return `admin/settlements/${id}.json`
    } else {
      return 'admin/settlements.json'
    }
  }

  index (params, onSuccess) {
    this.parent.send('GET', `${this.baseUrl()}?${this.parent.serializeParams(params)}`, {}, onSuccess)
  }

  show (id, onSuccess) {
    this.parent.send('GET', this.baseUrl(id), {}, onSuccess)
  }

  items (id, params, onSuccess) {
    this.parent.send('GET', `/admin/settlements/${id}/items.json?${this.parent.serializeParams(params)}`, {}, onSuccess)
  }

  create (params, onSuccess) {
    this.parent.send('POST', this.baseUrl(), params, onSuccess)
  }

  update (params, onSuccess) {
    const id = params.id
    this.parent.send('PATCH', this.baseUrl(id), params, onSuccess)
  }

  sendSms (settlelmentId, onSuccess) {
    this.parent.send('POST', `/admin/settlements/${settlelmentId}/send_sms.json`, {}, onSuccess)
  }

  updateItem (id, params, onSuccess) {
    this.parent.send('PATCH', `/admin/settlement_items/${id}.json`, params, onSuccess)
  }

  destroy (id, onSuccess) {
    this.parent.send('DELETE', this.baseUrl(id), {}, onSuccess)
  }

  destroyItem (id, onSuccess) {
    this.parent.send('DELETE', `/admin/settlement_items/${id}.json`, {}, onSuccess)
  }

  addItem (params, onSuccess) {
    this.parent.send('POST', '/admin/settlement_items.json', params, onSuccess)
  }
}
