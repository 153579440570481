export default class Notes {
  constructor (parent) {
    this.parent = parent
  }

  baseUrl () {
    return '/admin/notes.json'
  }

  createItem (params, onSuccess) {
    this.parent.send('POST', this.baseUrl('add'), params, onSuccess)
  }
}
