<template>
  <transition name="fade">
    <section v-if="waypointPresent" class="mb-3">
      <b-field :label="$t('orders.bundle')">
        <b-autocomplete
          rounded
          :data="bundles.available"
          :placeholder="$t('orders.chooseBundle')"
          icon="search"
          field="name"
          :loading="bundles.isFetching"
          @typing="getBundlesLazy"
          @select="option => bundles.selected = option"
        >
          <template slot-scope="props">
            {{ props.option.name }}
          </template>
          <template slot="empty">
            {{ $t('orders.noBoundleFound') }}
          </template>
        </b-autocomplete>
      </b-field>
      <div v-if="bundles.selected" class="field">
        <b-button
          class="is-fullwidth"
          icon-left="plus"
          type="is-success"
          @click="assignToBundle"
        >
          {{ $t('orders.addToBundle') }}
        </b-button>
      </div>
    </section>
  </transition>
</template>

<script>
import BundlesFetch from 'mixins/bundlesFetch'
import SettlementsFetch from 'mixins/settlementsFetch'
import { createNamespacedHelpers, mapState, mapGetters as plainMapGetters } from 'vuex'
const { mapGetters, mapActions } = createNamespacedHelpers('orders')

export default {
  mixins: [
    BundlesFetch, SettlementsFetch
  ],
  computed: {
    ...mapGetters(['selectedCount', 'anySelected', 'selectedIds']),
    ...mapState('map', ['waypoints']),
    ...plainMapGetters('map', ['waypointPresent'])
  },
  methods: {
    ...mapActions(['clearAll']),
    clear () {
      this.clearAll()
      // this.bundles.selected = null
      // this.settlements.selected = null
    },
    assignToBundle () {
      const onlyOrdersIds = this.waypoints
        .filter(waypoint => waypoint.marker.order.id)
        .map(waypoint => waypoint.marker.order.id)
      this.$api.manifests.add({
        manifest: {
          bundle_id: this.bundles.selected.id,
          orders_ids: onlyOrdersIds
        }
      }, () => {
        this.$buefy.toast.open({
          message: this.$t('manifests.ordersAdded'),
          type: 'is-success'
        })
        this.clear()
      })
    }
    // assignToSettlement () {
    //   this.$api.settlements.addItem({
    //     id: this.settlements.selected.id,
    //     orders_ids: this.selectedIds
    //   }, () => {
    //     this.$buefy.toast.open({
    //       message: this.$t('manifests.ordersAdded'),
    //       type: 'is-success'
    //     })
    //     this.clear()
    //   })
    // }
  }
}
</script>
