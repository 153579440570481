export const FormatDate = {
  methods: {
    formatDate (date) {
      if (date) {
        const month = date.getMonth() + 1
        const day = date.getDate()
        const monthStr = month < 10 ? `0${month}` : `${month}`
        const dayStr = day < 10 ? `0${day}` : `${day}`
        return `${date.getFullYear()}-${monthStr}-${dayStr}`
      } else {
        return null
      }
    }
  }
}
