<template>
  <b-table
    :data="items"
    :loading="loading"
    :paginated="paginated"
    backend-pagination
    pagination-position="both"
    :total="total"
    :per-page="meta.perPage"
    :current-page.sync="page"
    aria-next-label="Next page"
    aria-previous-label="Previous page"
    aria-page-label="Page"
    aria-current-label="Current page"
    backend-sorting
    :default-sort-direction="sortOrder"
    :default-sort="[sortField, sortOrder]"
    @page-change="onPageChanged"
    @sort="onSortChanged"
  >
    <b-table-column v-slot="props" field="producent_ident" :label="$t('routes.fields.producer_ident')">
      {{ props.row.producer_ident }}
    </b-table-column>
    <b-table-column v-slot="props" field="postal_code" :label="$t('routes.fields.postal_code')">
      {{ props.row.postal_code }}
    </b-table-column>
    <b-table-column
      v-slot="props"
      field="payment_value"
      :label="$t('routes.fields.payment_value')"
    >
      <currency-input
        :key="props.row.id"
        :value="props.row.payment_value"
        class="input"
        :data-row="props.row.id"
        @blur="pvChanged"
      />
    </b-table-column>
    <b-table-column
      v-slot="props"
      field="transport_cost"
      :label="$t('routes.fields.transport_cost')"
    >
      <currency-input
        :key="props.row.id"
        :value="props.row.transport_cost"
        class="input"
        :data-row="props.row.id"
        @blur="tcChanged"
      />
    </b-table-column>
    <b-table-column field="edit" :label="$t('order.order')" centered>
      <template #default="props">
        <router-link v-if="props.row.order_id" :to="{ name: 'orderEdit', params: { id: props.row.order_id }}">
          <b-icon
            icon="external-link-alt"
            size="is-medium"
          />
        </router-link>
      </template>
    </b-table-column>
    <template slot="empty">
      <section class="section">
        <div class="content has-text-grey has-text-centered">
          <p>
            <b-icon
              icon="times-circle"
              size="is-large"
            />
          </p>
          <p>{{ $t('routeItems.noRecord') }}</p>
        </div>
      </section>
    </template>
  </b-table>
</template>

<script>
import { getValue } from 'vue-currency-input'
import appApi from '../js/api/AppApi'
import { createNamespacedHelpers } from 'vuex'
const { mapState, mapGetters, mapActions } = createNamespacedHelpers('tables')

export default {
  data () {
    return {
      page: 1
    }
  },
  computed: {
    ...mapState(['items', 'loading', 'perPage', 'sortField', 'sortOrder']),
    ...mapGetters(['meta', 'total', 'paginated'])
  },
  methods: {
    ...mapActions(['onPage', 'onSort']),
    onSortChanged (field, order) {
      this.onSort({
        field,
        order
      })
      this.$emit('fetch')
    },
    onPageChanged (page) {
      this.onPage(page)
      this.$emit('fetch')
    },
    itemUpdate (params) {
      appApi.routes.updateItem(event.target.dataset.row, {
        route_items: params
      },
      (response) => {
        this.$emit('item-changed', response.data.attributes)
      })
    },
    pvChanged (event) {
      this.itemUpdate({
        payment_value: getValue(event.target)
      })
    },
    tcChanged (event) {
      this.itemUpdate({
        transport_cost: getValue(event.target)
      })
    }
  }
}
</script>
