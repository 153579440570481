<template>
  <div class="columns">
    <div class="column is-one-fifth">
      <SideHelp :items="['settlements']" scope="settlements.help" />
      <doc-archives />
    </div>
    <div class="column">
      <div class="columns">
        <div class="column is-6">
          <section>
            <b-field :label="$t('settlements.chooseProducer')">
              <b-autocomplete
                rounded
                :data="producers.available"
                :placeholder="$t('settlements.chooseProducer')"
                icon="search"
                field="company"
                :loading="producers.isFetching"
                @typing="getProducersLazy"
                @select="selected"
              >
                <template slot-scope="props">
                  {{ props.option.company }}
                </template>
                <template slot="empty">
                  {{ $t('settlements.producerNotFound') }}
                </template>
              </b-autocomplete>
            </b-field>
          </section>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ProducersFetch from 'mixins/producersFetch'
import SideHelp from './SideHelp.vue'
import DocArchives from './DocArchives.vue'

export default {
  components: {
    SideHelp, DocArchives
  },
  mixins: [
    ProducersFetch
  ],
  data () {
    return {
    }
  },
  methods: {
    selected (producer) {
      this.producers.selected = producer
      this.$router.push({ name: 'settlementProducer', params: { id: producer.id } })
    }
  }
}
</script>
