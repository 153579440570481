export default class Manifests {
  constructor (parent) {
    this.parent = parent
  }

  baseUrl (oper) {
    return `/admin/manifests/${oper}.json`
  }

  add (params, onSuccess) {
    this.parent.send('POST', this.baseUrl('add'), params, onSuccess)
  }

  remove (params, onSuccess) {
    this.parent.send('POST', this.baseUrl('remove'), params, onSuccess)
  }
}
