<template>
  <b-table
    :data="items"
    :loading="loading"
    :paginated="paginated"
    backend-pagination
    pagination-position="both"
    :total="total"
    :per-page="perPage"
    :current-page.sync="page"
    aria-next-label="Next page"
    aria-previous-label="Previous page"
    aria-page-label="Page"
    aria-current-label="Current page"
    backend-sorting
    :default-sort-direction="sortOrder"
    :default-sort="[sortField, sortOrder]"
    @page-change="pageChanged"
    @sort="sortChanged"
  >
    <b-table-column v-slot="props" field="name" :label="$t('bundle.fields.name')">
      <router-link :to="{ name: 'bundleEdit', params: { id: props.row.id }}">
        {{ props.row.name }}
      </router-link>
    </b-table-column>
    <b-table-column v-slot="props" field="updated_at" :label="$t('bundle.fields.updated_at')" sortable>
      {{ props.row.updated_at }}
    </b-table-column>
    <b-table-column v-slot="props" field="departed_at" :label="$t('bundle.fields.departed_at')">
      {{ props.row.departed_at }}
    </b-table-column>
    <b-table-column v-slot="props" field="orders_count" :label="$t('bundle.fields.orders_count')">
      {{ props.row.orders_count }}
    </b-table-column>
    <b-table-column v-slot="props" field="packages_count" :label="$t('bundle.fields.packages_count')">
      {{ props.row.packages_count }}
    </b-table-column>
    <b-table-column field="edit" :label="$t('bundle.fields.edit')" centered>
      <template slot="header">
        <b-icon
          icon="edit"
          size="is-small"
        />
      </template>
      <template v-slot="props">
        <router-link :to="{ name: 'bundleEdit', params: { id: props.row.id }}">
          <b-icon
            icon="edit"
          />
        </router-link>
      </template>
    </b-table-column>
    <template slot="empty">
      <section class="section">
        <div class="content has-text-grey has-text-centered">
          <p>
            <b-icon
              icon="times-circle"
              size="is-large"
            />
          </p>
          <p>{{ $t('bundle.noRecord') }}</p>
        </div>
      </section>
    </template>
  </b-table>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'
const { mapState, mapGetters, mapActions } = createNamespacedHelpers('bundles')

export default {
  data () {
    return {
      page: 1
    }
  },
  computed: {
    ...mapState(['items', 'loading', 'perPage', 'sortField', 'sortOrder']),
    ...mapGetters(['total', 'paginated'])
  },
  methods: {
    ...mapActions(['fetch', 'onPage', 'onSort']),
    sortChanged (field, order) {
      this.onSort({
        field,
        order
      })
    },
    pageChanged (page) {
      this.onPage(page)
    }
  }
}
</script>
