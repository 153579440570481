<template>
  <div v-if="record.id" class="card">
    <div class="card-content">
      <div class="content">
        <ul>
          <li v-for="obj in attrs" :key="obj.name">
            <span class="card-label">{{ $t(`${scope}.${obj.name}`) }}: </span>
            <strong>{{ attrFormater(obj) }}</strong>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { dateToStr } from '../js/date_lib.js'

export default {
  props: {
    record: {
      type: Object,
      required: true
    },
    attrs: {
      type: Array,
      required: true
    },
    scope: {
      type: String,
      required: true
    }
  },
  methods: {
    attrFormater (attr) {
      if (attr.type === 'currency') {
        return this.record[attr.name].toLocaleString('pl-PL', { style: 'currency', currency: 'PLN' })
      } else if (attr.type === 'date') {
        return dateToStr(this.record[attr.name])
      } else {
        return this.record[attr.name]
      }
    }
  }
}
</script>

<style scoped>
ul {
  margin: 0;
  font-size: small;
  list-style: none;
}
.card-label {
  width: 50%;
  display: inline-block;
  text-align: right;
}
</style>
