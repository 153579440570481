<template>
  <div class="container">
    <Order :id="id" />
  </div>
</template>

<script>
import Order from './Order.vue'

export default {
  components: {
    Order
  },
  props: {
    id: {
      type: String,
      required: true
    }
  },
  data () {
    return {
    }
  }
}
</script>
