export default class Settings {
  constructor (parent) {
    this.parent = parent
  }

  index (onSuccess) {
    this.parent.send('GET', 'admin/settings.json', {}, onSuccess)
  }

  update (params, onSuccess) {
    this.parent.send('POST', 'admin/settings.json', params, onSuccess)
  }
}
