const totalDistance = function (legs) {
  var total = legs.reduce(function (acc, leg) {
    return acc + leg.distance.value
  }, 0)
  return (total / 1000).toFixed(0)
}

const durationFormat = function (duration) {
  var hours = parseInt((parseInt(duration, 10) / 3600), 10)
  var min = parseInt((duration - (hours * 3600)) / 60, 10)
  if (min < 0 || hours < 0) {
    return {
      hours: 0,
      min: 0
    }
  } else {
    return {
      hours: hours,
      min: min
    }
  }
}

const durationAdjust = function (duration, adjust) {
  return durationFormat(duration + adjust * 60)
}

const totalDuration = function (legs, adjPoint, adjRoute) {
  var total = legs.reduce(function (acc, leg) {
    return acc + leg.duration.value
  }, 0) + (legs.length * adjPoint * 60) + (adjRoute * 60)
  return durationFormat(total)
}

export {
  durationAdjust,
  durationFormat,
  totalDistance,
  totalDuration
}
