<template>
  <b-table
    :data="items"
    :loading="loading"
    :paginated="paginated"
    backend-pagination
    pagination-position="both"
    :total="total"
    :per-page="meta.perPage"
    :current-page.sync="page"
    aria-next-label="Next page"
    aria-previous-label="Previous page"
    aria-page-label="Page"
    aria-current-label="Current page"
    backend-sorting
    :default-sort-direction="sortOrder"
    :default-sort="[sortField, sortOrder]"
    @page-change="onPageChanged"
    @sort="onSortChanged"
  >
    <b-table-column v-slot="props" field="producent_ident" :label="$t('settlements.fields.last_name')">
      {{ props.row.last_name }}
    </b-table-column>
    <b-table-column v-if="docConfig === 'doc_info'" v-slot="props" field="postal_code" :label="$t('settlements.fields.info')">
      {{ props.row.info }}
    </b-table-column>
    <b-table-column v-slot="props" field="postal_code" :label="$t('settlements.fields.postal_code')">
      {{ props.row.postal_code }}
    </b-table-column>
    <b-table-column v-slot="props" field="postal_code" :label="$t('settlements.fields.city')">
      {{ props.row.city }}
    </b-table-column>
    <b-table-column
      v-slot="props"
      field="payment_value"
      :label="$t('settlements.fields.payment_value')"
    >
      <currency-input
        :key="props.row.id"
        :value="props.row.payment_value"
        class="input"
        :data-row="props.row.id"
        @blur="pvChanged"
      />
    </b-table-column>
    <b-table-column
      v-slot="props"
      field="transport_cost"
      :label="$t('settlements.fields.transport_cost')"
    >
      <currency-input
        :key="props.row.id"
        :value="props.row.transport_cost"
        class="input"
        :data-row="props.row.id"
        @blur="tcChanged"
      />
    </b-table-column>
    <b-table-column field="id" :label="$t('settlements.items.destroy')" centered>
      <template #header>
        <b-tooltip
          :label="$t('settlements.items.destroy')"
        >
          <b-icon
            icon="trash"
            size="is-small"
          />
        </b-tooltip>
      </template>
      <template #default="props">
        <b-button @click="destroyItem(props.row.id, `${props.row.last_name} ${props.row.postal_code} ${props.row.city}`)">
          <b-icon
            class="destroy"
            icon="trash"
            size="is-small"
            type="is-danger"
          />
        </b-button>
      </template>
    </b-table-column>
    <template slot="empty">
      <section class="section">
        <div class="content has-text-grey has-text-centered">
          <p>
            <b-icon
              icon="times-circle"
              size="is-large"
            />
          </p>
          <p>{{ $t('settlements.items.noRecord') }}</p>
        </div>
      </section>
    </template>
  </b-table>
</template>

<script>
import { getValue } from 'vue-currency-input'
import appApi from '../js/api/AppApi'
import DestroyItems from '../mixins/destroyItems'
import { createNamespacedHelpers } from 'vuex'
const { mapState, mapGetters, mapActions } = createNamespacedHelpers('tables')

export default {
  mixins: [DestroyItems],
  props: {
    docConfig: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      page: 1
    }
  },
  computed: {
    ...mapState(['items', 'loading', 'perPage', 'sortField', 'sortOrder']),
    ...mapGetters(['meta', 'total', 'paginated'])
  },
  methods: {
    ...mapActions(['onPage', 'onSort']),
    onSortChanged (field, order) {
      this.onSort({
        field,
        order
      })
      this.$emit('fetch')
    },
    onPageChanged (page) {
      this.onPage(page)
      this.$emit('fetch')
    },
    itemUpdate (params) {
      appApi.settlements.updateItem(event.target.dataset.row, {
        settlement_items: params
      },
      (response) => {
        this.$emit('item-changed', response.data.attributes)
      })
    },
    pvChanged (event) {
      this.itemUpdate({
        payment_value: getValue(event.target)
      })
    },
    tcChanged (event) {
      this.itemUpdate({
        transport_cost: getValue(event.target)
      })
    },
    destroyItemConfirmed () {
      this.$api.settlements.destroyItem(this.item.id, (response) => {
        this.$buefy.toast.open({
          message: this.$t('settlements.items.actionSuccess.destroy'),
          type: 'is-success'
        })
        this.$emit('fetch', response.data.attributes)
      })
    },
    destroyItem (id, ident) {
      this.destroyDialog(
        id,
        ident,
        'settlements.items.dialog.destroy',
        this.destroyItemConfirmed
      )
    }
  }
}
</script>
