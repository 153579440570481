<template>
  <div class="columns">
    <div class="column is-3">
      <simple-side-card
        :record="form"
        :attrs="attrs"
        scope="settlements.fields"
      />
      <side-help :items="['form', 'currency_update']" scope="settlements.help" />
      <div class="mb-3">
        <a v-if="form.id" :href="pdfUrl" target="_blank" rel="noopener" class="button is-info is-fullwidth mb-3">
          <b-icon
            icon="print"
            size="is-small"
          />
          <span>
            {{ $t('settlements.actions.pdf') }}
          </span>
        </a>
        <b-button
          tag="router-link"
          :to="`/settlement-producer/${parentId}`"
          type="is-info"
          class="is light is-fullwidth"
          icon-left="arrow-left"
        >
          {{ $t('settlements.returnToProducer') }}
        </b-button>
        <b-button
          v-if="form.id"
          icon-left="envelope"
          type="is-primary is-light"
          class="mt-3"
          expanded
          @click="sendSettlementInfo"
        >
          {{ $t('settlements.sendSms.button') }}
        </b-button>
      </div>
      <code-reader
        v-if="form.id"
        ref="reader"
        :label="$t('settlements.codeReader.label')"
        :placeholder="$t('settlements.codeReader.placeholder')"
        @code="codeScanned"
      />
    </div>
    <div class="column">
      <record-title
        :record="form"
        scope="settlements"
      />
      <div class="columns">
        <div class="column is-6">
          <text-field
            v-model="form.name"
            scope="settlements"
            field="name"
            obligatory
            :vform="$v.form"
            @input="$v.form.name.$touch()"
          />
        </div>
        <div class="column is-3">
          <b-field :label="$t('settlements.fields.dated_to')">
            <b-datepicker
              v-model="form.dated_to"
              :first-day-of-week="1"
              :show-week-number="true"
              :placeholder="$t('settlements.form.selectDate')"
              icon="calendar-day"
              :month-names="monthNames"
              :day-names="dayNames"
              :date-formatter="dateFormater"
            />
          </b-field>
        </div>
      </div>
      <div class="columns">
        <div class="column is-3">
          <form-label attr="vat_tax" scope="settlements" />
          <div class="control">
            <currency-input
              v-model="form.vat_tax"
              class="input"
              :class="{ 'is-danger': $v.form.vat_tax.$error }"
              :placeholder="$t('form.enterString')"
              @input="$v.form.vat_tax.$touch()"
            />
          </div>
          <div v-if="$v.form.vat_tax.$error">
            <p v-if="!$v.form.vat_tax.required" class="help is-danger">
              {{ $t('v.vat_tax.required') }}
            </p>
            <p v-if="!$v.form.vat_tax.decimal" class="help is-danger">
              {{ $t('v.vat_tax.decimal') }}
            </p>
            <p v-if="!$v.form.vat_tax.minValue" class="help is-danger">
              {{ $t('v.vat_tax.minValue') }}
            </p>
          </div>
        </div>
      </div>
      <record-actions
        :vobj="$v"
        :record="form"
        scope="settlements"
        @create="create"
        @update="update"
      />
      <div v-if="form.id" class="columns my-3">
        <settlement-items-table :doc-config="form.user_doc_config" @fetch="fetchItems" @item-changed="updateCard" />
      </div>
    </div>
    <DispatchNotesDialog
      v-show="isDialogOpen"
      :target="dialog"
      scope="dispatchNotes"
      @confirmed="addToDispatchNote"
    />
  </div>
</template>

<script>
import locales from 'js/locales.pl.js'
import { dateToStr } from '../js/date_lib.js'
import Persisted from 'mixins/persisted'
import SimpleSideCard from './SimpleSideCard.vue'
import RecordTitle from './RecordTitle.vue'
import SideHelp from './SideHelp.vue'
import TextField from './TextField.vue'
import RecordActions from './RecordActions.vue'
import SettlementItemsTable from './SettlementItemsTable.vue'
import DispatchNotesDialog from './DispatchNotesDialog.vue'
import CodeReader from './CodeReader'
import FormLabel from './FormLabel.vue'
import { required, maxLength, decimal, minValue } from 'vuelidate/lib/validators'
import { createNamespacedHelpers, mapState } from 'vuex'
const { mapGetters, mapMutations } = createNamespacedHelpers('tables')

const initialForm = () => {
  return {
    name: '',
    vat_tax: 0.0,
    dated_to: new Date()
  }
}

export default {
  components: {
    DispatchNotesDialog, SimpleSideCard, RecordTitle, SideHelp, TextField, RecordActions, SettlementItemsTable, CodeReader, FormLabel
  },
  mixins: [Persisted],
  props: {
    userId: {
      validator: function (value) {
        return ['undefiend', 'string', 'number'].includes(typeof (value))
      }
    }
  },
  data () {
    return {
      dialog: {
        notes: [],
        refId: null
      },
      isDialogOpen: false,
      attrs: [
        { name: 'user_ident', type: 'string' },
        { name: 'created_at', type: 'string' },
        { name: 'updated_at', type: 'string' },
        { name: 'dated_to', type: 'date' },
        { name: 'items_count', type: 'string' },
        { name: 'payment_value_total', type: 'currency' },
        { name: 'transport_cost_total', type: 'currency' },
        { name: 'payment_balance', type: 'currency' }
      ],
      monthNames: locales.pl.monthNames,
      dayNames: locales.pl.dayNames,
      form: initialForm()
    }
  },
  computed: {
    parentId () {
      return this.userId || this.form.user_id
    },
    ...mapState([
      'user', 'userLoaded'
    ]),
    ...mapGetters(['query', 'total']),
    pdfUrl () {
      return `/admin/settlements/${this.form.id}.pdf`
    }
  },
  methods: {
    ...mapMutations(['setItems', 'setSort', 'reset']),
    dateFormater (date) {
      return dateToStr(date)
    },
    updateData (response, action = null) {
      const data = response.data.attributes
      data.dated_to = new Date(data.dated_to)
      this.form = data
      if (action === 'create') {
        this.$router.replace({ name: 'settlementEdit', params: { id: this.form.id } })
      }
      if (action) {
        this.$buefy.toast.open({
          message: this.$t(`settlements.actionSuccess.${action}`),
          type: 'is-success'
        })
      }
    },
    load () {
      this.$api.settlements.show(this.id,
        (response) => {
          this.updateData(response)
          this.reset()
          this.fetchItems()
        }
      )
    },
    fetchItems (attrs = null) {
      this.setSort({
        field: 'created_at',
        order: 'asc'
      })
      this.$api.settlements.items(this.form.id, {
        ...this.query
      }, (response) => {
        this.setItems({
          items: response.data.map(elem => elem.attributes),
          meta: response.meta
        })
        if (attrs) {
          this.updateCard(attrs)
        }
      })
    },
    create () {
      this.$api.settlements.create(
        {
          id: this.userId,
          name: this.form.name,
          dated_to: dateToStr(this.form.dated_to)
        },
        (response) => {
          this.updateData(response, 'create')
        })
    },
    update () {
      this.$api.settlements.update(
        {
          id: this.form.id,
          name: this.form.name,
          vat_tax: this.form.vat_tax,
          dated_to: dateToStr(this.form.dated_to)
        },
        (response) => {
          this.updateData(response, 'update')
        })
    },
    updateCard (data) {
      data.dated_to = new Date(data.dated_to)
      this.form = data
    },
    resetForm () {
      this.form = initialForm()
    },
    codeScanned (code, takeback) {
      this.$api.settlements.addItem({
        id: this.form.id,
        refid: code
      }, (response) => {
        this.$buefy.toast.open({
          message: this.$t('settlements.items.added'),
          type: 'is-success'
        })
        this.fetchItems()
        this.updateCard(response.data.attributes)
        if (response.data && response.data.meta) {
          let dispatch_notes = response.data.meta.dispatch_notes
          if (dispatch_notes) {
            this.dialog.notes = dispatch_notes.notes
            this.dialog.refId = dispatch_notes.refid
            this.isDialogOpen = true
          }
        }
      })
    },
    sendSettlementInfo () {
      this.$buefy.dialog.confirm({
        title: this.$t('settlements.sendSms.title'),
        message: this.$t('settlements.sendSms.message'),
        confirmText: this.$t('settlements.sendSms.confirmText'),
        cancelText: this.$t('settlements.sendSms.cancelText'),
        type: 'is-info',
        hasIcon: true,
        onConfirm: this.sendSettlementInfoConfirmed
      })
    },
    sendSettlementInfoConfirmed () {
      this.$api.settlements.sendSms(this.form.id, () => {
        this.$buefy.toast.open({
          message: this.$t('settlements.sendSms.sent'),
          type: 'is-success'
        })
      })
    },
    addToDispatchNote(refId, noteId) {
      this.$api.dispatchNotes.createItem({
        item: {
          document_id: noteId,
          code: refId,
          skip_check_storedout: true
        }
      }, (response) => {
        this.$buefy.toast.open({
          message: this.$t('dispatchNotes.items.created'),
          type: 'is-success'
        })
      })
    },
    dialogClose () {
      this.isDialogOpen = false
    }
  },
  validations: {
    form: {
      name: {
        required,
        maxLength: maxLength(50)
      },
      vat_tax: {
        required,
        decimal,
        minValue: minValue(0)
      }
    }
  }
}
</script>
