<template>
  <section>
    <div v-if="waypoints.length > 0" class="waypoints-list">
      <div class="waypoints-header">
        {{ $t('map.choosenWaypoints') }}
        (<span :class="{ limit: waypointsLimit }">
          {{ waypoints.length }}
        </span>)
      </div>
      <draggable v-model="waypoints" group="people" @start="drag=true" @end="drag=false">
        <div v-for="(waypoint, index) in waypoints" :key="index" class="waypoint">
          <div class="waypoint-title" @click="setInsertIndex(index)">
            {{ index + 1 }}. {{ waypoint.title }}
          </div>
          <div v-if="insertIndex === index" class="waypoint-insert">
            <b-icon
              icon="hand-point-right"
              type="is-success"
              size="is-large"
            />
          </div>
          <button type="button" aria-label="Remove waypoint" class="delete" @click="remove(index)" />
        </div>
      </draggable>
      <div>
        <b-button
          :title="$t('waypoints.save.tagTitle')"
          icon-left="save"
          type="is-primary is-light mr-3"
          class=""
          @click="saveWaypoints"
        />
        <b-button
          icon-left="times-circle"
          type="is-info"
          class="remove-button"
          @click="removeAll"
        >
          {{ $t('map.removeAllWaypoints') }}
        </b-button>
      </div>
      <div>
        <b-field class="mt-3">
          <b-switch
            v-model="onlyWaypoints"
            size="is-small"
          >
            {{ $t('map.onlyWaypoints') }}
          </b-switch>
        </b-field>
      </div>
    </div>
    <div v-else class="mb-3">
      <b-field :label="$t('waypoints.choose')">
        <b-autocomplete
          rounded
          :data="items.available"
          :placeholder="$t('waypoints.placeholder')"
          icon="search"
          field="ident"
          :loading="items.isFetching"
          @typing="getItemsLazy"
          @select="selected"
        >
          <template slot-scope="props">
            {{ props.option.ident }}
          </template>
          <template slot="empty">
            {{ $t('waypoints.notFound') }}
          </template>
        </b-autocomplete>
      </b-field>
    </div>
  </section>
</template>

<script>
import { config } from '../js/mapper_lib'
import draggable from 'vuedraggable'
import { mapMutations, mapState } from 'vuex'
import Items from 'mixins/items'

export default {
  components: { draggable },
  mixins: [Items],
  data () {
    return {
      dragging: false
    }
  },
  computed: {
    ...mapState('map', ['insertIndex']),
    waypoints: {
      get () {
        return this.$store.state.map.waypoints
      },
      set (value) {
        this.$store.commit('map/setWaypoints', value)
        this.$store.commit('map/setInsertIndex', null)
      }
    },
    onlyWaypoints: {
      get () {
        return this.$store.state.map.onlyWaypoints
      },
      set (value) {
        this.$store.commit('map/updateOnlyWaypoints', value)
      }
    },
    waypointsLimit () {
      return this.waypoints.length === config.maxWaypoints
    }
  },
  methods: {
    ...mapMutations('map', ['removeWaypoint', 'removeAllWaypoints', 'setInsertIndex']),
    ...mapMutations('orders', {
      clearSelectedOrders: 'clearSelected',
      clearAllSelectedOrders: 'clearSelected'
    }),
    remove (index) {
      const waypoint = this.waypoints[index]
      waypoint.marker.setLabel('')
      this.removeWaypoint(index)
      this.clearSelectedOrders({
        id: waypoint.id
      })
    },
    removeAll () {
      this.waypoints.forEach(waypoint => {
        waypoint.marker.setLabel('')
      })
      this.removeAllWaypoints()
      this.clearAllSelectedOrders()
    },
    saveWaipointConfirmed (name) {
      this.$api.waypoints.save({
        name: name,
        addresses: this.waypoints.map(waypoint => waypoint.addressId)
      }, () => {
        this.$buefy.toast.open({
          message: this.$t('waypoints.save.success'),
          type: 'is-success'
        })
      })
    },
    saveWaypoints () {
      this.$buefy.dialog.prompt({
        message: this.$t('waypoints.save.dialogTitle'),
        inputAttrs: {
          placeholder: this.$t('waypoints.save.placeholder'),
          maxlength: 30
        },
        confirmText: this.$t('waypoints.save.confirmText'),
        cancelText: this.$t('waypoints.save.cancelText'),
        trapFocus: true,
        onConfirm: (name) => this.saveWaipointConfirmed(name)
      })
    },
    getItemsFunction (pattern, callback) {
      this.$api.waypoints.index(pattern, callback)
    },
    selected (point) {
      this.$emit('addresses', point.addresses)
    }
  }
}
</script>

<style scoped>
.waypoints-list {
  margin-bottom: 1em;
}
.waypoint {
  padding: 3px 0;
  margin-bottom: .6em;
  font-size: .8em;
  position: relative;
  cursor: grab;
}

.waypoint-title {
  width: 290px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap !important;
}

.delete {
  position: absolute;
  right: 3px;
  top: 3px;
}
.waypoints-header {
  text-align: center;
  margin-bottom: .7em;
}
.limit {
  color: red;
}
</style>
