export default class Bundles {
  constructor (parent) {
    this.parent = parent
  }

  baseUrl (id = null) {
    if (id) {
      return `admin/bundles/${id}.json`
    } else {
      return 'admin/bundles.json'
    }
  }

  index (params, onSuccess) {
    this.parent.send('GET', `${this.baseUrl()}?${this.parent.serializeParams(params)}`, {}, onSuccess)
  }

  show (id, onSuccess) {
    this.parent.send('GET', this.baseUrl(id), {}, onSuccess)
  }

  orders (id, params, onSuccess) {
    this.parent.send('GET', `/admin/bundles/${id}/orders.json?${this.parent.serializeParams(params)}`, {}, onSuccess)
  }

  create (params, onSuccess) {
    this.parent.send('POST', this.baseUrl(), params, onSuccess)
  }

  update (params, onSuccess) {
    const id = params.id
    this.parent.send('PATCH', this.baseUrl(id), params, onSuccess)
  }

  destroy (id, onSuccess) {
    this.parent.send('DELETE', this.baseUrl(id), {}, onSuccess)
  }
}
