<template>
  <div class="columns">
    <div class="column is-one-fifth">
      <Counters :items="counters" />
      <search-filter @search="search" />
      <router-link :to="{ name: 'settlementNew', params: { userId: id }}" class="button is-info is-fullwidth">
        <b-icon
          icon="plus"
          size="is-small"
        />
        <span>
          {{ $t('settlements.addNew') }}
        </span>
      </router-link>
      <b-button
        v-if="checkedIds.length > 0"
        icon-left="save"
        type="is-primary is-light"
        class="mt-3"
        expanded
        @click="printBatch"
      >
        {{ $t('settlements.printBatch') }}
      </b-button>
      <form ref="opsForm" action="/admin/settlements/printbatch" method="POST" target="_blank">
        <input type="hidden" name="utf8" value="✓">
        <input v-model="authenticity_token" type="hidden" name="authenticity_token">
        <input v-model="checkedIds" type="hidden" name="settlements_batch_print[ids]">
      </form>
    </div>
    <div class="column mb-2">
      <h1 class="is-size-4">
        {{ $t('navbar.settlements.title') }} - {{ form.company }}
      </h1>
      <settlements-table @check="checked" @fetch="fetchItems" @destroy="destroy" />
    </div>
  </div>
</template>

<script>
import Counters from './Counters.vue'
import SettlementsTable from './SettlemensTable'
import SearchFilter from './SearchFilter.vue'
import Persisted from 'mixins/persisted'
import { createNamespacedHelpers, mapState } from 'vuex'
const { mapGetters, mapMutations } = createNamespacedHelpers('tables')

const initialForm = () => {
  return {
    form: {}
  }
}
export default {
  components: {
    SearchFilter, SettlementsTable, Counters
  },
  mixins: [Persisted],
  data () {
    return {
      authenticity_token: '',
      checkedIds: [],
      item: {
        id: null,
        name: null
      },
      form: initialForm()
    }
  },
  computed: {
    ...mapState([
      'user', 'userLoaded'
    ]),
    ...mapGetters(['query', 'total']),
    counters () {
      return [
        {
          label: this.$t('settlements.found'),
          value: this.total
        }
      ]
    }
  },
  mounted () {
    this.authenticity_token = document.querySelectorAll('meta[name=csrf-token]')[0].getAttribute('content')
  },
  methods: {
    ...mapMutations(['setItems', 'reset', 'setSearch', 'setSort']),
    updateData (response, action = null) {
      const data = response.data.attributes
      this.form = data
    },
    load () {
      this.$api.users.show(this.id,
        (response) => {
          this.updateData(response)
          this.reset()
          this.fetchItems()
        }
      )
    },
    fetchItems () {
      this.setSort({ field: 'created_at', order: 'desc' })
      this.$api.users.settlements(this.form.id, {
        ...this.query
      }, (response) => {
        this.setItems({
          items: response.data.map(elem => elem.attributes),
          meta: response.meta
        })
      })
    },
    resetForm () {
      this.form = initialForm()
    },
    search (attrs) {
      this.setSearch(attrs)
      this.fetchItems()
    },
    destroy (id, name) {
      this.item.id = id
      this.item.name = name
      const key = 'settlements.dialog.destroy'
      this.$buefy.dialog.confirm({
        title: this.$t(`${key}.title`),
        message: this.$t(`${key}.message`, { name: this.item.name }),
        confirmText: this.$t(`${key}.confirmText`),
        cancelText: this.$t(`${key}.cancelText`),
        type: 'is-danger',
        hasIcon: true,
        onConfirm: () => this.destroyConfirmed()
      })
    },
    destroyConfirmed () {
      this.$api.settlements.destroy(this.item.id, (response) => {
        this.$buefy.toast.open({
          message: this.$t('settlements.actionSuccess.destroy'),
          type: 'is-success'
        })
        this.reset()
        this.fetchItems()
      })
    },
    checked (items) {
      this.checkedIds = items.map(item => item.id)
    },
    printBatch () {
      this.$nextTick(function () {
        this.$refs.opsForm.submit()
      })
    }
  }
}
</script>
