export default class Packages {
  constructor (parent) {
    this.parent = parent
  }

  print (id, separatePrint, onSuccess) {
    let url = `/admin/orders/${id}/packages.json`
    if (separatePrint) {
      url += '?separate=true'
    }
    this.parent.send('GET', url, {}, onSuccess)
  }
}
