<template>
  <div>
    <div class="columns">
      <ConfirmationDialog
        v-show="dialog.show"
        :target="dialog"
        scope="bundle"
        @destroyConfirmed="destroyConfirmed"
        @removeItemsConfirmed="removeItemsConfirmed"
        @storeoutItemsConfirmed="storeoutItemsConfirmed"
        @doneItemsConfirmed="doneItemsConfirmed"
      />
      <div class="column is-3">
        <Counters v-if="form.id" :items="counters" />
        <BundleCard :bundle="form" />
        <SideHelp :items="['form', 'destroy']" scope="bundle.help" />
      </div>
      <div class="column is-9">
        <div class="columns">
          <h1 v-if="form.id" class="title">
            {{ $t('bundle.title.updateBundle') }}
          </h1>
          <h1 v-else class="title">
            {{ $t('bundle.title.createBundle') }}
          </h1>
        </div>
        <div class="columns">
          <div class="column is-6">
            <TextField
              v-model="form.name"
              scope="bundle"
              field="name"
              obligatory
              :vform="$v.form"
              @input="$v.form.name.$touch()"
            />
            <BundleActions
              :vobj="$v"
              :bundle="form"
              @create="create"
              @update="update"
              @destroy="destroy"
            />
          </div>
          <div class="column is-3">
            <b-field :label="$t('bundle.fields.departed_at')">
              <b-datepicker
                v-model="form.departed_at"
                :first-day-of-week="1"
                :show-week-number="true"
                :placeholder="$t('bundle.form.selectDate')"
                icon="calendar-day"
                :month-names="monthNames"
                :day-names="dayNames"
                :date-formatter="dateFormater"
              />
            </b-field>
          </div>
        </div>
      </div>
    </div>
    <div class="columns">
      <div v-if="isPersisted" class="column">
        <OrdersTableForBundle />
        <BundleOrdersActions
          @remove-items="removeItems"
          @storeout-items="storeoutItems"
          @done-items="doneItems"
        />
      </div>
    </div>
  </div>
</template>

<script>
import locales from 'js/locales.pl.js'
import { dateToStr } from '../js/date_lib.js'
import { createNamespacedHelpers, mapState } from 'vuex'
import Counters from './Counters.vue'
import Persisted from 'mixins/persisted'
import BundleCard from './BundleCard.vue'
import SideHelp from './SideHelp.vue'
import ConfirmationDialog from './ConfirmationDialog.vue'
import BundleActions from './BundleActions.vue'
import BundleOrdersActions from './BundleOrdersActions.vue'
import TextField from './TextField.vue'
import OrdersTableForBundle from './OrdersTableForBundle.vue'
import { required, minLength, maxLength } from 'vuelidate/lib/validators'
const { mapActions, mapGetters, mapMutations } = createNamespacedHelpers('orders')

const initialForm = () => {
  return {
    name: '',
    departed_at: new Date()
  }
}

export default {
  components: {
    BundleCard, SideHelp, ConfirmationDialog, TextField, BundleActions, BundleOrdersActions, OrdersTableForBundle, Counters
  },
  mixins: [Persisted],
  data () {
    return {
      monthNames: locales.pl.monthNames,
      dayNames: locales.pl.dayNames,
      dialog: {
        show: false,
        action: 'destroy',
        ident: null
      },
      form: initialForm()
    }
  },
  computed: {
    ...mapState([
      'user', 'userLoaded'
    ]),
    ...mapGetters(['selectedIds', 'total']),
    counters () {
      return [
        {
          label: this.$t('orders.forBundle'),
          value: this.total
        }
      ]
    }
  },
  methods: {
    dateFormater (date) {
      return dateToStr(date)
    },
    ...mapMutations(['setBundle', 'reset']),
    ...mapActions({
      clearAll: 'clearAll',
      fetchOrders: 'fetch'
    }),
    updateData (response, action = null) {
      const data = response.data.attributes
      data.departed_at = new Date(data.departed_at)
      this.form = data
      if (action === 'create') {
        this.$router.replace({ name: 'bundleEdit', params: { id: this.form.id } })
      }
      if (action) {
        this.$buefy.toast.open({
          message: this.$t(`bundle.actionSuccess.${action}`),
          type: 'is-success'
        })
      }
    },
    load () {
      this.$api.bundles.show(this.id,
        (response) => {
          this.updateData(response)
          this.fetchOrders({
            reset: true,
            bundle: this.id
          })
        }
      )
    },
    create () {
      this.$api.bundles.create(
        {
          name: this.form.name,
          departed_at: dateToStr(this.form.departed_at)
        },
        (response) => {
          this.updateData(response, 'create')
          this.reset()
        })
    },
    update () {
      this.$api.bundles.update(
        {
          id: this.form.id,
          name: this.form.name,
          departed_at: dateToStr(this.form.departed_at)
        },
        (response) => {
          this.updateData(response, 'update')
        })
    },
    destroy () {
      this.dialog.show = true
      this.dialog.ident = this.form.name
    },
    destroyConfirmed () {
      this.$api.bundles.destroy(this.form.id,
        (response) => {
          this.afterDestory()
        })
    },
    afterDestory () {
      this.$router.push({ name: 'bundles' })
      this.$buefy.toast.open({
        message: this.$t('bundle.actionSuccess.destroy'),
        type: 'is-success'
      })
    },
    dialogClose () {
      this.dialog.show = false
    },
    removeItems () {
      this.dialog.action = 'removeItems'
      this.dialog.show = true
    },
    removeItemsConfirmed () {
      this.$api.manifests.remove({
        manifest: {
          bundle_id: this.id,
          orders_ids: this.selectedIds
        }
      }, (_response) => {
        this.clearAll()
        this.fetchOrders()
        this.$buefy.toast.open({
          message: this.$t('bundle.actionSuccess.removeItems'),
          type: 'is-success'
        })
      })
    },
    storeoutItems () {
      this.dialog.action = 'storeoutItems'
      this.dialog.show = true
    },
    storeoutItemsConfirmed () {
      this.$api.batch.changeStatus({
        batch: {
          status: 'storeout',
          orders_ids: this.selectedIds
        }
      }, (_response) => {
        this.clearAll()
        this.fetchOrders()
        this.$buefy.toast.open({
          message: this.$t('bundle.actionSuccess.storeoutItems'),
          type: 'is-success'
        })
      })
    },
    doneItems () {
      this.dialog.action = 'doneItems'
      this.dialog.show = true
    },
    doneItemsConfirmed () {
      this.$api.batch.changeStatus({
        batch: {
          status: 'done',
          orders_ids: this.selectedIds
        }
      }, (_response) => {
        this.clearAll()
        this.fetchOrders()
        this.$buefy.toast.open({
          message: this.$t('bundle.actionSuccess.doneItems'),
          type: 'is-success'
        })
      })
    },
    resetForm () {
      this.form = initialForm()
    }
  },
  validations: {
    form: {
      name: {
        required,
        minLength: minLength(3),
        maxLength: maxLength(50)
      }
    }
  }
}
</script>

<style scoped>
.b-table {
  margin-bottom: 1em;
}
</style>
