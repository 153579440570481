var rules = function (el, binding, vnode) {
  const user = vnode.context.$store.state.user
  const permission = binding.arg
  const target = binding.value
  const modifiers = binding.modifiers
  // let username = null
  let type = null
  if (target) {
    type = typeof target === 'string' ? target : binding.value.type
  }

  let result = false
  switch (permission) {
    case 'hasAccount':
      result = !(user.admin || user.manager)
      break
    case 'modify':
      if (target.aasm_state === 'canceled') {
        result = false
      } else if (target.aasm_state === 'producer') {
        if (user.manager) {
          result = false
        } else {
          result = true
        }
      } else {
        if (user.admin && target.aasm_state !== 'done') {
          result = true
        }
      }
      break
    case 'manage':
      if (user.admin || (user.manager && type === 'Order')) {
        result = true
      } else {
        result = false
      }
      break
    case 'addNew':
      result = !(user.admin || user.manager) && type === 'Order'
      break
    case 'cancel':
      if (target.aasm_state === 'canceled') {
        result = false
      } else if (user.admin) {
        if (target.aasm_state !== 'done') {
          result = true
        }
      } else {
        if (target.aasm_state === 'producer' && !user.manager) {
          result = true
        }
      }
      break
  }
  if (modifiers.not) {
    result = !result
  }

  // if (user) {
  //   username = user.username
  // }

  // console.log('vue-can: ', username, permission, type, JSON.stringify(modifiers), result)
  return result
}

var Accessor = function (el, binding, vnode, user) {
  if (rules(el, binding, vnode, user)) {
    el.style.display = el._display
  } else {
    el.style.display = 'none'
  }
}
export default Accessor
