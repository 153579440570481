<template>
  <div class="columns">
    <div class="column is-3">
      <SideHelp :items="['using', 'smsTemplates']" scope="settings" />
      <SettingsPreview />
    </div>
    <div class="column">
      <h1 class="title">
        {{ $t(`settings.title`) }}
      </h1>
      <div class="columns">
        <div class="column is-6">
          <div class="columns">
            <div class="column">
              <div class="field">
                <FormLabel attr="storedinSmsTemplate" scope="settings" />
                <div class="control">
                  <textarea v-model="form.storedinSmsTemplate" class="textarea" :class="{ 'is-danger': $v.form.storedinSmsTemplate.$error }" :placeholder="$t('form.enterString')" @input="$v.form.storedinSmsTemplate.$touch()" />
                </div>
                <FieldErrors :vform="$v.form" field="storedinSmsTemplate" />
              </div>
              <div class="field">
                <FormLabel attr="storedoutSmsTemplate" scope="settings" />
                <div class="control">
                  <textarea v-model="form.storedoutSmsTemplate" class="textarea" :class="{ 'is-danger': $v.form.storedoutSmsTemplate.$error }" :placeholder="$t('form.enterString')" @input="$v.form.storedoutSmsTemplate.$touch()" />
                </div>
                <FieldErrors :vform="$v.form" field="storedoutSmsTemplate" />
              </div>
              <div class="field">
                <FormLabel attr="settlementSmsTemplate" scope="settings" />
                <div class="control">
                  <textarea v-model="form.settlementSmsTemplate" class="textarea" :class="{ 'is-danger': $v.form.settlementSmsTemplate.$error }" :placeholder="$t('form.enterString')" @input="$v.form.settlementSmsTemplate.$touch()" />
                </div>
                <FieldErrors :vform="$v.form" field="settlementSmsTemplate" />
              </div>
            </div>
          </div>
          <div class="action-buttons actions-bottom">
            <button :disabled="formInvalid" class="button is-primary" @click="save">
              <b-icon
                icon="save"
                size="is-small"
              />
              <span>
                {{ $t('settings.save') }}
              </span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import SideHelp from './SideHelp.vue'
import FormLabel from './FormLabel.vue'
import FieldErrors from './FieldErrors.vue'
import SettingsPreview from './SettingsPreview.vue'
import { required, maxLength } from 'vuelidate/lib/validators'

export default {
  components: {
    SideHelp, FormLabel, FieldErrors, SettingsPreview
  },
  data () {
    return {
      form: {
        storedinSmsTemplate: '',
        storedoutSmsTemplate: '',
        settlementSmsTemplate: ''
      }
    }
  },
  computed: {
    ...mapState([
      'user', 'userMeta', 'userLoaded'
    ]),
    formInvalid () {
      return this.$v.form.$invalid
    }
  },
  mounted () {
    this.fetch()
  },
  methods: {
    fetch () {
      this.$api.settings.index((response) => {
        const params = response.data.rw_params
        const form = this.form
        form.storedinSmsTemplate = params.storedin_sms_template
        form.storedoutSmsTemplate = params.storedout_sms_template
        form.settlementSmsTemplate = params.settlement_sms_template
      })
    },
    save () {
      const form = this.form
      this.$api.settings.update({
        settings: {
          storedin_sms_template: form.storedinSmsTemplate,
          storedout_sms_template: form.storedoutSmsTemplate,
          settlement_sms_template: form.settlementSmsTemplate
        }
      }, (response) => {
        this.$buefy.toast.open({
          message: this.$t('settings.saved'),
          type: 'is-success'
        })
      })
    }
  },
  validations: {
    form: {
      storedinSmsTemplate: {
        required,
        maxLength: maxLength(140)
      },
      storedoutSmsTemplate: {
        required,
        maxLength: maxLength(140)
      },
      settlementSmsTemplate: {
        required,
        maxLength: maxLength(140)
      }
    }
  }
}
</script>

<style scoped>
</style>
