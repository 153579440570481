import { helpers } from 'vuelidate/lib/validators'

const validateNip = function (nip) {
  var nipWithoutDashes = nip.replace(/-/g, '')
  var reg = /^[0-9]{10}$/
  if (reg.test(nipWithoutDashes) === false) {
    return false
  } else {
    var digits = ('' + nipWithoutDashes).split('')
    var checksum = (6 * parseInt(digits[0]) + 5 * parseInt(digits[1]) + 7 * parseInt(digits[2]) + 2 * parseInt(digits[3]) + 3 * parseInt(digits[4]) + 4 * parseInt(digits[5]) + 5 * parseInt(digits[6]) + 6 * parseInt(digits[7]) + 7 * parseInt(digits[8])) % 11

    return (parseInt(digits[9]) === checksum)
  }
}

const nipNumFormat = function (value) {
  return !helpers.req(value) || validateNip(value)
}

const phoneNumFormat = function (value) {
  return !helpers.req(value) ||
    ((value.match(/\+\s|\d|\(|\)|-/g) || []).length >= value.length &&
      (value.match(/\d/g) || []).length >= 9)
}

const mobilePhoneNumFormat = function (value) {
  return !helpers.req(value) || (new RegExp(/\d\d\d\d\d\d\d\d\d/).test(value) && value.length === 9)
}

const postalCodeFormatPL = function (value) {
  return !helpers.req(value) || (new RegExp(/\d\d-\d\d\d/).test(value) && value.length === 6)
}

const postalCodeFormatCZ = function (value) {
  return !helpers.req(value) || (new RegExp(/\d\d\d\s\d\d/).test(value) && value.length === 6)
}

const paymentValue = function (kindOfPayment) {
  return function (value) {
    return !helpers.req(value)
  }
}

export { phoneNumFormat, postalCodeFormatPL, postalCodeFormatCZ, paymentValue, nipNumFormat, mobilePhoneNumFormat }
