<template>
  <div class="container">
    <b-sidebar
      v-model="sidebarOpen"
      type="is-light"
      :fullheight="true"
    >
      <route-leg />
    </b-sidebar>
    <div class="columns mb-5">
      <div class="column is-3">
        <div class="map-sidebar-header">
          <b-button
            icon-left="road"
            type="is-primary is-light mr-3"
            class="mb-3"
            @click="sidebarOpen = !sidebarOpen"
          />
          <Counters :items="counters" class="counters" />
        </div>
        <map-filter />
        <code-reader
          :label="$t('map.codeReader.label')"
          :placeholder="$t('map.codeReader.placeholder')"
          @code="codeScanned"
        />
        <hr>
        <Waypoints @addresses="setWaypoints" />
        <div class="field">
          <b-button
            icon-left="calculator"
            type="is-primary"
            class="is-fullwidth calc-button"
            :loading="calcInProgress"
            :disabled="!waypointsPresent"
            @click="startCalc"
          >
            {{ $t('map.calculateRoute') }}
          </b-button>
        </div>
        <div class="field">
          <b-switch
            v-model="zoomViewport"
            size="is-small"
          >
            {{ $t('map.zoomViewport') }}
          </b-switch>
        </div>
        <orders-batch-ops />
        <side-help :items="['using1', 'using2', 'using3']" scope="map" />
      </div>
      <div class="column">
        <google-map
          :zoom-viewport="zoomViewport"
        />
      </div>
    </div>
  </div>
</template>

<script>
import MapMarkers from '../js/map_markers'
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex'
import CodeReader from './CodeReader.vue'
import Counters from './Counters.vue'
import GoogleMap from './GoogleMap.vue'
import MapFilter from './MapFilter.vue'
import OrdersBatchOps from './OrdersBatchOps.vue'
import RouteLeg from './RouteLeg.vue'
import SideHelp from './SideHelp.vue'
import Waypoints from './Waypoints.vue'

export default {
  components: {
    SideHelp, GoogleMap, Counters, RouteLeg, Waypoints, OrdersBatchOps, MapFilter, CodeReader
  },
  provide () {
    return {
      getMarkers: this.getMapMarkers
    }
  },
  data () {
    return {
      sidebarOpen: false,
      zoomViewport: true
    }
  },
  computed: {
    ...mapState('map', ['calcInProgress']),
    ...mapGetters('map', ['waypointsPresent', 'multiLeg', 'ordersToDisplay']),
    counters () {
      return [
        {
          label: this.$t('map.ordersToDisplay'),
          value: this.ordersToDisplay.length
        }
      ]
    }
  },
  watch: {
    multiLeg: function () {
      if (this.multiLeg) {
        this.zoomViewport = false
      } else {
        this.zoomViewport = true
      }
    }
  },
  created () {
    this.mapMarkers = new MapMarkers()
  },
  methods: {
    getMapMarkers () {
      return this.mapMarkers
    },
    ...mapMutations('map', ['addWaypoint', 'startCalc']),
    ...mapActions('map', ['tryAddWaypoint']),
    errorToast (message) {
      this.$buefy.toast.open({
        message: message,
        type: 'is-danger'
      })
    },
    async codeScanned (code) {
      const marker = this.mapMarkers.findByRefid(code)
      if (!marker) {
        this.errorToast(this.$t('map.codeReader.notFound'))
        return
      }
      if (marker.order.aasm_state !== 'storedin') {
        this.errorToast(this.$t('map.codeReader.badState'))
        return
      }
      const result = await this.tryAddWaypoint(marker)
      if (result !== 'OK') {
        this.errorToast(this.$t(result))
      }
    },
    setWaypoints (addresses) {
      const markers = this.getMapMarkers().markers
      addresses.forEach(id => {
        const marker = markers.find(marker => marker.order.addressId === id)
        if (marker) {
          this.addWaypoint(marker)
        }
      })
    }
  }
}
</script>

<style scoped>
.map-sidebar-header {
  display: flex;
}

.counters {
  flex-grow: 2;
}

</style>
