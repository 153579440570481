/* global google */

import { ToastProgrammatic as Toast } from 'buefy'
import { config } from './mapper_lib'

export default class Mapper {
  constructor (map) {
    this.map = map
    this.renderers = []
    this.routeRequests = []
    this.directionsService = new google.maps.DirectionsService()
  }

  originWaypoint (points) {
    if (!points[0]) {
      return
    }
    return points[0].position
  }

  destinationWaypoint (points) {
    if (!points[points.length - 1]) {
      return
    }
    return points[points.length - 1].position
  }

  middleWaypoints (points) {
    const middles = []
    points.forEach((element, index) => {
      if (index !== 0 && index !== points.length - 1) {
        middles.push({
          location: element.position
        })
      }
    })
    return middles
  }

  prepareRouteRequest (points) {
    this.routeRequests.push({
      origin: this.originWaypoint(points),
      destination: this.destinationWaypoint(points),
      travelMode: 'DRIVING',
      waypoints: this.middleWaypoints(points)
    })
  }

  prepareRouteRequests (waypoints) {
    this.routeRequests = []
    if (waypoints.length <= config.maxLegWaypoints) {
      this.prepareRouteRequest(waypoints)
    } else {
      const first = waypoints.slice(0, config.maxLegWaypoints)
      this.prepareRouteRequest(first)
      const second = waypoints.slice(config.maxLegWaypoints - 1)
      this.prepareRouteRequest(second)
    }
  }

  clearRenderers () {
    this.renderers.forEach(renderer => renderer.setMap(null))
    this.renderers = []
  }

  callDirectionsService (request) {
    return new Promise((resolve, reject) => {
      this.directionsService.route(request, (result, status) => {
        if (status === 'OK') {
          resolve(result)
        } else {
          reject(status)
        }
      })
    })
  }

  routeCalculated (vm, results) {
    results.forEach(result => {
      const renderer = new google.maps.DirectionsRenderer()
      renderer.setOptions({
        suppressMarkers: true,
        preserveViewport: !vm.zoomViewport
      })
      renderer.setMap(this.map)
      renderer.setDirections(result)
      this.renderers.push(renderer)
      vm.addLegs(result.routes[0].legs)
    })
  }

  makeDirectionCalls (vm) {
    const calls = []
    this.routeRequests.forEach((request) => {
      const call = this.callDirectionsService(request)
      calls.push(call)
    })
    Promise.all(calls).then((results) => {
      this.routeCalculated(vm, results)
    }).catch(error => {
      Toast.open({
        message: vm.$t('map.googleError', { status: error }),
        type: 'is-danger',
        duration: 10000
      })
    }).finally(() => {
      vm.stopCalc()
    })
  }

  calculateRoute (vm) {
    vm.clearLegs()
    this.clearRenderers()
    this.prepareRouteRequests(vm.waypoints)
    this.makeDirectionCalls(vm)
  }
}
