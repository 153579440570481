<template>
  <div class="notification">
    <nav class="level">
      <div v-for="item in items" :key="item.label" class="level-item has-text-centered">
        <div>
          <p class="heading">
            {{ item.label }}
          </p>
          <p class="title">
            {{ item.value }}
          </p>
        </div>
      </div>
    </nav>
  </div>
</template>

<script>
export default {
  props: {
    items: {
      type: Array,
      required: true
    }
  }
}
</script>
<style scoped>
</style>
