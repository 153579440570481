<template>
  <div>
    <h2 class="subtitle mt-5">
      Archiwa dokumentów
    </h2>
    <div class="is-flex mb-4" style="justify-content: space-between">
      <b-button
        v-if="!for_prev_month"
        type="is-info"
        class="is-light"
        icon-left="cog"
        @click="create"
      >
        {{ $t('archives.create') }}
      </b-button>
      <b-button
        type="is-info"
        class="is-light"
        @click="fetch"
      >
        <b-icon
          icon="sync"
          size="is-small"
        />
      </b-button>
    </div>
    <div v-if="archives.length > 0">
      <div v-for="archive in archives" :key="archive.id" class="mb-4">
        <b-tooltip :label="archive.created_at" class="mb-1">
          <strong>Archiwum {{ archive.year }}/{{ archive.month }}</strong>
        </b-tooltip>
        <a
          v-if="archive.attached"
          :href="pdfUrl(archive)"
          target="_blank"
          rel="noopener"
          class="button is-info is-fullwidth mb-2"
        >
          <b-icon
            icon="download"
            size="is-small"
          />
          <span>
            Pobierz {{ archive.size }}
          </span>
        </a>
        <b-button
          v-else
          loading
          :disabled="true"
          type="is-info"
          class="is-fullwidth mb-2"
        />
        <div class="mb-2" style="display: flex; justify-content: space-between; gap: 12px;">
          <b-button
            :disabled="archive.files_discarded_at || !archive.attached"
            type="is-danger"
            class="is-light"
            icon-left="trash"
            @click="destroyFiles(archive.id, `${archive.year}/${archive.month}`)"
          >
            {{ $t('archives.destroy_files') }}
          </b-button>
          <b-button
            :disabled="!archive.attached"
            type="is-danger"
            class="is-light"
            icon-left="trash"
            @click="destroy(archive.id, `Archiwum ${archive.year}/${archive.month}`)"
          >
            {{ $t('archives.destroy') }}
          </b-button>
        </div>
      </div>
    </div>
    <b-message v-else type="is-warning">
      Brak archiwów.
    </b-message>
  </div>
</template>

<script>
export default {
  data () {
    return {
      archives: [],
      for_prev_month: true
    }
  },
  mounted () {
    this.fetch()
  },
  methods: {
    pdfUrl (archive) {
      return `/admin/archives/${archive.id}.zip`
    },
    create () {
      const vm = this
      this.$api.archives.create(() => {
        this.fetch()
        vm.$buefy.toast.open({
          message: vm.$t('archives.toasts.create'),
          type: 'is-success'
        })
      })
    },
    fetch () {
      this.$api.archives.index((response) => {
        this.for_prev_month = response.for_prev_month
        this.archives = response.archives.data.map(item => item.attributes)
      })
    },
    destroy (id, name) {
      const key = 'archives.dialog.destroy'
      this.$buefy.dialog.confirm({
        title: this.$t(`${key}.title`),
        message: this.$t(`${key}.message`, { name: name }),
        confirmText: this.$t(`${key}.confirmText`),
        cancelText: this.$t(`${key}.cancelText`),
        type: 'is-danger',
        hasIcon: true,
        onConfirm: () => this.destroyConfirmed(id)
      })
    },
    destroyConfirmed (id) {
      this.$api.archives.destroy(id, (response) => {
        this.$buefy.toast.open({
          message: this.$t('archives.toasts.destroyed'),
          type: 'is-success'
        })
        this.fetch()
      })
    },
    destroyFiles (id, name) {
      const key = 'archives.dialog.filesDestroy'
      this.$buefy.dialog.confirm({
        title: this.$t(`${key}.title`),
        message: this.$t(`${key}.message`, { name: name }),
        confirmText: this.$t(`${key}.confirmText`),
        cancelText: this.$t(`${key}.cancelText`),
        type: 'is-danger',
        hasIcon: true,
        onConfirm: () => this.destroyFilesConfirmed(id)
      })
    },
    destroyFilesConfirmed (id) {
      this.$api.archives.destroyFiles(id, (response) => {
        this.$buefy.toast.open({
          message: this.$t('archives.toasts.filesDestroyed'),
          type: 'is-success'
        })
        this.fetch()
      })
    }
  }
}
</script>

<style scoped>
</style>
