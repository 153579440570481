import page from '../js/page.js'
import cookieConfig from '../js/cookieConfig'
import 'cookieconsent/src/cookieconsent.js'
import 'cookieconsent/build/cookieconsent.min.css'

document.addEventListener('DOMContentLoaded', () => {
  if (page.notControlledBy(['welcome', 'sessions', 'registrations'])) {
    return
  }
  window.cookieconsent.initialise(cookieConfig)
})
