/* global google */

import { orderContent } from './mapper_content'
import { orderStatusIcon } from './mapper_lib'

export default class MapMarkers {
  constructor () {
    this.markers = []
    this.config = {
      premises: {}
    }
  }

  setMap (map) {
    this.map = map
  }

  setConfig (config) {
    this.config = config
  }

  toString () {
    console.log(this.config)
    const config = this.config
    const premises = config.premises
    return `assets_url: ${config.assets_url}, premisesName: ${premises.name}, premisesAddress: ${premises.address}, premisesPosition: ${premises.position}, clickCallback: ${config.clickCallback}`
  }

  markers () {
    return this.markers
  }

  createMarker (args) {
    const infowindow = new google.maps.InfoWindow({
      content: args.contentString
    })

    const marker = new google.maps.Marker({
      map: ['storedin', 'premises'].includes(args.genre) ? this.map : null,
      position: args.position,
      title: args.title,
      icon: this.icon(args.genre),
      genre: args.genre,
      order: {
        id: args.id,
        addressId: args.addressId,
        aasm_state: args.genre,
        refid: args.refid
      }
    })

    marker.addListener('mouseover', function () {
      infowindow.open(this.map, marker)
    })

    marker.addListener('mouseout', function () {
      infowindow.close()
    })

    marker.addListener('click', () => {
      this.config.clickCallback(marker)
    })
    return marker
  }

  icon (name) {
    return this.config.assets_url + '/' + orderStatusIcon(name)
  }

  createPremisesMarker () {
    const premises = this.config.premises
    const marker = this.createMarker({
      position: premises.position,
      contentString: premises.name,
      title: premises.address,
      genre: 'premises',
      addressId: premises.addressId
    })
    this.markers.push(marker)
  }

  createMarkerForOrder (order) {
    const marker = this.createMarker({
      id: order.id,
      position: { lat: order.latitude, lng: order.longitude },
      contentString: orderContent(order),
      title: [[order.street, order.building_num].join(' '), order.postal_code, order.city].join(', '),
      genre: order.aasm_state,
      refid: order.refid,
      addressId: order.address_id
    })
    this.markers.push(marker)
  }

  createOrderMarkers (orders) {
    orders.forEach(order => {
      this.createMarkerForOrder(order)
    })
  }

  /*
  * filter = orderToDisplay vs markers
  * route = waypoints vs markers
  */

  showFor (ids) {
    for (const marker of this.markers) {
      if (!marker.order.id) {
        marker.setMap(this.map)
        continue
      }
      const found = ids.find(id => id === marker.order.id)
      found ? marker.setMap(this.map) : marker.setMap(null)
    }
  }

  showOnly (markers) {
    this.markers.forEach(marker => {
      const found = markers.includes(marker)
      found ? marker.setMap(this.map) : marker.setMap(null)
    })
  }

  createMarkers (orders) {
    this.createPremisesMarker()
    this.createOrderMarkers(orders)
  }

  findByRefid (refid) {
    return this.markers.find(marker => marker.order.refid === refid)
  }
}
