<template>
  <div class="field is-grouped action-buttons">
    <div class="control">
      <div v-if="anySelected" class="selected-info">
        {{ $t('bundle.actions.selected') }} ({{ selectedCount }})
      </div>
      <button v-if="anySelected" class="button is-warning" @click="$emit('remove-items')">
        <b-icon icon="list-ul" size="is-small" />
        <span>{{ $t('bundle.actions.removeItems') }}</span>
      </button>
      <button v-if="anySelected" class="button status-storedout" @click="$emit('storeout-items')">
        <b-icon icon="truck" size="is-small" />
        <span>{{ $t('bundle.actions.storeoutItems') }}</span>
      </button>
      <button v-if="anySelected" class="button status-done" @click="$emit('done-items')">
        <b-icon icon="check" size="is-small" />
        <span>{{ $t('bundle.actions.doneItems') }}</span>
      </button>
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'
const { mapGetters } = createNamespacedHelpers('orders')
export default {
  computed: {
    ...mapGetters(['anySelected', 'selectedCount'])
  }
}
</script>

<style scoped>
.action-buttons {
  justify-content: space-between;
  margin-bottom: 1em;
}
.selected-info {
  margin-bottom: .5em;
}
</style>
