<template>
  <div class="columns">
    <div class="column is-3">
      <SideHelp :items="['using']" scope="appNotifications" />
    </div>
    <div class="column">
      <h1 class="title">
        {{ $t(`appNotifications.title`) }}
      </h1>
      <div class="columns">
        <div class="column is-8">
          <div class="columns">
            <div class="column">
              <FormLabel attr="users" scope="appNotifications" />
              <div class="field">
                <b-radio
                  v-model="group"
                  name="group"
                  native-value="toSelected"
                >
                  {{ $t('appNotifications.sendToSelected') }}
                </b-radio>
                <b-radio
                  v-model="group"
                  name="group"
                  native-value="toAll"
                >
                  {{ $t('appNotifications.sendToAll') }}
                </b-radio>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-show="group === 'toSelected'" class="columns">
        <div class="column is-12">
          <b-taginput
            v-model="selected"
            :data="filteredUsers"
            ellipsis
            autocomplete
            type="is-info"
            field="email"
            icon="user"
            :placeholder="$t('appNotifications.tagPlaceholder')"
            @typing="getFilteredUsers"
          >
            <template slot-scope="props">
              <strong>{{ props.option.company }} / {{ props.option.email }}</strong>
            </template>
            <template slot="empty">
              {{ $t('appNotifications.noUserFound') }}
            </template>
          </b-taginput>
          <FieldErrors :vform="$v" field="selected" />
        </div>
      </div>
      <div class="columns">
        <div class="column is-6">
          <TextField
            v-model="subject"
            scope="appNotifications"
            field="subject"
            obligatory
            :vform="$v"
            @input="$v.subject.$touch()"
          />
        </div>
      </div>
      <div class="columns">
        <div class="column is-12">
          <div class="columns">
            <div class="column">
              <div class="field">
                <FormLabel required attr="message" scope="appNotifications" />
                <div class="control">
                  <quill-editor
                    ref="customEditor"
                    v-model="message"
                    :options="editorOption"
                    @change="$v.rawMessage.$touch()"
                  />
                  <FieldErrors :vform="$v" field="rawMessage" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="action-buttons actions-bottom">
        <div class="left">
          <button :disabled="formInvalid" class="button is-info" @click="sendMessage">
            <b-icon
              icon="envelope"
              size="is-small"
            />
            <span>
              {{ $t('appNotifications.sendMessage') }}
            </span>
          </button>
          <button class="button is-warning" @click="clearForm">
            <b-icon
              icon="backspace"
              size="is-small"
            />
            <span>
              {{ $t('appNotifications.clearForm') }}
            </span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FieldErrors from './FieldErrors.vue'
import { required, minLength, maxLength } from 'vuelidate/lib/validators'
import FormLabel from './FormLabel.vue'
import SideHelp from './SideHelp.vue'
import TextField from './TextField.vue'
import { quillEditor } from 'vue-quill-editor'

export default {
  components: {
    SideHelp, FormLabel, TextField, FieldErrors, quillEditor
  },
  data () {
    return {
      message: '',
      group: 'toSelected',
      subject: '',
      selected: [],
      users: [],
      filteredUsers: [],
      editorOption: {
        modules: {
          toolbar: ['bold', 'italic', 'underline', 'strike']
        },
        placeholder: this.$t('appNotifications.enterMessage')
      }
    }
  },
  computed: {
    rawMessage () {
      return this.message.replace(/(<([^>]+)>)/ig, '')
    },
    formInvalid () {
      return this.$v.$invalid
    },
    query () {
      return {
        notification: {
          group: this.group,
          subject: this.subject,
          message: this.message,
          selected: this.selected.map((value) => value.id)
        }
      }
    }
  },
  mounted () {
    this.fetch()
  },
  beforeDestroy () {
    this.editor.destroy()
  },
  methods: {
    fetch () {
      this.$api.users.forNotify((response) => {
        this.users = response.user_notifies
      })
    },
    clearForm () {
      this.group = 'toSelected'
      this.selected = []
      this.subject = ''
      this.message = ''
    },
    getFilteredUsers (text) {
      this.$v.selected.$touch()
      this.filteredUsers = this.users.filter((option) => {
        return `${option.company} ${option.email}`.toLowerCase().indexOf(text.toLowerCase()) >= 0
      })
    },
    sendMessage () {
      this.$api.users.notify(this.query, (response) => {
        this.$buefy.toast.open({
          message: this.$t('appNotifications.sent'),
          type: 'is-success'
        })
      })
    }
  },
  validations: {
    rawMessage: {
      required,
      minLength: minLength(10),
      maxLength: maxLength(1500)
    },
    subject: {
      required,
      minLength: minLength(5),
      maxLength: maxLength(50)
    },
    selected: {
      noUsersSelected () {
        if (this.group === 'toSelected' && this.selected.length === 0) {
          return false
        } else {
          return true
        }
      }
    }
  }
}
</script>

<style>
.ql-toolbar {
  border-radius: 4px 4px 0 0;
  box-shadow: inset 0 1px 2px rgba(10, 10, 10, 0.1);
}

.ql-container {
  font-size: 1em;
  border-radius: 0 0 4px 4px;
}

.ql-editor.ql-blank::before {
  color: rgba(0,0,0,0.1 );
  font-style: normal;
  font-family: BlinkMacSystemFont, -apple-system, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", "Helvetica", "Arial", sans-serif;
}
</style>
