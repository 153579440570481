export default {
  props: {
    id: {
      validator: function (value) {
        return ['undefiend', 'string', 'number'].includes(typeof (value))
      }
    }
  },
  data: function () {
    return {
      recordLoaded: false
    }
  },
  computed: {
    isPersisted () {
      return !!this.id
    }
  },
  watch: {
    $route: function () {
      if (this.isPersisted) {
        this.loadRecord()
      } else {
        this.resetForm()
      }
    },
    user: {
      immediate: true,
      handler: function () {
        this.loadRecord()
      }
    }
  },
  mounted () {
    this.loadRecord()
  },
  methods: {
    loadRecord () {
      if (this.userLoaded && this.isPersisted) {
        if (this.recordLoaded) {
          return
        }
        this.recordLoaded = true
        this.load()
      }
    }
  }
}
