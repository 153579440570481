<template>
  <div class="modal">
    <div class="modal-background" @click="$parent.dialogClose()" />
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">
          {{ $t(`${prefix}.title`) }}
        </p>
        <button class="delete" aria-label="close" @click="$parent.dialogClose()" />
      </header>
      <section class="modal-card-body">
        {{ $t(`${prefix}.message`) }}
      </section>
      <section class="modal-card-body">
        <strong>{{ target.ident }}</strong>
      </section>
      <section v-if="target.notification" class="modal-card-body">
        <p class="notification is-warning">
          {{ $t(`${prefix}.notification`) }}
        </p>
      </section>
      <footer class="modal-card-foot">
        <button class="button" :class="buttonKlass" @click="confirmation">
          <b-icon
            :icon="buttonIcon"
            size="is-small"
          />
          <span>
            {{ $t(`${prefix}.button`) }}
          </span>
        </button>
        <button class="button" @click="$parent.dialogClose()">
          {{ $t('modal.common.cancel') }}
        </button>
      </footer>
    </div>
  </div>
</template>

<script>
import { dialogProps } from 'js/constants'

export default {
  props: {
    target: {
      type: Object,
      required: true
    },
    scope: {
      type: String,
      required: true
    }
  },
  computed: {
    prefix () {
      return `${this.scope}.dialog.${this.target.action}`
    },
    dProps () {
      return dialogProps[this.target.action]
    },
    buttonKlass () {
      return this.dProps.klass
    },
    buttonIcon () {
      return this.dProps.icon
    }
  },
  methods: {
    confirmation () {
      this.$parent.dialogClose()
      this.$emit(`${this.target.action}Confirmed`)
    }
  }
}
</script>

<style scoped>
.modal {
  display: block;
}
.modal-card {
  margin-top: 3em;
}
.modal-card-foot {
  justify-content: space-between;
}
</style>
