export default class Storage {
  constructor(parent) {
    this.parent = parent
  }

  url(type) {
    return `admin/${type}.json`
  }

  store(type, params, onSuccess) {
    this.parent.send('POST', this.url(type), params, onSuccess)
  }
}
