<template>
  <div v-if="bundle.id" class="card">
    <div class="card-content">
      <div class="content">
        <ul>
          <li v-for="attr in attrs" :key="attr.name">
            {{ $t(`bundle.fields.${attr.name}`) }}: <strong>{{ attr.value }}</strong>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    bundle: {
      type: Object,
      required: true
    }
  },
  computed: {
    attrs () {
      const arr = [
        {
          name: 'created_at',
          value: this.bundle.created_at
        },
        {
          name: 'updated_at',
          value: this.bundle.updated_at
        }
      ]
      return arr
    }
  }
}
</script>

<style scoped>
ul {
  margin: 0;
  font-size: small;
  list-style: square;
}
</style>
