<template>
  <span>
    {{ value.toLocaleString('pl-PL', { style: 'currency', currency: 'PLN' }) }}
  </span>
</template>

<script>
export default {
  props: {
    value: {
      type: Number,
      required: true
    }
  }
}
</script>
