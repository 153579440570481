<template>
  <b-collapse :open="false" position="is-top" aria-id="contentIdForA11y1" class="side-help">
    <a slot="trigger" slot-scope="props" aria-controls="contentIdForA11y1" class="side-help-trigger">
      <b-icon :icon="!props.open ? 'chevron-down' : 'chevron-up'" />
      {{ !props.open ? $t('sideHelp.show') : $t('sideHelp.hide') }}
    </a>
    <article v-for="item in items" :key="item" class="message is-info side-help-items">
      <div class="message-body">
        {{ $t(`${scope}.${item}`) }}
      </div>
    </article>
  </b-collapse>
</template>

<script>
export default {
  props: {
    items: {
      type: Array,
      required: true
    },
    scope: {
      type: String,
      default: 'form.help'
    }
  }
}
</script>

<style scoped>
.side-help {
  margin-bottom: .75em;
}
</style>
