<template>
  <div class="modal">
    <div class="modal-background" @click="$parent.dialogClose()" />
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">
          Dodanie zlecenia do dokumentu WZ
        </p>
        <button class="delete" aria-label="close" @click="$parent.dialogClose()" />
      </header>
      <section v-if="target.notes.length === 0" class="modal-card-body">
        <b-notification
          v-if="target.notes.length === 0"
          type="is-warning is-light"
          :closable="false">
          Klient nie ma żadnych dokumentów WZ.
        </b-notification>
      </section>
      <section v-else class="modal-card-body">
        <p class="my-4">
          Wybierz dokument WZ, do którego dodać zlecenie:
        </p>
        <b-select v-model="selectedNoteId" placeholder="Wybierz WZ">
          <option
            v-for="option in target.notes"
            :value="option.id"
            :key="option.id">
            {{ option.name }}
          </option>
        </b-select>
      </section>
      <footer class="modal-card-foot">
        <button :disabled="!selectedNoteId" class="button is-success" @click="confirmation">
          <b-icon
            icon="plus"
            size="is-small"
          />
          <span>
            Dodaj
          </span>
        </button>
        <button class="button" @click="$parent.dialogClose()">
          {{ $t('modal.common.cancel') }}
        </button>
      </footer>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    target: {
      type: Object,
      required: true
    },
    scope: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      selectedNoteId: null,
    }
  },
  methods: {
    confirmation () {
      this.$parent.dialogClose()
      this.$emit('confirmed',this.target.refId, this.selectedNoteId)
    }
  }
}
</script>

<style scoped>
.modal {
  display: block;
}
.modal-card {
  margin-top: 3em;
}
.modal-card-foot {
  justify-content: space-between;
}
</style>
