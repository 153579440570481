import appApi from '../../js/api/AppApi'
import { config } from '../../js/mapper_lib'

const state = {
  calcInProgress: false,
  legs: [],
  meta: {},
  orders: [],
  postalCode: '',
  selectedItems: [],
  producer: false,
  storedin: true,
  storedout: false,
  waypoints: [],
  onlyWaypoints: false,
  insertIndex: null
}

const mutations = {
  startCalc (state) {
    state.calcInProgress = true
  },
  stopCalc (state) {
    state.calcInProgress = false
  },
  clearLegs (state) {
    state.legs = []
  },
  addLegs (state, legs) {
    legs.forEach(leg => state.legs.push(leg))
  },
  setOrders (state, payload) {
    state.orders = payload.orders
    state.meta = payload.meta
  },
  addWaypoint (state, marker) {
    const position = {
      lat: marker.position.lat(),
      lng: marker.position.lng()
    }
    const waypoint = {
      addressId: marker.order.addressId,
      position,
      title: marker.title,
      marker
    }

    if (state.insertIndex !== null) {
      state.waypoints.splice(state.insertIndex + 1, 0, waypoint)
    } else {
      state.waypoints.push(waypoint)
    }
  },
  setWaypoints (state, points) {
    state.waypoints = points
  },
  removeWaypoint (state, index) {
    state.waypoints.splice(index, 1)
  },
  removeAllWaypoints (state) {
    state.waypoints = []
  },
  updateProducer (state, value) {
    state.producer = value
  },
  updateStoredin (state, value) {
    state.storedin = value
  },
  updateStoredout (state, value) {
    state.storedout = value
  },
  updatePostalCode (state, value) {
    state.postalCode = value
  },
  updateOnlyWaypoints (state, value) {
    state.onlyWaypoints = value
  },
  setInsertIndex (state, index) {
    if (index >= state.waypoints.length - 1) {
      state.insertIndex = null
      return
    }
    if (state.insertIndex === index) {
      state.insertIndex = null
    } else {
      state.insertIndex = index
    }
  }
}

const getters = {
  waypointsPresent (state) {
    return state.waypoints.length >= 2
  },
  waypointPresent (state) {
    return state.waypoints.length >= 1
  },
  multiLeg (state) {
    return state.waypoints.length > config.maxLegWaypoints
  },
  ordersToDisplay (state) {
    return state.orders.filter((order) => {
      const regex = RegExp(`^${state.postalCode}`)
      let postalMatched = false
      let stateMatched = false
      if (regex.test(order.postal_code)) {
        postalMatched = true
      }
      const status = order.aasm_state
      if ((state.producer && status === 'producer') ||
        (state.storedout && status === 'storedout') ||
        (state.storedin && status === 'storedin')
      ) {
        stateMatched = true
      }
      return postalMatched && stateMatched
    })
  }
}

const actions = {
  fetchOrders ({ commit }) {
    appApi.maps.orders(
      (response) => {
        commit('setOrders', {
          orders: response.data,
          meta: response.meta
        })
      }
    )
  },
  tryAddWaypoint ({ commit }, marker) {
    if (state.waypoints.length >= config.maxWaypoints) {
      return
    }
    if (marker.order.id) {
      const found = state.waypoints.find(function (element) {
        return element.marker.order.id === marker.order.id
      })
      if (found) {
        return 'map.codeReader.alreadyAdded'
      }
    }
    commit('addWaypoint', marker)
    return 'OK'
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
