import debounce from 'js/debounce'

export default {
  data () {
    return {
      items: {
        available: [],
        selected: null,
        isFetching: false
      }
    }
  },
  methods: {
    getItemsLazy: debounce(function (pattern) {
      this.getItems(pattern)
    }, 250),
    setItems (response) {
      this.items.available = []
      response.data.map(elem => elem.attributes).forEach((item) => this.items.available.push(item))
    },
    getItems (pattern) {
      if (!pattern.length) {
        this.items.available = []
        return
      }
      this.items.isFetching = true
      this.getItemsFunction(pattern, this.setItems)
      this.items.isFetching = false
    }
  }
}
