<template>
  <label :for="attr" class="label">
    {{ $t(`${scope}.fields.${attr}`) }}
    <abbr v-if="required" :title="$t('form.obligatory')">
      *
    </abbr>
  </label>
</template>

<script>
export default {
  props: {
    attr: {
      type: String,
      required: true
    },
    required: {
      type: Boolean,
      required: false
    },
    scope: {
      type: String,
      required: true
    }
  }
}
</script>

<style>

</style>
