export default {
  palette: {
    popup: {
      background: '#eb6c44',
      text: '#ffffff'
    },
    button: {
      background: '#f5d948'
    }
  },
  theme: 'classic',
  position: 'bottom-right',
  content: {
    message: 'Serwis korzysta z technologii Ciasteczek. Korzystając z serwisu zgadzasz się na użycie tej technologii.',
    dismiss: 'Rozumiem',
    link: 'Regulamin serwisu',
    href: '/doc/regulamin_serwisu.pdf'
  }
}
