export default class PrintSettings {
  constructor (parent) {
    this.parent = parent
  }

  baseUrl () {
    return 'home/print-settings.json'
  }

  params (form) {
    return {
      print_settings: {
        network: form.network,
        label: form.label,
        combination: form.combination
      }
    }
  }

  show (onSuccess) {
    this.parent.send('GET', this.baseUrl(), {}, onSuccess)
  }

  update (form, onSuccess) {
    this.parent.send('POST', this.baseUrl(), this.params(form), onSuccess)
  }
}
