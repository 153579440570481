import debounce from 'js/debounce'

export default {
  data () {
    return {
      bundles: {
        available: [],
        selected: null,
        isFetching: false
      }
    }
  },
  computed: {
    isBundleSelected() {
      return !!this.bundles.selected
    }
  },
  methods: {
    getBundlesLazy: debounce(function (pattern) {
      this.getBundles(pattern)
    }, 250),
    getBundles (pattern) {
      if (!pattern.length) {
        this.bundles.available = []
        return
      }
      this.bundles.isFetching = true
      this.$api.bundles.index({
        pattern
      }, (response) => {
        this.bundles.available = []
        response.data.map(elem => elem.attributes).forEach((item) => this.bundles.available.push(item))
      })
      this.bundles.isFetching = false
    }
  }
}
