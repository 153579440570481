<template>
  <b-table
    :data="items"
    :loading="loading"
    :paginated="paginated"
    backend-pagination
    pagination-position="both"
    :checked-rows.sync="checkedRows"
    checkable
    :total="total"
    :per-page="meta.perPage"
    :current-page.sync="page"
    aria-next-label="Next page"
    aria-previous-label="Previous page"
    aria-page-label="Page"
    aria-current-label="Current page"
    backend-sorting
    :default-sort-direction="sortOrder"
    :default-sort="[sortField, sortOrder]"
    @page-change="onPageChanged"
    @sort="onSortChanged"
    @check="itemChecked"
    @check-all="allChecked"
  >
    <b-table-column v-slot="props" field="name" :label="$t('settlements.fields.name')">
      <router-link :to="{ name: 'settlementEdit', params: { id: props.row.id }}">
        {{ props.row.name }}
      </router-link>
    </b-table-column>
    <b-table-column v-slot="props" field="dated_to" :label="$t('settlements.fields.dated_to')">
      {{ props.row.dated_to }}
    </b-table-column>
    <b-table-column v-slot="props" field="items_count" :label="$t('settlements.fields.items_count')" numeric>
      {{ props.row.items_count }}
    </b-table-column>
    <b-table-column v-slot="props" field="payment_value_total" :label="$t('settlements.fields.payment_value_total')" numeric>
      <CurrencyFormatter :value="props.row.payment_value_total" />
    </b-table-column>
    <b-table-column v-slot="props" field="transport_cost_total" :label="$t('settlements.fields.transport_cost_total')" numeric>
      <CurrencyFormatter :value="props.row.transport_cost_total" />
    </b-table-column>
    <b-table-column v-slot="props" field="vat_tax" :label="$t('settlements.fields.vat_tax')" numeric>
      <CurrencyFormatter :value="props.row.vat_tax" />
    </b-table-column>
    <b-table-column v-slot="props" field="payment_balance" :label="$t('settlements.fields.payment_balance')" numeric>
      <CurrencyFormatter :value="props.row.payment_balance" />
    </b-table-column>

    <b-table-column field="id" :label="$t('settlements.fields.destroy')" centered>
      <template #header>
        <b-tooltip
          :label="$t('settlements.fields.destroy')"
        >
          <b-icon
            icon="trash"
            size="is-small"
          />
        </b-tooltip>
      </template>
      <template #default="props">
        <b-button @click="$emit('destroy', props.row.id, props.row.name)">
          <b-icon
            class="destroy"
            icon="trash"
            size="is-small"
            type="is-danger"
          />
        </b-button>
      </template>
    </b-table-column>
    <template slot="empty">
      <section class="section">
        <div class="content has-text-grey has-text-centered">
          <p>
            <b-icon
              icon="times-circle"
              size="is-large"
            />
          </p>
          <p>{{ $t('settlements.noRecord') }}</p>
        </div>
      </section>
    </template>
  </b-table>
</template>

<script>
import TableMixin from '../mixins/table.js'
import CurrencyFormatter from './CurrencyFormatter.vue'

export default {
  components: { CurrencyFormatter },
  mixins: [TableMixin],
  data () {
    return {
      checkedRows: []
    }
  },
  methods: {
    itemChecked (rows) {
      this.$emit('check', rows)
    },
    allChecked (rows) {
      this.$emit('check', rows)
    }
  }
}
</script>
