<template>
  <p>
    <span class="field-label">
      {{ $t(`order.fields.${field}`) }}:
    </span>
    <strong v-if="isValue">{{ value }}</strong>
    <b-tag v-else type="is-info">
      Brak
    </b-tag>
  </p>
</template>

<script>
export default {
  props: {
    field: {
      type: String,
      required: true
    },
    value: {
      type: String,
      required: true
    }
  },
  computed: {
    isValue () {
      return this.value.length > 0
    }
  }
}
</script>
