export default class Orders {
  constructor (parent) {
    this.parent = parent
  }

  baseUrl (scope, id = null) {
    if (id) {
      return `${scope}/orders/${id}.json`
    } else {
      return `${scope}/orders.json`
    }
  }

  orderParams (form) {
    return {
      order: {
        client: {
          first_name: form.first_name,
          last_name: form.last_name,
          phone_num: form.phone_num
        },
        address: {
          street: form.street,
          building_num: form.building_num,
          flat_num: form.flat_num,
          postal_code: form.postal_code,
          city: form.city,
          state: form.state,
          country: form.country
        },
        attrs: {
          info: form.info,
          quantity: form.quantity,
          kind_of_payment: form.kind_of_payment,
          payment_value: form.payment_value,
          parcel_add: form.parcel_add,
          parcel_kind: form.parcel_kind,
          parcel_name: form.parcel_name,
          transport_cost: form.transport_cost,
          transport_kind: form.transport_kind
        }
      }
    }
  }

  index (scope, params, onSuccess) {
    this.parent.send('GET', `${this.baseUrl(scope)}?${this.parent.serializeParams(params)}`, {}, onSuccess)
  }

  show (scope, id, onSuccess) {
    this.parent.send('GET', this.baseUrl(scope, id), {}, onSuccess)
  }

  create (params, onSuccess) {
    this.parent.send('POST', 'home/orders.json', this.orderParams(params), onSuccess)
  }

  update (scope, params, onSuccess) {
    const id = params.id
    this.parent.send('PATCH', this.baseUrl(scope, id), this.orderParams(params), onSuccess)
  }

  cancel (scope, id, onSuccess) {
    this.parent.send('DELETE', this.baseUrl(scope, id), {}, onSuccess)
  }

  stateChange (scope, id, targetState, onSuccess) {
    this.parent.send('POST', `${scope}/orders/${id}/${targetState}.json`, {}, onSuccess)
  }
}
