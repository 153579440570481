<template>
  <b-navbar>
    <template slot="brand">
      <b-navbar-item>
        <a href="/">
          <img src="../../assets/images/logo_black_cyna.png">
        </a>
      </b-navbar-item>
    </template>
    <template slot="start">
      <b-navbar-item tag="router-link" to="/dashboard" href="#" exact>
        <b-icon
          icon="tachometer-alt"
          size="is-small"
        />
        <span>
          {{ $t('navbar.dashboard') }}
        </span>
      </b-navbar-item>
      <b-navbar-item v-can:manage tag="router-link" to="/map" href="#" exact>
        <b-icon
          icon="map-marked-alt"
          size="is-small"
        />
        <span>
          {{ $t('navbar.map') }}
        </span>
      </b-navbar-item>
      <b-navbar-dropdown v-can:manage>
        <template #label>
          <b-icon
            icon="box-open"
            size="is-small"
          />
          <span>
            {{ $t('navbar.packagesHandling') }}
          </span>
        </template>
        <b-navbar-item tag="router-link" has-link to="/storein" href="#">
          {{ $t('navbar.storein') }}
        </b-navbar-item>
        <b-navbar-item tag="router-link" has-link to="/storeout" href="#">
          {{ $t('navbar.storeout') }}
        </b-navbar-item>
      </b-navbar-dropdown>
      <b-navbar-dropdown v-can:manage>
        <template #label>
          <b-icon
            icon="list"
            size="is-small"
          />
          <span>
            {{ $t('navbar.bundles.title') }}
          </span>
        </template>
        <b-navbar-item tag="router-link" has-link to="/bundles" href="#">
          {{ $t('navbar.bundles.list') }}
        </b-navbar-item>
        <b-navbar-item tag="router-link" has-link to="/bundle" href="#">
          {{ $t('navbar.bundles.addNew') }}
        </b-navbar-item>
      </b-navbar-dropdown>
      <b-navbar-item v-can:addNew="'Order'" tag="router-link" to="/orders" href="#" exact>
        <b-icon
          icon="plus-circle"
          size="is-small"
        />
        <span>
          {{ $t('navbar.createOrder') }}
        </span>
      </b-navbar-item>
      <b-navbar-item v-can:manage tag="router-link" to="/settlements" href="#" exact>
        <b-icon
          icon="user-edit"
          size="is-small"
        />
        <span>
          {{ $t('navbar.settlements.title') }}
        </span>
      </b-navbar-item>
      <b-navbar-dropdown v-can:manage>
        <template #label>
          <b-icon
            icon="directions"
            size="is-small"
          />
          <span>
            {{ $t('navbar.routes.title') }}
          </span>
        </template>
        <b-navbar-item tag="router-link" has-link to="/routes" href="#">
          {{ $t('navbar.routes.list') }}
        </b-navbar-item>
        <b-navbar-item tag="router-link" has-link to="/route" href="#">
          {{ $t('navbar.routes.addNew') }}
        </b-navbar-item>
      </b-navbar-dropdown>
      <b-navbar-item v-can:manage tag="router-link" to="/dispatch-notes" href="#" exact>
        <b-icon
          icon="truck"
          size="is-small"
        />
        <span>
          {{ $t('navbar.dispatchNotes.title') }}
        </span>
      </b-navbar-item>
    </template>
    <template slot="end">
      <b-navbar-item v-can:manage tag="router-link" to="/users" href="#" exact>
        <b-icon
          icon="users"
          size="is-small"
        />
        <span>
          {{ $t('navbar.users') }}
        </span>
      </b-navbar-item>
      <b-dropdown position="is-bottom-left" aria-role="list">
        <button slot="trigger" class="button is-info user-dropdown">
          <b-icon
            icon="user"
            size="is-small"
          />
          <span>
            {{ user.username }}
          </span>
          <b-icon
            icon="chevron-down"
            size="is-small"
          />
        </button>
        <b-dropdown-item has-link aria-role="listitem">
          <router-link to="/password">
            {{ $t('navbar.passwordChange') }}
          </router-link>
        </b-dropdown-item>
        <b-dropdown-item v-can:hasAccount has-link aria-role="listitem">
          <router-link to="/account">
            {{ $t('navbar.account') }}
          </router-link>
        </b-dropdown-item>
        <b-dropdown-item has-link aria-role="listitem">
          <router-link to="/print-settings">
            {{ $t('navbar.printSettings') }}
          </router-link>
        </b-dropdown-item>
        <b-dropdown-item v-can:manage has-link aria-role="listitem">
          <router-link to="/sms">
            {{ $t('navbar.sms') }}
          </router-link>
        </b-dropdown-item>
        <b-dropdown-item v-can:manage has-link aria-role="listitem">
          <router-link to="/printnode">
            {{ $t('navbar.printnode') }}
          </router-link>
        </b-dropdown-item>
        <b-dropdown-item v-can:manage has-link aria-role="listitem">
          <router-link to="/settings">
            {{ $t('navbar.appSettings') }}
          </router-link>
        </b-dropdown-item>
        <b-dropdown-item v-can:manage has-link aria-role="listitem">
          <router-link to="/app-notifications">
            {{ $t('navbar.appNotifications') }}
          </router-link>
        </b-dropdown-item>
        <b-dropdown-item v-can:manage has-link aria-role="listitem">
          <a href="/sidekiq">
            {{ $t('navbar.sidekiq') }}
          </a>
        </b-dropdown-item>
        <b-dropdown-item class="logout-item" aria-role="listitem">
          <LogoutForm />
        </b-dropdown-item>
      </b-dropdown>
    </template>
  </b-navbar>
</template>

<script>
import { mapState } from 'vuex'
import LogoutForm from './LogoutForm.vue'

export default {
  components: {
    LogoutForm
  },
  data: function () {
    return {
    }
  },
  computed: {
    ...mapState([
      'user'
    ])
  }
}
</script>
<style scoped>
.navbar {
  margin: 0.5em 0 1.5em;
}
.dropdown-item {
  padding: 0.5rem 0.5rem;
}
.user-dropdown {
  top: 8px;
}
</style>
