export default (function () {
  return {
    body: function () {
      return document.getElementsByTagName('body')[0]
    },
    controller: function () {
      return this.body().dataset.controller
    },
    action: function () {
      return this.body().dataset.action
    },
    checkInArray (array) {
      return array.includes(this.controller())
    },
    checkName (name) {
      return name === this.controller()
    },
    checkAction (actionName) {
      return actionName === this.action()
    },
    controlledBy: function (controllerNames, actionName) {
      if (Array.isArray(controllerNames)) {
        if (!this.checkInArray(controllerNames)) {
          return false
        }
      } else {
        if (!this.checkName(controllerNames)) {
          return false
        }
      }
      if (actionName) {
        if (!this.checkAction(actionName)) {
          return false
        }
      }
      // console.log('Page controlled:', controllerNames, actionName)
      return true
    },
    notControlledBy (controllerNames, actionName) {
      // console.log(controllerNames, actionName, this.controller(), this.action())
      return !this.controlledBy(controllerNames, actionName)
    },
    hasClass (klassName) {
      return this.body().classList.contains(klassName)
    }
  }
})()
