<template>
  <div class="action-buttons">
    <button
      v-if="!record.id"
      :disabled="formInvalid"
      class="button is-primary"
      @click="$emit('create')"
    >
      <b-icon icon="plus-circle" size="is-small" />
      <span>{{ $t(`${scope}.actions.create`) }}</span>
    </button>
    <button
      v-else
      v-can:modify="record"
      :disabled="formInvalid"
      class="button is-primary"
      @click="$emit('update')"
    >
      <b-icon icon="save" size="is-small" />
      <span>{{ $t(`${scope}.actions.update`) }}</span>
    </button>
  </div>
</template>

<script>
export default {
  props: {
    record: {
      type: Object,
      required: true
    },
    vobj: {
      type: Object,
      required: true
    },
    scope: {
      type: String,
      required: true
    }
  },
  data () {
    return {}
  },
  computed: {
    formInvalid () {
      return this.vobj.$invalid
    }
  }
}
</script>
