<template>
  <div class="columns">
    <div class="column is-3">
      <SideHelp :items="['using']" scope="printnode" />
    </div>
    <div class="column">
      <h1 class="title">
        {{ $t(`printnode.title`) }}
      </h1>
      <div class="columns">
        <div class="column is-6">
          <TextField
            v-model="form.printerId"
            field="printerId"
            obligatory
            scope="printnode"
            :vform="$v.form"
            @input="$v.form.printerId.$touch()"
          />
          <div class="action-buttons actions-middle">
            <button :disabled="formInvalid" class="button is-primary" @click="save">
              <b-icon
                icon="save"
                size="is-small"
              />
              <span>
                {{ $t('printnode.save') }}
              </span>
            </button>
          </div>
        </div>
      </div>
      <div class="columns">
        <div class="column">
          <h2 class="subtitle">
            {{ $t('printnode.subtitle') }}
          </h2>
          <div v-if="isLoading" class="column">
            {{ $t('printnode.isLoading') }}
          </div>
          <div v-else>
            <pre v-if="response" class="response-container">
              {{ response | prettify }}
            </pre>
          </div>
        </div>
      </div>
      <div class="columns last-on-page">
        <div class="column is-6">
          <div class="action-buttons actions-bottom">
            <button class="button is-info" @click="whoami">
              <b-icon
                icon="user"
                size="is-small"
              />
              <span>
                {{ $t('printnode.whoami') }}
              </span>
            </button>
            <button class="button is-info" @click="printers">
              <b-icon
                icon="print"
                size="is-small"
              />
              <span>
                {{ $t('printnode.printers') }}
              </span>
            </button>
            <button class="button is-info" @click="printjobs">
              <b-icon
                icon="layer-group"
                size="is-small"
              />
              <span>
                {{ $t('printnode.printjobs') }}
              </span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import SideHelp from './SideHelp.vue'
import TextField from './TextField.vue'
import { required, numeric } from 'vuelidate/lib/validators'
import PrintNodeApi from 'js/api/PrintNodeApi'

export default {
  components: {
    SideHelp, TextField
  },
  data () {
    return {
      papi: null,
      response: null,
      isLoading: false,
      form: {
        printerId: ''
      }
    }
  },
  computed: {
    ...mapState([
      'user', 'userMeta', 'userLoaded'
    ]),
    formInvalid () {
      return this.$v.form.$invalid
    }
  },
  watch: {
    userMeta: function () {
      this.setPapi()
    }
  },
  mounted () {
    this.fetch()
    this.setPapi()
  },
  methods: {
    setPapi () {
      if (this.userLoaded && !this.papi) {
        this.papi = new PrintNodeApi(
          this.userMeta.printnode_api_key,
          this.userMeta.printer_id,
          this.userMeta.pdfs_path
        )
      }
    },
    fetch () {
      this.$api.settings.index((response) => {
        this.form.printerId = response.data.rw_params.printer_id
      })
    },
    save () {
      this.$api.settings.update({
        settings: {
          printer_id: this.form.printerId
        }
      }, (response) => {
        this.$buefy.toast.open({
          message: this.$t('printnode.saved'),
          type: 'is-success'
        })
      })
    },
    whoami () {
      this.isLoading = true
      this.papi.whoami((response) => {
        this.response = response
      }, undefined, () => {
        this.isLoading = false
      })
    },
    printers () {
      this.isLoading = true
      this.papi.printers((response) => {
        this.response = response
      }, undefined, () => {
        this.isLoading = false
      })
    },
    printjobs () {
      this.isLoading = true
      this.papi.printjobs((response) => {
        this.response = response
      }, undefined, () => {
        this.isLoading = false
      })
    }
  },
  validations: {
    form: {
      printerId: {
        required,
        numeric
      }
    }
  }
}
</script>

<style scoped>
.response-container {
  border: 1px solid #ddd;
  padding: 1em;
  margin-bottom: 1em;
}
</style>
