<template>
  <div>
    <div class="google-map">
      {{ $t('map.loading') }}
    </div>
  </div>
</template>

<script>
/* global google */
import Mapper from 'js/mapper'
import { mapState, mapMutations, mapActions, mapGetters } from 'vuex'

export default {
  inject: ['getMarkers'],
  props: {
    zoomViewport: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
    }
  },
  computed: {
    ...mapState('map', {
      meta: 'meta',
      waypoints: 'waypoints',
      calcInProgress: 'calcInProgress',
      legs: 'legs',
      orders: 'orders',
      onlyWaypoints: 'onlyWaypoints'
    }),
    ...mapGetters('map', ['multiLeg', 'ordersToDisplay'])
  },
  watch: {
    ordersToDisplay: function () {
      if (this.getMarkers()) {
        this.getMarkers().showFor(this.ordersToDisplay.map(order => order.id))
      }
    },
    onlyWaypoints: function () {
      if (this.onlyWaypoints) {
        this.getMarkers().showOnly(this.waypoints.map(waypoint => waypoint.marker))
      } else {
        this.getMarkers().showFor(this.ordersToDisplay.map(order => order.id))
      }
    },
    waypoints: function () {
      this.waypoints.forEach(function (waypoint, index) {
        waypoint.marker.setLabel({
          fontWeight: 'bold',
          color: 'white',
          text: `${index + 1}`
        })
      })
    },
    calcInProgress: function () {
      if (this.calcInProgress) {
        this.calculateRoute()
      }
    }
  },
  mounted: function () {
    this.loadGoogle()
    this.fetchOrders()
  },
  methods: {
    ...mapMutations('orders', {
      addOrder: 'addItem'
    }),
    ...mapMutations('map', ['stopCalc', 'clearLegs', 'addLegs', 'addMarker', 'updateOnlyWaypoints']),
    ...mapActions('map', ['tryAddWaypoint', 'fetchOrders']),
    loadGoogle () {
      if (google && google.maps && this.mapElement()) {
        this.initializeMap()
      } else {
        setTimeout(this.loadGoogle, 50)
      }
    },
    mapElement () {
      return document.querySelectorAll('.google-map')[0]
    },
    loadDeps () {
      if (this.meta.start_point) {
        this.getMarkers().setConfig({
          assets_url: this.meta.assets_url,
          clickCallback: this.markerSelected,
          premises: {
            name: this.meta.start_name,
            address: this.meta.start_address,
            addressId: this.meta.start_address_id,
            position: this.meta.start_point
          }
        })
        this.getMarkers().createMarkers(this.orders)
      } else {
        setTimeout(this.loadDeps, 50)
      }
    },
    initializeMap () {
      const mapDiv = this.mapElement()

      const options = {
        zoom: 7,
        center: new google.maps.LatLng({
          lat: 52.143188,
          lng: 19.245566
        })
      }
      this.map = new google.maps.Map(mapDiv, options)
      this.mapper = new Mapper(this.map)
      this.getMarkers().setMap(this.map)
      this.loadDeps()
    },
    calculateRoute () {
      this.mapper.calculateRoute(this)
      this.$store.commit('map/updateOnlyWaypoints', true)
    },
    markerSelected (marker) {
      if (marker.order && marker.order.id) {
        this.addOrder({
          id: marker.order.id
        })
      }
      this.tryAddWaypoint(marker)
    }
  }
}
</script>

<style scoped>
.google-map {
  width: 100%;
  height: 700px;
  margin: 0 auto;
  background: #eee;
  box-shadow: 5px 10px 8px #ddd;
}
</style>
