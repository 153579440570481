export default {
  pl: {
    archives: {
      create: 'Wykonaj archiwum',
      refresh: 'Odśwież stan archiwów',
      destroy: 'Usuń',
      destroy_files: 'Usuń pliki',
      toasts: {
        create: 'Zlecono utworzenie archiwum.',
        destroyed: 'Archiwum zostało usunięte.',
        filesDestroyed: 'Pliki zostały usunięte.'
      },
      dialog: {
        destroy: {
          title: 'Usuwanie archiwum',
          message: 'Czy chcesz usunąć <b>%{name}</b>?',
          cancelText: 'Anuluj',
          confirmText: 'Usuń archiwum'
        },
        filesDestroy: {
          title: 'Usuwanie plików (rozliczeń i wz-tek)',
          message: 'Czy chcesz usunąć pliki dotyczące <b>%{name}</b>?',
          cancelText: 'Anuluj',
          confirmText: 'Usuń pliki'
        }
      }
    },
    printSettings: {
      actionSuccess: {
        update: 'Ustawienia zostały zapisane.'
      },
      networkTitle: 'Drukowanie sieciowe',
      labelTitle: 'Wymiar etykiety',
      combinationTitle: 'Układ etykiet',
      title: 'Ustawienia drukowania',
      save: 'Zapisz ustawienia',
      help: 'Tu możesz ustawić preferencje związane z drukowaniem.',
      network: {
        disabled: 'Wyłączone',
        enabled: 'Włączone'
      },
      label: {
        v80x62: '80 mm x 62 mm',
        v90x38: '90 mm x 38 mm'
      },
      combination: {
        separate: 'Osobno na stronie',
        combine: 'Na jednej stronie'
      }
    },
    bundles: {
      found: 'Znalezione zestawienia'
    },
    printnode: {
      fields: {
        printerId: 'Identyfikator drukarki'
      },
      title: 'Serwis Printnode',
      using: 'Konfiguracja i weryfikacja działania serwisu Printnode.',
      save: 'Zapisz konfigurację',
      saved: 'Konfiguracja została zapisana',
      whoami: 'Informacje o koncie',
      printers: 'Drukarki',
      printjobs: 'Zlecenie wydruku',
      isLoading: 'Pobieranie danych...',
      subtitle: 'Weryfikacja działania',
      jobNok: 'PrintNode: (%{code}) %{message}',
      jobOk: 'PrintNode: Drukowanie (%{printJobId})'
    },
    appNotifications: {
      title: 'Powiadomienia',
      using: 'Powiadomienie może być wysłane do wszystkich użytkowników, jak również tylko do wybranych.',
      sendMessage: 'Wyślij wiadomość',
      sent: 'Wiadomość została wysłana',
      sendToAll: 'Do wszystkich',
      sendToSelected: 'Do wybranych',
      tagPlaceholder: 'Wpisz nazwę firmy lub e-mail',
      noUserFound: 'Nie znaleziono',
      enterMessage: 'Wpisz wiadomość',
      clearForm: 'Wyczyść formularz',
      fields: {
        message: 'Wiadomość',
        users: 'Użytkownicy',
        subject: 'Temat'
      }
    },
    settings: {
      preview: {
        title: 'Przykłady konfiguracji',
        statuses: 'Prezentacja statusów'
      },
      fields: {
        storedinSmsTemplate: 'Treść SMS-a dla statusu Magazyn',
        storedoutSmsTemplate: 'Treść SMS-a dla statusu Transport',
        settlementSmsTemplate: 'Treść SMS-a dla powiadomień dotyczących rozliczeń'
      },
      title: 'Ustawienia',
      using: 'Konfiguracja aplikacji.',
      smsTemplates: 'Ciąg znaków <NR> w szablonie będzie zastępowany numerem zlecenia.',
      save: 'Zapisz ustawienia',
      saved: 'Ustawienia zostały zapisane'
    },
    storein: {
      fields: {
        code: 'Numer paczki / zlecenia'
      }
    },
    storeout: {
      fields: {
        code: 'Numer paczki'
      }
    },
    store: {
      teaser: 'Wpisz kod z etykiety lub zeskanuj przy pomocy czytnika.',
      code: 'Z kodu usuwane są poprzedzające i następujące białe znaki, np: spacje.',
      content: 'W przypadku wpisywania z klawiatury wyłącz automatyczne zatwierdzanie.',
      commit: 'Zatwierdź',
      enter_code: 'Wpisz numer paczki',
      autocommit: 'Automatyczne zatwierdzanie',
      takeback: 'Zwrot',
      storein: {
        title: 'Przyjęcie magazynowe (PZ)',
        successfulMessage: 'Paczka została przyjęta.'
      },
      storeout: {
        title: 'Wydanie magazynowe (WZ)',
        successfulMessage: 'Paczka została wydana.'
      },
      status: 'Stan zleceń'
    },
    modal: {
      common: {
        cancel: 'Zamknij'
      },
      none: {
        title: 'Brak',
        message: 'Brak',
        button: 'Brak'
      },
      cancel: {
        title: 'Anulowanie zlecenia',
        message: 'Czy chcesz anulować to zlecenie?',
        button: 'Anuluj zlecenie'
      },
      stateChange: {
        title: 'Zmiana stanu zlecenia',
        message: 'Czy chcesz zmienić stan zlecenia na %{state}?',
        button: 'Zmień'
      }
    },
    sideHelp: {
      show: 'Pokaż pomoc',
      hide: 'Schowaj pomoc'
    },
    waypoints: {
      choose: 'Wybierz zapis',
      placeholder: 'Wpisz nazwę zapisu',
      notFound: 'Nie znaleziono zapisu',
      save: {
        tagTitle: 'Zapis punktów nawigacyjnych',
        dialogTitle: 'Podaj nazwę, pod którą chcesz zapisać.',
        placeholder: 'Wpisz nazwę, maks. 30 znaków',
        confirmText: 'Zapisz',
        cancelText: 'Anuluj',
        success: 'Punkty nawigacyjne zostały zapisane.'
      }
    },
    map: {
      codeReader: {
        label: 'Czytnik zleceń',
        placeholder: 'Wprowadź numer zlecenia',
        notFound: 'Nie znaleziono zlecenia o podanym numerze',
        badState: 'Znalezione zlecenie ma nieprawidłowy status',
        alreadyAdded: 'Zlecenie zostało już dodane'
      },
      filter: {
        label: 'Filtr',
        producer: 'Producent',
        storedin: 'Magazyn',
        storedout: 'Transport',
        postalCode: 'Wpisz kod pocztowy'
      },
      onlyWaypoints: 'Pokaż tylko punkty nawigacyjne na mapie',
      ordersToDisplay: 'Zlecenia',
      distance_unit: 'km',
      duration_unit_hour: 'godz.',
      duration_unit_min: 'min',
      duration_without_min: '%{hours} godz.',
      duration_with_min: '%{hours} godz. %{min} min',
      using1: 'Aby wyznaczyć trasę, należy wybrać co najmniej 2 miejsca na mapie.',
      using2: 'Na liście do wyznaczenia trasy może znajdować się maksymalnie 40 miejsc.',
      using3: 'Opcja Przybliżanie mapy przybliża trasę na mapie po jej wyznaczeniu.',
      loading: 'Ładowanie mapy...',
      calculateRoute: 'Wyznacz trasę',
      nonCalculatedRoute: 'Nie wyznaczono żadnej trasy.',
      showAllMarkers: 'Pokaż wszystkie punkty',
      duration: 'Czas: ',
      distance: 'Dystans: ',
      totals: 'Podsumowanie',
      removeAllWaypoints: 'Usuń punkty',
      choosenWaypoints: 'Punkty nawigacyjne',
      calculatedRoute: 'Wyznaczona trasa',
      zoomViewport: 'Przybliżenie mapy',
      googleError: 'Błąd wyznaczania trasy: %{status}.',
      addToBundle: 'Dodaj do zestawienia'
    },
    monthNames: ['Styczeń', 'Luty', 'Marzec', 'Kwiecień', 'Maj', 'Czerwiec', 'Lipiec', 'Sierpień', 'Wrzesień', 'Październik', 'Listopad', 'Grudzień'],
    dayNames: ['Ni', 'Po', 'Wt', 'Śr', 'Cz', 'Pi', 'So'],
    logout: 'Wyloguj',
    welcomeSearch: {
      placeholder: 'Wpisz numer przesyłki',
      action: 'Szukaj',
      noNumber: 'Wpisz numer przesyłki.'
    },
    v: {
      email: {
        required: 'Adres e-mail jest wymagany.',
        minLength: 'Adres e-mail musi mieć przynajmniej %{length} znak(i/ów)',
        maxLength: 'Adres e-mail może mieć co najwyżej %{length} znak(i/ów).'
      },
      phone_number: {
        required: 'Numer telefonu jest wymagany.'
      },
      selected: {
        noUsersSelected: 'Nie wybrano żadnego użytkownika.'
      },
      message: {
        required: 'Treść wiadomości jest wymagana.',
        minLength: 'Treść wiadomości musi mieć przynajmniej %{length} znak(i/ów)',
        maxLength: 'Treść wiadomości może mieć co najwyżej %{length} znak(i/ów).'
      },
      rawMessage: {
        required: 'Treść wiadomości jest wymagana.',
        minLength: 'Treść wiadomości musi mieć przynajmniej %{length} znak(i/ów)',
        maxLength: 'Treść wiadomości może mieć co najwyżej %{length} znak(i/ów).'
      },
      printerId: {
        required: 'Identyfikator drukarki jest wymagany.',
        numeric: 'Identyfikator drukarki musi być liczbą.'
      },
      first_name: {
        required: 'Imię jest wymagane.',
        minLength: 'Imię musi mieć przynajmniej %{length} znak(i/ów).',
        maxLength: 'Imię może mieć co najwyżej %{length} znak(i/ów).'
      },
      last_name: {
        required: 'Nazwisko jest wymagane.',
        minLength: 'Nazwisko musi mieć przynajmniej %{length} znak(i/ów).',
        maxLength: 'Nazwisko może mieć co najwyżej %{length} znak(i/ów).'
      },
      name: {
        required: 'Nazwa jest wymagana.',
        minLength: 'Nazwa musi mieć przynajmniej %{length} znak(i/ów)',
        maxLength: 'Nazwa może mieć co najwyżej %{length} znak(i/ów).'
      },
      phone_num: {
        required: 'Numer telefonu jest wymagany',
        phoneNumFormat: 'Numer telefonu powinien zawierać przynajmniej 9 cyfr. Dozwolone znaki: -, (, ), + i spacja.',
        mobilePhoneNumFormat: 'Numer telefonu musi składać się z 9 cyfr.',
        maxLength: 'Numer telefonu może mieć co najwyżej %{length} znak(i/ów).'
      },
      street: {
        required: 'Ulica jest wymagana.',
        minLength: 'Ulica musi mieć przynajmniej %{length} znak(i/ów).',
        maxLength: 'Ulica może mieć co najwyżej %{length} znak(i/ów).'
      },
      postal_code: {
        required: 'Kod pocztowy jest wymagany.',
        postalCodeFormatPL: 'Podany kod pocztowy jest nieprawidłowy (XX-XXX).',
        postalCodeFormatCZ: 'Podany kod pocztowy jest nieprawidłowy (XXX XX).'
      },
      city: {
        required: 'Miejscowość jest wymagana.',
        minLength: 'Miejscowość musi mieć przynajmniej %{length} znak(i/ów).',
        maxLength: 'Miejscowość może mieć co najwyżej %{length} znak(i/ów).'
      },
      state: {
        maxLength: 'Województwo może mieć co najwyżej %{length} znak(i/ów).'
      },
      building_num: {
        required: 'Numer budunku jest wymagany.',
        minLength: 'Numer budynku musi mieć przynajmniej %{length} znak(i/ów).',
        maxLength: 'Numer budynku może mieć co najwyżej %{length} znak(i/ów).'
      },
      flat_num: {
        maxLength: 'Numer mieszkania może mieć co najwyżej %{length} znak(i/ów).'
      },
      info: {
        maxLength: 'Dodatkowe informacje mogą mieć co najwyżej %{length} znak(i/ów).'
      },
      payment_value: {
        required: 'Kwota jest wymagana.',
        decimal: 'Kwota nie jest liczbą.',
        paymentValue: 'Kwota musi zawierać się między 0 a 100tys.'
      },
      vat_tax: {
        required: 'Kwota jest wymagana.',
        decimal: 'Kwota nie jest liczbą.',
        minValue: 'Kwota musi być dodatnia.'
      },
      company: {
        required: 'Nazwa firmy jest wymagana.',
        minLength: 'Nazwa firmy musi mieć przynajmniej %{length} znak(i/ów).',
        maxLength: 'Nazwa firmy może mieć co najwyżej %{length} znak(i/ów).'
      },
      parcel_add: {
        maxLength: 'Dodatek do paczek może mieć co najwyżej %{length} znak(i/ów).'
      },
      parcel_kind: {
        required: 'Rodzaj towaru jest wymagany.',
        minLength: 'Rodzaj towaru musi mieć przynajmniej %{length} znak(i/ów).',
        maxLength: 'Rodzaj towaru może mieć co najwyżej %{length} znak(i/ów).'
      },
      parcel_name: {
        required: 'Nazwa towaru jest wymagana.',
        minLength: 'Nazwa towaru musi mieć przynajmniej %{length} znak(i/ów).',
        maxLength: 'Nazwa towaru może mieć co najwyżej %{length} znak(i/ów).'
      },
      nip: {
        required: 'Numer NIP jest wymagany',
        nipNumFormat: 'Numer NIP jest nieprawidłowy.'
      },
      password: {
        required: 'Hasło jest wymagane.',
        minLength: 'Hasło musi mieć przynajmniej %{length} znak(i/ów).'
      },
      passwordConfirmation: {
        sameAsPassword: 'Potwierdzenie hasła nie jest takie samo jak hasło.'
      },
      storedinSmsTemplate: {
        required: 'Treść szablonu jest wymagana.',
        maxLength: 'Treść szablonu może mieć co najwyżej %{length} znak(i/ów).'
      },
      storedoutSmsTemplate: {
        required: 'Treść szablonu jest wymagana.',
        maxLength: 'Treść szablonu może mieć co najwyżej %{length} znak(i/ów).'
      },
      subject: {
        required: 'Temat jest wymagany.',
        minLength: 'Temat musi mieć przynajmniej %{length} znak(i/ów)',
        maxLength: 'Temat może mieć co najwyżej %{length} znak(i/ów).'
      },
      transport_cost: {
        decimal: 'Koszt musi byc liczbą.'
      }
    },
    account: {
      title: 'Ustawienia konta',
      config: 'Aby, tworzyć zlecenia, Twoje konto musi być prawidłowo skonfigurowane.',
      nip: 'Podanie numeru NIP jest opcjonalne.',
      update: 'Zapisz ustawienia',
      passwordConfig: 'Hasło powinno być łatwe do zapamiętania, ale trudne do zgadnięcia przez innych.',
      passwordChange: 'Zmiana hasła',
      changePassword: 'Zmień hasło',
      actionSuccess: {
        update: 'Ustawienia zostały zapisane.',
        passwordChange: 'Hasło zostało zmienione.'
      },
      company: 'Dane firmy'
    },
    form: {
      obligatory: 'Pole wymagane',
      search: 'Szukaj',
      searchAction: 'Szukaj',
      clearAction: 'Wyczyść',
      enterPattern: 'Wpisz szukany ciąg znaków',
      print: 'Wydruk (PDF)',
      selectDate: 'Wybierz datę',
      selectCreatedFromDate: 'Wybierz datę utworzenia',
      selectCreatedToDate: 'Wybierz datę utworzenia',
      searchFrom: 'Szukaj od',
      searchTo: 'Szukaj do',
      status: 'Status',
      enterString: 'Wpisz tekst',
      title: {
        createOrder: 'Nowe zlecenie',
        updateOrder: 'Aktualizacja zlecenia'
      },
      help: {
        createOrder: 'Aby utworzyć lub zaktualizować zlecenie, wypełnij wszystkie obowiązkowe pola.',
        obligatory: 'Pola obowiązkowe oznaczone są gwiazdką (*).',
        packages: 'Ilość paczek powinna zawierać między 1 a 100.',
        sms: 'Na podany numer telefonu, będzie wysyłany SMS informujący statusie zlecenia, dlatego ważne jest, aby podać prawidłowy numer komórkowy.',
        printing: 'Preferencje drukowania możesz ustawić z menu użytkownika.'
      },
      kindOfPayments: {
        paymentOnDelivery: 'Zapłata przy odbiorze',
        paymentPrepaid: 'Przedpłata'
      },
      transportKinds: {
        delivery: 'Dostawa',
        pickup: 'Odbiór',
        exchange: 'Wymiana'
      },
      countries: {
        pl: 'Polska',
        cz: 'Czechy'
      }
    },
    bundle: {
      form: {
        selectDate: 'Wybierz datę'
      },
      noOrders: 'Wybrane zestawienie nie ma przypisanych żadnych zleceń.',
      noRecord: 'Nie znaleziono żadnych zestawień.',
      fields: {
        departed_at: 'Data wyjazdu',
        edit: 'Edycja',
        name: 'Nazwa',
        created_at: 'Utworzone',
        updated_at: 'Aktualizacja',
        orders_count: 'Ilość zleceń',
        packages_count: 'Ilość paczek'
      },
      help: {
        form: 'Zlecenia do zestawienia można dodawać z widoku listy zleceń, jak również z widoku mapy.',
        destroy: 'Usunięcie zestawienia NIE powoduje usunięcia zlecenia do niego przypisanych.'
      },
      title: {
        createBundle: 'Nowe zestawienie',
        updateBundle: 'Aktualizacja zestawienia'
      },
      actions: {
        selected: 'Ilość wybranych zmówień: ',
        create: 'Utwórz zestawienie',
        update: 'Aktualizuj zestawienie',
        destroy: 'Usuń zestawienie',
        removeItems: 'Usuń zlecenia z zestawienia',
        storeoutItems: 'Zmień stan na transport',
        doneItems: 'Zmień stan na zrealizowane'
      },
      actionSuccess: {
        create: 'Zestawinie zostało utworzone.',
        update: 'Zestawinie zostało zaktualizowane.',
        destroy: 'Zestawienie zostało usunięte.',
        removeItems: 'Zlecenia zostały usunięte z zestawienia.',
        storeoutItems: 'Stan wybranych zleceń zmieniono na TRANSPORT',
        doneItems: 'Stan wybranych zleceń zmieniono na ZREALIZOWANE'
      },
      dialog: {
        destroy: {
          title: 'Usunięcie zestawienia',
          button: 'Usuń zestawienie',
          message: 'Czy chcesz usunąć poniższe zestawienie?'
        },
        removeItems: {
          title: 'Usunięcie zleceń z zestawienia',
          button: 'Usuń zlecenia z zestawienia',
          message: 'Czy chcesz usunąć zaznaczone zlecenia z zestawienia?'
        },
        storeoutItems: {
          title: 'Zmiana statusu',
          button: 'Zmień status',
          message: 'Czy chcesz zmienić status wybranych zleceń na TRANSPORT?'
        },
        doneItems: {
          title: 'Zmiana statusu',
          button: 'Zmień status',
          message: 'Czy chcesz zmienić status wybranych zleceń na ZREALIZOWANE?'
        }
      }
    },
    order: {
      order: 'Zlecenie',
      noRecord: 'Nie znaleziono żadnych zleceń.',
      statuses: {
        all: 'Wszystkie',
        producer: 'Producent',
        storedin: 'Magazyn',
        storedout: 'Transport',
        done: 'Zrealizowane',
        canceled: 'Anulowane'
      },
      kind_of_payment: {
        payment_on_delivery: 'Przy odbiorze',
        payment_prepaid: 'Przedpłata'
      },
      fields: {
        aasm_state: 'Status',
        address_detail: 'Adres',
        address_short: 'Adres',
        address: 'Adres',
        building_num: 'Nr budynku',
        bundled: 'Zestawienie',
        city: 'Miejscowość',
        client_name: 'Nazwa klienta',
        company: 'Firma',
        created_at: 'Utworzone',
        discarded_at: 'Anulowane',
        edit: 'Edycja',
        first_name: 'Imię',
        flat_num: 'Nr mieszkania',
        fullInfo: 'Informacja',
        geocoded: 'Adres zgeolokalizowany',
        info: 'Dodatkowe informacje',
        kind_of_payment: 'Rodzaj płatności',
        last_name: 'Nazwisko',
        lastStored: 'Ostatni numer',
        nip: 'Numer NIP',
        packages: 'Paczki',
        parcel_add: 'Dodatek do paczek',
        parcel_kind: 'Rodzaj towaru',
        parcel_name: 'Nazwa towaru',
        parcel: 'Towar',
        password: 'Hasło',
        passwordConfirmation: 'Potwierdzenie hasła',
        payment_value: 'Kwota',
        payment: 'Płatność',
        phone_num: 'Numer telefonu',
        postal_code: 'Kod pocztowy',
        quantity: 'Ilość paczek',
        quantityShort: 'Paczki',
        refid: 'Numer',
        transport_cost: 'Koszt transportu',
        sender: 'Firma',
        state: 'Województwo',
        storedin: 'Paczki przyjęte',
        storedinShort: 'Przyjęte',
        storedout: 'Paczki wydane',
        storedoutShort: 'Wydane',
        street: 'Ulica',
        updated_at: 'Aktualizacja',
        transportKind: 'Rodzaj transportu',
        transportKindShort: 'Rodzaj',
        country: 'Kraj'
      },
      actions: {
        create: 'Utwórz zlecenie',
        update: 'Aktualizuj zlecenie',
        print: 'Wydruk zlecenia',
        printLabels: 'Wydruk etykiet',
        cancel: 'Anulowanie',
        networkPrint: 'Wydruk sieciowy',
        separatePrint: 'Wydruk rozdzielony',
        spreadsheet: 'Eksport do arkusza'

      },
      actionSuccess: {
        create: 'Zlecenie zostało utworzone.',
        update: 'Zlecenie zostało zaktualizowane.',
        cancel: 'Zlecenie zostało anulowane.',
        stateChanged: 'Stan został zmieniony.'
      }
    },
    orders: {
      forBundle: 'Ilość zleceń',
      found: 'Znalezione zlecenia',
      selected: 'Zlecenia wybrane',
      geocodedFound: 'Zlecenia',
      clearSelected: 'Anuluj wybór',
      chooseBundle: 'Wybierz zestawienie',
      noBoundleFound: 'Nie znaleziono',
      bundle: 'Zestawienie',
      addToBundle: 'Dodaj do zestawienia',
      settlements: {
        choose: 'Wybierz rozliczenie',
        notFound: 'Nie znaleziono',
        settlement: 'Rozliczenie',
        addTo: 'Dodaj do rozliczenia'
      }
    },
    manifests: {
      ordersAdded: 'Zamówinia zostały przypisane do zestawienia.'
    },
    sms: {
      title: 'Serwis SMS',
      help: 'Weryfikacja działania serwisu SMS',
      checkService: 'Sprawdź połączenie z serwisem',
      fields: {
        phone_number: 'Numer MSISDN',
        message: 'Wiadomość'
      },
      sendMessage: 'Wyślij wiadomość'
    },
    navbar: {
      account: 'Ustawienia konta',
      appNotifications: 'Powiadomienia',
      appSettings: 'Ustawienia aplikacji',
      createOrder: 'Utwórz zlecenie',
      dashboard: 'Pulpit',
      map: 'Mapa',
      packagesHandling: 'Obsługa przesyłek',
      passwordChange: 'Zmiana hasła',
      printnode: 'Serwis PrintNode',
      printSettings: 'Ustawienia drukowania',
      sidekiq: 'Sidekiq',
      sms: 'Serwis SMS',
      storein: 'Przyjęcie magazynowe (PZ)',
      storeout: 'Wydanie magazynowe (WZ)',
      users: 'Użytkownicy',
      settlements: {
        title: 'Rozliczenia',
        list: 'List rozliczeń'
      },
      dispatchNotes: {
        title: 'Dokumenty WZ',
        list: 'Dokumenty WZ'
      },
      routes: {
        title: 'Trasy',
        list: 'Lista tras',
        addNew: 'Dodaj trasę'
      },
      bundles: {
        title: 'Zestawienia',
        list: 'Lista zestawień',
        addNew: 'Dodaj zestawienie'
      }
    },
    user: {
      docConfigs: {
        doc_default: 'Domyślna',
        doc_info: 'Dodaj dodatkowe informacje do rozliczenia'
      },
      actions: {
        update: 'Aktualizuj użytkownika'
      },
      title: {
        edit: 'Edycja użytkownika'
      },
      fields: {
        email: 'E-mail',
        name: 'Nazwa',
        docConfig: 'Konfiguracja dokumentów'
      },
      help: {
        form: 'Jeżeli Nazwa użytkownika będzie wpisana, będzie prezentowana zamiast nazwy firmy użytkownika'
      },
      actionSuccess: {
        destroy: 'Konto użytkownika zostało usunięte.',
        update: 'Użytkownik został zaktualizowany.'
      },
      dialog: {
        destroy: {
          title: 'Usuwanie użytkownika',
          message: 'Czy chcesz usunąć konto użytkownika?',
          button: 'Usuń użytkownika',
          notification: 'Usunięcie konta użytkownika jest nieodwracalne. Wraz z kontem usunięte zostaną również wszystkie związane z nim dane, np: zlecenia.'
        }
      }
    },
    users: {
      noRecord: 'Nie znaleziono żadnych użytkowników',
      found: 'Użytkownicy',
      fields: {
        culture_counters: 'Liczba zleceń / WZ / rozliczeń',
        created_at: 'Utworzony',
        email: 'Adres e-mail',
        admin: 'Uprawnienia administratorskie',
        confirmed: 'Adres e-email potwierdzony',
        company: 'Firma',
        phone_num: 'Nr tel.',
        nip: 'Nr NIP',
        configured: 'Konto skonfigurowane',
        destroy: 'Usunięcie konta użytkownika',
        orders_count: 'Liczba zleceń',
        edit: 'Edycja',
        name: 'Nazwa'
      }
    },
    routeItems: {
      noRecord: 'Nie znaleziono żadnych składowych.'
    },
    searchFilter: {
      title: 'Szukaj',
      placeholder: 'Wpisz ciąg znaków',
      action: 'Szukaj',
      clear: 'Wyczyść'
    },
    codeReader: {
      autoCommit: 'Automatyczne zatwierdzanie',
      manualCommit: 'Zatwierdź'
    },
    settlements: {
      codeReader: {
        label: 'Czytnik zleceń',
        placeholder: 'Wprowadź numer zlecenia'
      },
      items: {
        added: 'Zlecenie zostało dodane',
        noRecord: 'Rozliczenie nie zawiera żadnego składnika',
        destroy: 'Usunięcie składnika',
        dialog: {
          destroy: {
            title: 'Usuwanie składnika rozliczenia',
            message: 'Czy chcesz usunąć składnik <b>%{name}</b> ?',
            cancelText: 'Anuluj',
            confirmText: 'Usuń składnik'
          }
        },
        actionSuccess: {
          create: 'Składnik został dodany.',
          update: 'Składnik został zaktualizowany.',
          destroy: 'Składnik został usunięty.'
        }
      },
      sendSms: {
        button: 'Wyślij wiadomość',
        title: 'Wysłanie SMS-a',
        message: 'Czy chcesz wysłać SMS-a informującego o możliwości odbioru pieniędzy?',
        confirmText: 'Wyślij SMS',
        cancelText: 'Anuluj',
        sent: 'Wiadomość SMS została wysłana'
      },
      returnToProducer: 'Powrót do rozliczeń',
      found: 'Znalezione rozliczenia',
      addNew: 'Dodaj rozliczenie',
      noRecord: 'Nie znaleziono żadnych rozliczeń',
      producerNotFound: 'Nie znaleziono producenta o podanych kryteriach.',
      chooseProducer: 'Wybierz producenta',
      printBatch: 'Wydruk rozliczeń',
      help: {
        settlements: 'Aby zarządzać rozliczeniami, należy najpierw wybrać producenta.',
        form: 'Przed dodaniem składowej do rozliczenia należy rozlicznie utworzyć.',
        currency_update: 'Wartość kwoty jest automatycznie zapamiętywana po wyjściu z pola edycyjnego.'
      },
      fields: {
        info: 'Informacje',
        last_name: 'Nazwisko',
        postal_code: 'Kod pocztowy',
        city: 'Miejscowość',
        edit: 'Edycja',
        created_at: 'Utworzone',
        updated_at: 'Aktualizacja',
        name: 'Nazwa',
        dated_to: 'Datowane na',
        items_count: 'Ilość składowych',
        destroy: 'Usunięcie rozliczenia',
        payment_value: 'Kwota pobrania',
        transport_cost: 'Koszt transportu',
        payment_value_total: 'Kwota pobrania',
        transport_cost_total: 'Koszt transportu',
        payment_balance: 'Do wypłaty',
        user_ident: 'Producent',
        vat_tax: 'VAT'
      },
      titles: {
        new: 'Nowe rozliczenie',
        edit: 'Aktualizacja rozliczenia'
      },
      dialog: {
        destroy: {
          title: 'Usuwanie rozliczenia',
          message: 'Czy chcesz usunąć rozliczenie <b>%{name}</b> ?',
          cancelText: 'Anuluj',
          confirmText: 'Usuń rozliczenie'
        }
      },
      actionSuccess: {
        create: 'Rozliczenie zostało utworzone.',
        update: 'Rozlicznie zostało zaktualizowane.',
        destroy: 'Rozliczenie zostało usunięte.'
      },
      actions: {
        create: 'Utwórz rozliczenie',
        update: 'Aktualizuj rozliczenie',
        pdf: 'Wydruk'
      },
      form: {
        selectDate: 'Wybierz datę'
      }
    },
    dispatchNotes: {
      codeReader: {
        label: 'Czytnik zleceń',
        placeholder: 'Wprowadź numer zlecenia'
      },
      items: {
        created: 'Zlecenie zostało dodane',
        noRecord: 'Dokument WZ nie zawiera żadnego składnika',
        destroy: 'Usunięcie składnika',
        dialog: {
          destroy: {
            title: 'Usuwanie składnika dokumentu WZ',
            message: 'Czy chcesz usunąć składnik <b>%{name}</b> ?',
            cancelText: 'Anuluj',
            confirmText: 'Usuń składnik'
          }
        },
        actionSuccess: {
          create: 'Składnik został dodany.',
          update: 'Składnik został zaktualizowany.',
          destroy: 'Składnik został usunięty.'
        }
      },
      returnToProducer: 'Powrót do dokumentów',
      found: 'Znalezione dokumenty WZ',
      addNew: 'Dodaj dokument WZ',
      noRecord: 'Nie znaleziono żadnych dokumentów WZ',
      producerNotFound: 'Nie znaleziono producenta o podanych kryteriach.',
      chooseProducer: 'Wybierz producenta',
      printBatch: 'Wydruk dokumentów WZ',
      help: {
        dispatchNotes: 'Aby zarządzać dokumentami WZ, należy najpierw wybrać producenta.',
        form: 'Przed dodaniem składowej do dokumenu WZ należy dokument utworzyć.'
      },
      fields: {
        position: 'Lp.',
        client_name: 'Imię i nazwisko',
        parcel: 'Towar',
        quantity: 'Ilość',
        order_code: 'Kod zlecenia',
        item_info: 'Powód zwrotu',
        info: 'Info',
        ident: 'Numer',
        dispatched_at: 'Datowany na',
        last_name: 'Nazwisko',
        postal_code: 'Kod pocztowy',
        city: 'Miejscowość',
        edit: 'Edycja',
        created_at: 'Utworzone',
        updated_at: 'Aktualizacja',
        name: 'Nazwa',
        dated_to: 'Datowane na',
        items_count: 'Ilość składowych',
        destroy: 'Usunięcie dokumentu WZ',
        payment_value: 'Kwota pobrania',
        transport_cost: 'Koszt transportu',
        user_ident: 'Producent',
        vat_tax: 'VAT'
      },
      titles: {
        new: 'Nowy dokument WZ',
        edit: 'Aktualizacja dokumentu WZ'
      },
      dialog: {
        destroy: {
          title: 'Usuwanie dokumentu WZ',
          message: 'Czy chcesz usunąć dokument WZ <b>%{name}</b> ?',
          cancelText: 'Anuluj',
          confirmText: 'Usuń dokument WZ'
        }
      },
      actionSuccess: {
        create: 'Dokument WZ został utworzony.',
        update: 'Dokument WZ został zaktualizowany.',
        destroy: 'Dokument WZ został usunięty.'
      },
      actions: {
        create: 'Utwórz dokument WZ',
        update: 'Aktualizuj dokument WZ',
        pdf: 'Wydruk'
      },
      form: {
        selectDate: 'Wybierz datę'
      }
    },
    routes: {
      actions: {
        create: 'Utwórz trasę',
        update: 'Aktualizuj trasę',
        spreadsheet: 'Eksport do arkusza'
      },
      form: {
        selectDate: 'Wybierz datę'
      },
      help: {
        form: 'Po utworzeniu trasy możliwa jest tylko zmiana nazwy oraz kwot.',
        currency_update: 'Wartość kwoty jest automatycznie zapamiętywana po wyjściu z pola edycyjnego.',
        no_items: 'Trasa zostanie utworzona kiedy zawiera przynajmniej jedną składową.'
      },
      titles: {
        new: 'Nowa trasa',
        edit: 'Aktualizacja trasy'
      },
      dialog: {
        destroy: {
          title: 'Usuwanie trasy',
          message: 'Czy chcesz usunąć trasę <b>%{name}</b> ?',
          cancelText: 'Anuluj',
          confirmText: 'Usuń trasę'
        }
      },
      actionSuccess: {
        create: 'Trasa została utworzona.',
        update: 'Trasa została zaktualizowana.',
        destroy: 'Trasa została usunięta.'
      },
      found: 'Znalezione trasy',
      addNew: 'Dodaj trasę',
      noRecord: 'Nie znaleziono żadnych tras',
      fields: {
        edit: 'Edycja',
        created_at: 'Utworzona',
        updated_at: 'Aktualizacja',
        name: 'Nazwa',
        from: 'Zestawienia od',
        to: 'Zestawienia do',
        items_count: 'Ilość składowych',
        destroy: 'Usunięcie trasy',
        producer_ident: 'Producent',
        postal_code: 'Kod pocztowy',
        payment_value: 'Kwota pobrania',
        transport_cost: 'Koszt transportu'
      }
    }
  }
}
