export default {
  data: function () {
    return {
      item: {
        id: null,
        ident: null
      }
    }
  },
  methods: {
    destroyDialog (id, ident, key, onConfirm) {
      this.item.id = id
      this.item.ident = ident
      this.$buefy.dialog.confirm({
        title: this.$t(`${key}.title`),
        message: this.$t(`${key}.message`, { name: ident }),
        confirmText: this.$t(`${key}.confirmText`),
        cancelText: this.$t(`${key}.cancelText`),
        type: 'is-danger',
        hasIcon: true,
        onConfirm: onConfirm
      })
    }
  }
}
