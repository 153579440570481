<template>
  <div v-if="printable">
    <div class="order-print m-b-1">
      <a :href="selfUrl" target="_blank" rel="noopener" class="button pdf-button is-info is-fullwidth">
        <b-icon
          icon="print"
          size="is-small"
        />
        <span>
          {{ $t('order.actions.print') }}
        </span>
      </a>
    </div>
    <div class="order-packages">
      <div class="order-packages m-b-1">
        <button v-if="netPrint" class="button is-info is-fullwidth" @click="print">
          <b-icon
            icon="barcode"
            size="is-small"
          />
          <span>
            {{ $t('order.actions.printLabels') }}
          </span>
        </button>
        <a v-else :href="packagesPrintUrl" target="_blank" rel="noopener" class="button is-info is-fullwidth">
          <b-icon
            icon="barcode"
            size="is-small"
          />
          <span>
            {{ $t('order.actions.printLabels') }}
          </span>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import PrintNodeApi from 'js/api/PrintNodeApi'

export default {
  props: {
    order: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      papi: null
    }
  },
  computed: {
    ...mapState([
      'user',
      'userMeta',
      'userLoaded'
    ]),
    selfUrl () {
      return `${this.user.scope}/orders/${this.order.id}.pdf`
    },
    packagesPrintUrl () {
      let url = `${this.user.scope}/orders/`
      url += this.order.id + '/packages'
      return url
    },
    printable () {
      return !!this.order.id
    },
    netPrint () {
      return this.user.print_network === 'enabled'
    }
  },
  watch: {
    userLoaded: {
      immediate: true,
      handler: function () {
        if (this.userLoaded) {
          const meta = this.userMeta
          this.papi = new PrintNodeApi(meta.printnode_api_key, meta.printer_id, meta.pdfs_path)
        }
      }
    }
  },
  methods: {
    printJobOk (response) {
      this.$buefy.toast.open({
        type: 'is-success',
        message: this.$t('printnode.jobOk', { printJobId: response })
      })
    },
    printJobNok (response) {
      this.$buefy.toast.open({
        type: 'is-danger',
        message: this.$t('printnode.jobNok', { code: response.data.code, message: response.data.message })
      })
    },
    print () {
      this.$api.packages.print(this.order.id, this.separatePrint, (response) => {
        this.papi.printjob(response.data.filename,
          'packages',
          this.printJobOk,
          this.printJobNok
        )
      })
    }
  }
}
</script>
