<template>
  <div class="columns">
    <OrderConfirmationDialog
      v-show="confirmationDialog"
      :dialog="dialog"
      @cancelConfirmed="cancelOrderConfirmed"
      @stateChangeConfirmed="stateChangeConfirmed"
    />
    <div class="column is-3">
      <OrderProducerCard v-can:manage :order="form" />
      <OrderCard :order="form" />
      <OrderPrinting :order="form" />
      <SideHelp :items="['createOrder', 'obligatory', 'packages', 'sms', 'printing']" />
    </div>
    <div class="column is-9">
      <h1 v-if="form.id" class="title">
        {{ $t('form.title.updateOrder') }}
      </h1>
      <h1 v-else class="title">
        {{ $t('form.title.createOrder') }}
      </h1>
      <div class="page-section">
        <div class="columns">
          <div class="column is-3">
            <b-field :label="$t('order.fields.country')">
              <b-select v-model="form.country">
                <option
                  v-for="option in countries"
                  :key="option"
                  :value="option"
                >
                  {{ $t(`form.countries.${option}`) }}
                </option>
              </b-select>
            </b-field>
          </div>
        </div>
        <div class="columns">
          <div class="column is-4">
            <TextField
              v-model="form.street"
              field="street"
              obligatory
              :vform="$v.form"
              @input="$v.form.street.$touch()"
            />
          </div>
          <div class="column is-2">
            <TextField
              v-model="form.building_num"
              field="building_num"
              obligatory
              :vform="$v.form"
              @input="$v.form.building_num.$touch()"
            />
          </div>
          <div class="column is-2">
            <TextField
              v-model="form.flat_num"
              field="flat_num"
              :vform="$v.form"
              @input="$v.form.flat_num.$touch()"
            />
          </div>
        </div>
        <div class="columns">
          <div class="column">
            <TextField
              v-model="form.city"
              field="city"
              obligatory
              :vform="$v.form"
              @input="$v.form.city.$touch()"
            />
          </div>
          <div class="column">
            <TextField
              v-model="form.postal_code"
              field="postal_code"
              obligatory
              :vform="$v.form"
              @input="$v.form.postal_code.$touch()"
            />
          </div>
          <div class="column">
            <TextField
              v-model="form.state"
              field="state"
              :vform="$v.form"
              @input="$v.form.state.$touch()"
            />
          </div>
        </div>
      </div>
      <div class="page-section">
        <div class="columns">
          <div class="column is-3">
            <TextField
              v-model="form.first_name"
              field="first_name"
              obligatory
              :vform="$v.form"
              @input="$v.form.first_name.$touch()"
            />
          </div>
          <div class="column is-3">
            <TextField
              v-model="form.last_name"
              field="last_name"
              obligatory
              :vform="$v.form"
              @input="$v.form.last_name.$touch()"
            />
          </div>
          <div class="column is-3">
            <FormLabel attr="phone_num" :required="true" scope="order" />
            <b-field>
              <p class="control">
                <span v-if="form.country === 'cz'" class="button is-static">+420</span>
                <span v-else class="button is-static">+48</span>
              </p>
              <div class="control">
                <input v-model="form.phone_num" class="input" :class="{ 'is-danger': $v.form.phone_num.$error }" type="text" :placeholder="$t('form.enterString')" @input="$v.form.phone_num.$touch()">
              </div>
            </b-field>
            <FieldErrors :vform="$v.form" field="phone_num" />
          </div>
        </div>
      </div>
      <div class="page-section">
        <div class="columns">
          <div class="column is-3">
            <b-field :label="$t('order.fields.transportKind')">
              <b-select v-model="form.transport_kind">
                <option
                  v-for="option in transportKinds"
                  :key="option"
                  :value="option"
                >
                  {{ $t(`form.transportKinds.${option}`) }}
                </option>
              </b-select>
            </b-field>
          </div>
        </div>
        <div class="columns">
          <div class="column is-4">
            <TextField
              v-model="form.parcel_kind"
              field="parcel_kind"
              obligatory
              :vform="$v.form"
              @input="$v.form.parcel_kind.$touch()"
            />
          </div>
          <div class="column is-4">
            <TextField
              v-model="form.parcel_name"
              field="parcel_name"
              :vform="$v.form"
              @input="$v.form.parcel_name.$touch()"
            />
          </div>
        </div>
        <div class="columns">
          <div class="column is-3">
            <div class="field">
              <FormLabel attr="quantity" :required="true" scope="order" />
              <b-field>
                <b-numberinput v-model="form.quantity" min="1" max="100" />
              </b-field>
            </div>
          </div>
          <div class="column is-4">
            <TextField
              v-model="form.parcel_add"
              field="parcel_add"
              :vform="$v.form"
              @input="$v.form.parcel_add.$touch()"
            />
          </div>
        </div>
        <div class="columns">
          <div class="column is-3">
            <b-field :label="$t('order.fields.kind_of_payment')">
              <b-select v-model="form.kind_of_payment" @input="kindOfPaymentsIsChanged">
                <option
                  v-for="option in kindOfPayments"
                  :key="option.id"
                  :value="option.id"
                >
                  {{ $t(`form.kindOfPayments.${option.value}`) }}
                </option>
              </b-select>
            </b-field>
          </div>
          <div class="column is-4">
            <div v-if="isPaymentOnDelivery" class="field">
              <FormLabel attr="payment_value" scope="order" />
              <div class="control">
                <currency-input
                  v-model="form.payment_value"
                  :currency="paymentCurrency"
                  class="input"
                  :class="{ 'is-danger': $v.form.payment_value.$error }"
                  :placeholder="$t('form.enterString')"
                  @input="$v.form.payment_value.$touch()"
                />
              </div>
              <div v-if="$v.form.payment_value.$error">
                <p v-if="!$v.form.payment_value.required" class="help is-danger">
                  {{ $t('v.payment_value.required') }}
                </p>
                <p v-if="!$v.form.payment_value.decimal" class="help is-danger">
                  {{ $t('v.payment_value.decimal') }}
                </p>
                <p v-if="!$v.form.payment_value.paymentValue" class="help is-danger">
                  {{ $t('v.payment_value.paymentValue') }}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div v-can:manage class="columns">
          <div class="column is-3">
            <FormLabel attr="transport_cost" scope="order" />
            <div class="control">
              <currency-input
                v-model="form.transport_cost"
                class="input"
                :class="{ 'is-danger': $v.form.transport_cost.$error }"
                :placeholder="$t('form.enterString')"
                @input="$v.form.transport_cost.$touch()"
              />
            </div>
            <div v-if="$v.form.transport_cost.$error">
              <p v-if="!$v.form.transport_cost.decimal" class="help is-danger">
                {{ $t('v.transport_cost.decimal') }}
              </p>
            </div>
          </div>
        </div>
        <div class="columns">
          <div class="column">
            <div class="field">
              <FormLabel attr="info" scope="order" />
              <div class="control">
                <textarea v-model="form.info" class="textarea" :class="{ 'is-danger': $v.form.info.$error }" :placeholder="$t('form.enterString')" @input="$v.form.info.$touch()" />
              </div>
              <FieldErrors :vform="$v.form" field="info" />
            </div>
          </div>
        </div>
      </div>

      <OrderActions
        class="actions-bottom"
        :vobj="$v"
        :order="form"
        @create="createOrder"
        @update="updateOrder"
        @state-change="stateChange"
        @cancel="cancelOrder"
      />
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import Persisted from 'mixins/persisted'
import FormLabel from './FormLabel.vue'
import TextField from './TextField.vue'
import FieldErrors from './FieldErrors.vue'
import OrderActions from './OrderActions.vue'
import OrderCard from './OrderCard.vue'
import OrderProducerCard from './OrderProducerCard.vue'
import SideHelp from './SideHelp.vue'
import OrderPrinting from './OrderPrinting.vue'
import OrderConfirmationDialog from './OrderConfirmationDialog.vue'
import { required, minLength, maxLength, minValue, maxValue, decimal } from 'vuelidate/lib/validators'
import { mobilePhoneNumFormat, postalCodeFormatPL, postalCodeFormatCZ } from 'js/validators'
import { kindOfPayments, transportKinds, countries } from 'js/constants'

const initialForm = () => {
  return {
    aasm_state: '',
    building_num: '',
    bundle: {},
    city: '',
    first_name: '',
    flat_num: '',
    info: '',
    kind_of_payment: 'payment_on_delivery',
    last_name: '',
    parcel_kind: '',
    parcel_name: '',
    parcel_add: '',
    payment_value: 1.0,
    phone_num: '',
    postal_code: '',
    quantity: 1,
    state: '',
    status: '',
    street: '',
    transport_cost: 0.0,
    type: 'Order',
    transport_kind: 'delivery',
    country: 'pl'
  }
}

export default {
  components: {
    FormLabel, TextField, FieldErrors, OrderActions, OrderCard, SideHelp, OrderConfirmationDialog, OrderPrinting, OrderProducerCard
  },
  mixins: [Persisted],
  data () {
    return {
      dialog: {
        action: 'none',
        targetState: null
      },
      confirmationDialog: false,
      kindOfPayments,
      transportKinds,
      countries,
      form: initialForm()
    }
  },
  computed: {
    ...mapState([
      'user', 'userMeta', 'userLoaded'
    ]),
    isPaymentOnDelivery () {
      return this.form.kind_of_payment === 'payment_on_delivery'
    },
    paymentCurrency () {
      return this.form.country === 'cz' ? 'CZK' : 'PLN'
    }
  },
  methods: {
    updateData (response, action = null) {
      const data = response.data.attributes
      data.payment_value = parseFloat(data.payment_value)
      data.transport_cost = parseFloat(data.transport_cost)
      this.form = data
      if (action) {
        this.$buefy.toast.open({
          message: this.$t(`order.actionSuccess.${action}`),
          type: 'is-success'
        })
      }
    },
    load () {
      this.$api.orders.show(this.user.scope, this.id,
        (response) => {
          this.updateData(response)
        }
      )
    },
    kindOfPaymentsIsChanged () {
      if (!this.isPaymentOnDelivery) {
        this.form.payment_value = 0
      }
    },
    createOrder () {
      this.$api.orders.create(this.form,
        (response) => {
          this.updateData(response, 'create')
        })
    },
    updateOrder () {
      this.$api.orders.update(this.user.scope, this.form,
        (response) => {
          this.updateData(response, 'update')
        })
    },
    stateChange (targetState) {
      this.dialog.action = 'stateChange'
      this.dialog.targetState = targetState
      this.confirmationDialog = true
    },
    stateChangeConfirmed (targetState) {
      this.$api.orders.stateChange(this.user.scope, this.form.id, this.dialog.targetState,
        (response) => {
          this.updateData(response, 'stateChanged')
        })
    },
    cancelOrder () {
      this.dialog.action = 'cancel'
      this.confirmationDialog = true
    },
    cancelOrderConfirmed () {
      this.$api.orders.cancel(this.user.scope, this.form.id,
        (response) => {
          this.updateData(response, 'cancel')
        })
    },
    dialogClose () {
      this.confirmationDialog = false
    },
    resetForm () {
      this.form = initialForm()
    }
  },
  validations: {
    form: {
      first_name: {
        minLength: minLength(3),
        maxLength: maxLength(20)
      },
      last_name: {
        required,
        minLength: minLength(3),
        maxLength: maxLength(30)
      },
      phone_num: {
        required,
        mobilePhoneNumFormat
      },
      street: {
        required,
        maxLength: maxLength(30)
      },
      building_num: {
        required,
        minLength: minLength(1),
        maxLength: maxLength(10)
      },
      flat_num: {
        maxLength: maxLength(10)
      },
      postal_code: {
        required,
        postalCodeFormat (value) {
          if (this.form.country === 'pl') {
            return postalCodeFormatPL(value)
          } else {
            return postalCodeFormatCZ(value)
          }
        }
      },
      country: {
        required
      },
      state: {
        maxLength: maxLength(30)
      },
      city: {
        required,
        minLength: minLength(3),
        maxLength: maxLength(30)
      },
      info: {
        maxLength: maxLength(1000)
      },
      quantity: {
        required,
        minValue: minValue(1),
        maxValue: maxValue(100)
      },
      parcel_kind: {
        required,
        minLength: minLength(3),
        maxLength: maxLength(30)
      },
      parcel_name: {
        maxLength: maxLength(30)
      },
      parcel_add: {
        maxLength: maxLength(20)
      },
      transport_cost: {
        decimal
      },
      payment_value: {
        required,
        decimal,
        paymentValue (value) {
          if (!this.isPaymentOnDelivery) {
            return true
          }
          if (this.isPaymentOnDelivery && value > 0 && value < 100000) {
            return true
          } else {
            return false
          }
        }
      }
    }
  }
}
</script>
