import Axios from 'axios'
import NProgress from 'nprogress'

export default class Api {
  constructor (options) {
    const common = {
      responseType: 'json',
      withCredentials: false
    }
    this.axios = Axios.create(Object.assign(common, options))
    this.axios.interceptors.request.use(function (config) {
      NProgress.start()
      return config
    })
  }

  showToast (_message) {
    alert('showToast: Must me implemented')
  }

  gotResponse (response, onSuccess, onFail) {
    if (!response.data) {
      onSuccess()
      return
    }
    const data = response.data
    const errors = data.errors
    if (errors && errors.length) {
      this.showToast(errors[0].title)
      if (onFail !== undefined) {
        onFail(data)
      }
    } else {
      onSuccess(data)
    }
  }

  gotError (error, onFail) {
    if (error.response) {
      if (onFail !== undefined) {
        onFail(error.response)
      } else {
        console.error(`API Response Error: ${error.message}`)
        this.showToast(`API Response Error: ${error.message}`)
      }
    } else if (error.request) {
      console.error(`API Request Error: ${error}`)
      this.showToast(`API Request Error: ${error}`)
    } else {
      console.error(`API Error: ${error}`)
      this.showToast(`API Error: ${error}`)
    }
  }

  send (method, url, data, onSuccess, onFail, onAlways) {
    this.axios.request({
      method: method,
      url: url,
      data: data
    }).then((response) => {
      this.gotResponse(response, onSuccess, onFail)
    }).catch((error) => {
      this.gotError(error, onFail)
    }).then(() => {
      NProgress.done()
      NProgress.remove()
      if (onAlways !== undefined) {
        onAlways()
      }
    })
  }
}
