import { createNamespacedHelpers } from 'vuex'
const { mapState, mapGetters, mapActions } = createNamespacedHelpers('tables')

export default {
  data () {
    return {
      page: 1
    }
  },
  computed: {
    ...mapState(['items', 'loading', 'perPage', 'sortField', 'sortOrder']),
    ...mapGetters(['meta', 'total', 'paginated'])
  },
  methods: {
    ...mapActions(['onPage', 'onSort']),
    onSortChanged (field, order) {
      this.onSort({
        field,
        order
      })
      this.$emit('fetch')
    },
    onPageChanged (page) {
      this.onPage(page)
      this.$emit('fetch')
    }
  }
}
