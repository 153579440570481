import Api from './Api'

export default class PrintNodeApi extends Api {
  constructor (apiKey, printerId, pdfsPath) {
    super({
      auth: {
        username: apiKey
      },
      baseURL: 'https://api.printnode.com',
      headers: {
        'X-Pretty': ''
      }
    })
    this.printerId = printerId
    this.pdfsPath = pdfsPath
    this.siteURL = `${window.location.protocol}//${window.location.host}/${this.pdfsPath}`
  }

  whoami (onSuccess, onFail, onAlways) {
    this.send('GET', '/whoami', {}, onSuccess, onFail, onAlways)
  }

  printers (onSuccess, onFail, onAlways) {
    this.send('GET', '/printers', {}, onSuccess, onFail, onAlways)
  }

  printjobs (onSuccess, onFail, onAlways) {
    this.send('GET', '/printjobs?limit=20&dir=desc', {}, onSuccess, onFail, onAlways)
  }

  filenameURL (filename) {
    return this.siteURL + '/' + filename
  }

  printjob (filename, source, onSuccess, onFail) {
    this.send('POST', '/printjobs', {
      printerId: this.printerId,
      title: filename,
      contentType: 'pdf_uri',
      content: this.filenameURL(filename),
      source: source
    }, onSuccess, onFail)
  }
}
