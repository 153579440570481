import debounce from 'js/debounce'

export default {
  data () {
    return {
      settlements: {
        available: [],
        selected: null,
        isFetching: false
      }
    }
  },
  methods: {
    getSettlementsLazy: debounce(function (pattern) {
      this.getSettlements(pattern)
    }, 250),
    getSettlements (pattern) {
      if (!pattern.length) {
        this.settlements.available = []
        return
      }
      this.settlements.isFetching = true
      this.$api.settlements.index({
        pattern
      }, (response) => {
        this.settlements.available = []
        response.data.map(elem => elem.attributes).forEach((item) => this.settlements.available.push(item))
      })
      this.settlements.isFetching = false
    }
  }
}
