<template>
  <div class="container">
    <div class="columns">
      <div class="column is-3">
        <SideHelp :items="['config', 'nip']" scope="account" />
      </div>
      <div class="column">
        <h1 class="title">
          {{ $t('account.title') }}
        </h1>
        <article v-if="showMessage" class="message is-info">
          <div class="message-body">
            {{ $t('account.config') }}
          </div>
        </article>
        <div class="form">
          <div class="columns">
            <div class="column is-5">
              <h1 class="subtitle">
                {{ $t('account.company') }}
              </h1>
              <TextField
                v-model="account.company"
                field="company"
                obligatory
                :vform="$v.account"
                @input="$v.account.company.$touch()"
              />
            </div>
          </div>
          <div class="columns">
            <div class="column is-5">
              <TextField
                v-model="account.phone_num"
                field="phone_num"
                obligatory
                :vform="$v.account"
                @input="$v.account.phone_num.$touch()"
              />
            </div>
          </div>
          <div class="columns">
            <div class="column is-5">
              <TextField
                v-model="account.nip"
                field="nip"
                :vform="$v.account"
                @input="$v.account.nip.$touch()"
              />
            </div>
          </div>
          <div class="action-buttons actions-bottom">
            <button :disabled="$v.$invalid" class="button is-primary" @click="update">
              <b-icon
                icon="save"
                size="is-small"
              />
              <span>
                {{ $t('account.update') }}
              </span>
            </button>
          </div>
        </div>
        <api-account />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import SideHelp from './SideHelp.vue'
import TextField from './TextField.vue'
import { required, minLength, maxLength } from 'vuelidate/lib/validators'
import { phoneNumFormat, nipNumFormat } from 'js/validators'
import ApiAccount from './ApiAccount.vue'

export default {
  components: {
    ApiAccount,
    SideHelp,
    TextField
  },
  data () {
    return {
      account: {
        company: '',
        nip: '',
        phone_num: ''
      }
    }
  },
  computed: {
    ...mapState([
      'user'
    ]),
    showMessage () {
      return !!this.$route.params.showMessage
    }
  },
  mounted () {
    this.fetchData()
  },
  methods: {
    update () {
      this.$api.accountUpdate(this.account,
        (response) => {
          this.$buefy.toast.open({
            message: this.$t('account.actionSuccess.update'),
            type: 'is-success'
          })
          this.$store.dispatch('fetchUser')
        }
      )
    },
    fetchData () {
      this.$api.accountShow(
        (response) => {
          this.account = response.data.attributes
        }
      )
    }
  },
  validations: {
    account: {
      company: {
        required,
        minLength: minLength(5),
        maxLength: maxLength(30)
      },
      phone_num: {
        required,
        phoneNumFormat,
        maxLength: maxLength(15)
      },
      nip: {
        nipNumFormat
      }
    }
  }
}
</script>
