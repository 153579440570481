<template>
  <div>
    <div class="columns mb-2">
      <div class="column is-one-fifth">
        <Counters :items="counters" />
        <Search @search="searchChanged" />
        <SideHelp :items="['form', 'destroy']" scope="bundle.help" />
      </div>
      <div class="column">
        <BundlesTable />
      </div>
    </div>
  </div>
</template>

<script>
import Counters from './Counters.vue'
import Search from './Search.vue'
import BundlesTable from './BundlesTable.vue'
import { createNamespacedHelpers } from 'vuex'
import SideHelp from './SideHelp.vue'
const { mapState, mapGetters, mapActions, mapMutations } = createNamespacedHelpers('bundles')

export default {
  components: {
    Counters, Search, BundlesTable, SideHelp
  },
  data () {
    return {
    }
  },
  computed: {
    ...mapState(['items']),
    ...mapGetters(['total']),
    counters () {
      return [
        {
          label: this.$t('bundles.found'),
          value: this.total
        }
      ]
    }
  },
  mounted () {
    this.setSearch({})
    this.fetch()
  },
  methods: {
    ...mapMutations(['setSearch']),
    ...mapActions(['fetch', 'onSearch']),
    searchChanged (params) {
      this.onSearch(params)
    }
  }
}
</script>
