<template>
  <div class="columns">
    <div class="column is-3">
      <SideHelp :items="['form']" scope="user.help" />
    </div>
    <div class="column is-9">
      <h1 class="title">
        {{ $t('user.title.edit') }}
      </h1>
      <div class="columns">
        <div class="column is-6">
          <TextField
            v-model="form.email"
            scope="user"
            field="email"
            obligatory
            :vform="$v.form"
            @input="$v.form.email.$touch()"
          />
          <TextField
            v-model="form.name"
            scope="user"
            field="name"
            obligatory
            :vform="$v.form"
            @input="$v.form.name.$touch()"
          />
          <b-field :label="$t('user.fields.docConfig')">
            <b-select v-model="form.doc_config">
              <option
                v-for="option in docConfigs"
                :key="option"
                :value="option"
              >
                {{ $t(`user.docConfigs.${option}`) }}
              </option>
            </b-select>
          </b-field>
          <UserActions
            :vobj="$v"
            :user="form"
            @update="update"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import TextField from './TextField.vue'
import UserActions from './UserActions.vue'
import SideHelp from './SideHelp.vue'
import Persisted from 'mixins/persisted'
import { required, minLength, maxLength } from 'vuelidate/lib/validators'
import { docConfigs } from 'js/constants'

const initialForm = () => {
  return {
    name: '',
    email: '',
    doc_config: ''
  }
}
export default {
  components: {
    TextField, SideHelp, UserActions
  },
  mixins: [Persisted],
  data () {
    return {
      form: initialForm(),
      docConfigs
    }
  },
  computed: {
    ...mapState([
      'user', 'userLoaded'
    ])
  },
  methods: {
    updateData (response, action = null) {
      this.form = response.data.attributes
      if (action) {
        this.$buefy.toast.open({
          message: this.$t(`user.actionSuccess.${action}`),
          type: 'is-success'
        })
      }
    },
    load () {
      this.$api.users.show(this.id,
        (response) => {
          this.updateData(response)
        }
      )
    },
    update () {
      this.$api.users.update(this.form,
        (response) => {
          this.updateData(response, 'update')
        })
    }
  },
  validations: {
    form: {
      email: {
        required,
        minLength: minLength(1),
        maxLength: maxLength(30)
      },
      name: {
        minLength: minLength(1),
        maxLength: maxLength(20)
      }
    }
  }
}
</script>
