<template>
  <div class="container">
    <div class="columns">
      <div class="column is-3">
        <SideHelp :items="['passwordConfig']" scope="account" />
      </div>
      <div class="column">
        <h1 class="title">
          {{ $t('account.passwordChange') }}
        </h1>
        <div class="columns">
          <div class="column is-5">
            <TextField
              v-model="form.password"
              field="password"
              obligatory
              :vform="$v.form"
              type="password"
              @input="$v.form.password.$touch()"
            />
          </div>
        </div>
        <div class="columns">
          <div class="column is-5">
            <TextField
              v-model="form.passwordConfirmation"
              field="passwordConfirmation"
              obligatory
              :vform="$v.form"
              type="password"
              @input="$v.form.passwordConfirmation.$touch()"
            />
          </div>
        </div>
        <div class="action-buttons actions-bottom">
          <button :disabled="$v.$invalid" class="button is-primary" @click="update">
            <b-icon
              icon="save"
              size="is-small"
            />
            <span>
              {{ $t('account.changePassword') }}
            </span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SideHelp from './SideHelp.vue'
import TextField from './TextField.vue'
import { required, minLength, sameAs } from 'vuelidate/lib/validators'
export default {
  components: {
    SideHelp, TextField
  },
  data  () {
    return {
      form: {
        password: '',
        passwordConfirmation: ''
      }
    }
  },
  methods: {
    update () {
      this.$api.passwordChange(this.form,
        (response) => {
          this.$buefy.toast.open({
            message: this.$t('account.actionSuccess.passwordChange'),
            type: 'is-success'
          })
          this.form.password = this.form.passwordConfirmation = ''
        }
      )
    }
  },
  validations: {
    form: {
      password: {
        required,
        minLength: minLength(10)
      },
      passwordConfirmation: {
        sameAsPassword: sameAs('password')
      }
    }
  }
}
</script>
