<template>
  <section class="mb-3">
    <b-field :label="$t('map.filter.label')">
      <b-switch
        v-model="storedin"
        size="is-small"
      >
        {{ $t('map.filter.storedin') }}
      </b-switch>
      <b-switch
        v-model="producer"
        size="is-small"
      >
        {{ $t('map.filter.producer') }}
      </b-switch>
      <b-switch
        v-model="storedout"
        size="is-small"
      >
        {{ $t('map.filter.storedout') }}
      </b-switch>
    </b-field>
    <b-field>
      <b-input v-model="postalCode" :placeholder="$t('map.filter.postalCode')" />
    </b-field>
  </section>
</template>

<script>
import debounce from '../js/debounce'

export default {
  computed: {
    producer: {
      get () {
        return this.$store.state.map.producer
      },
      set (value) {
        this.$store.commit('map/updateProducer', value)
      }
    },
    storedin: {
      get () {
        return this.$store.state.map.storedin
      },
      set (value) {
        this.$store.commit('map/updateStoredin', value)
      }
    },
    storedout: {
      get () {
        return this.$store.state.map.storedout
      },
      set (value) {
        this.$store.commit('map/updateStoredout', value)
      }
    },
    postalCode: {
      get () {
        return this.$store.state.map.postalCode
      },
      set (value) {
        this.postalCodeChanged(value)
      }
    }
  },
  methods: {
    postalCodeChanged: debounce(function (value) {
      this.$store.commit('map/updatePostalCode', value)
    }, 250)
  }
}
</script>
