<template>
  <div class="container">
    <div class="columns">
      <div class="column is-3">
        <SideHelp :items="['help']" scope="printSettings" />
      </div>
      <div class="column">
        <h1 class="title">
          {{ $t('printSettings.title') }}
        </h1>
        <div class="form">
          <div class="columns">
            <div class="column">
              <div class="column">
                <div v-can:manage class="network m-b-1">
                  <h1 class="subtitle">
                    {{ $t('printSettings.networkTitle') }}
                  </h1>
                  <b-switch
                    v-model="form.network"
                    size="is-small"
                    true-value="enabled"
                    false-value="disabled"
                  >
                    {{ $t(`printSettings.network.${form.network}`) }}
                  </b-switch>
                </div>
                <div class="print-label m-b-1">
                  <h1 class="subtitle">
                    {{ $t('printSettings.labelTitle') }}
                  </h1>
                  <b-radio
                    v-model="form.label"
                    name="label"
                    native-value="v80x62"
                  >
                    {{ $t("printSettings.label.v80x62") }}
                  </b-radio>
                  <b-radio
                    v-model="form.label"
                    name="label"
                    native-value="v90x38"
                  >
                    {{ $t("printSettings.label.v90x38") }}
                  </b-radio>
                </div>
                <div class="combination">
                  <h1 class="subtitle">
                    {{ $t('printSettings.combinationTitle') }}
                  </h1>
                  <b-radio
                    v-model="form.combination"
                    name="combination"
                    native-value="separate"
                  >
                    {{ $t("printSettings.combination.separate") }}
                  </b-radio>
                  <b-radio
                    v-model="form.combination"
                    name="combination"
                    native-value="combine"
                  >
                    {{ $t("printSettings.combination.combine") }}
                  </b-radio>
                </div>
              </div>
            </div>
          </div>
          <div class="action-buttons actions-bottom">
            <button class="button is-primary" @click="update">
              <b-icon
                icon="save"
                size="is-small"
              />
              <span>
                {{ $t('printSettings.save') }}
              </span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import SideHelp from './SideHelp.vue'

export default {
  components: {
    SideHelp
  },
  data () {
    return {
      form: {
        network: 'disabled',
        label: 'v80x62',
        combination: 'separate'
      }
    }
  },
  computed: {
    ...mapState([
      'user'
    ])
  },
  mounted () {
    this.fetchData()
  },
  methods: {
    update () {
      this.$api.printSettings.update(this.form,
        (response) => {
          this.$buefy.toast.open({
            message: this.$t('printSettings.actionSuccess.update'),
            type: 'is-success'
          })
          this.$store.dispatch('fetchUser')
        }
      )
    },
    fetchData () {
      this.$api.printSettings.show(
        (response) => {
          this.form = response.data.attributes
        }
      )
    }
  }
}
</script>
