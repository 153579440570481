import Accessor from './Accessor.js'

const VueCanPlugin = {
  install (Vue) {
    Vue.directive('can', {
      bind (el, binding, vnode) {
        el._display = el.style.display
        Accessor(el, binding, vnode)
      },
      update (el, binding, vnode) {
        Accessor(el, binding, vnode)
      }
    })
  }
}
export default VueCanPlugin
