export default class Manifests {
  constructor (parent) {
    this.parent = parent
  }

  baseUrl () {
    return '/admin/orders/change-status.json'
  }

  changeStatus (params, onSuccess) {
    this.parent.send('POST', this.baseUrl(), params, onSuccess)
  }
}
