<template>
  <div v-if="vobj.$error">
    <p v-if="field == 'selected' && !vobj.noUsersSelected" class="help is-danger">
      {{ $t(`v.${field}.noUsersSelected`) }}
    </p>
    <p v-if="params.required && !vobj.required" class="help is-danger">
      {{ $t(`v.${field}.required`) }}
    </p>
    <p v-if="params.numeric && !vobj.numeric" class="help is-danger">
      {{ $t(`v.${field}.numeric`) }}
    </p>
    <p v-if="params.minLength && !vobj.minLength" class="help is-danger">
      {{ $t(`v.${field}.minLength`, { length: params.minLength.min }) }}
    </p>
    <p v-if="params.maxLength && !vobj.maxLength" class="help is-danger">
      {{ $t(`v.${field}.maxLength`, { length: params.maxLength.max }) }}
    </p>
    <p v-if="params.hasOwnProperty('phoneNumFormat') && !vobj.phoneNumFormat" class="help is-danger">
      {{ $t(`v.${field}.phoneNumFormat`) }}
    </p>
    <p v-if="params.hasOwnProperty('mobilePhoneNumFormat') && !vobj.mobilePhoneNumFormat" class="help is-danger">
      {{ $t(`v.${field}.mobilePhoneNumFormat`) }}
    </p>
    <p v-if="params.hasOwnProperty('nipNumFormat') && !vobj.nipNumFormat" class="help is-danger">
      {{ $t(`v.${field}.nipNumFormat`) }}
    </p>
    <p v-if="params.hasOwnProperty('postalCodeFormat') && !vobj.postalCodeFormat" class="help is-danger">
      <span v-if="vform.country.$model === 'pl'">{{ $t(`v.${field}.postalCodeFormatPL`) }}</span>
      <span v-if="vform.country.$model === 'cz'">{{ $t(`v.${field}.postalCodeFormatCZ`) }}</span>
    </p>
    <p v-if="params.sameAsPassword && !vobj.sameAsPassword" class="help is-danger">
      {{ $t(`v.${field}.sameAsPassword`) }}
    </p>
  </div>
</template>

<script>
export default {
  props: {
    vform: {
      type: Object,
      required: true
    },
    field: {
      type: String,
      required: true
    }
  },
  computed: {
    vobj () {
      return this.vform[this.field]
    },
    params () {
      return this.vobj.$params
    }
  }
}
// <pre>
// {{ Object.keys(vform[field].$params) }}
// {{ vform[field]|prettify }}
// </pre>
</script>
