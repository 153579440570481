<template>
  <b-table
    :data="items"
    :loading="loading"
    :paginated="paginated"
    backend-pagination
    pagination-position="both"
    :checked-rows.sync="checkedRows"
    checkable
    :total="total"
    :per-page="meta.perPage"
    :current-page.sync="page"
    aria-next-label="Next page"
    aria-previous-label="Previous page"
    aria-page-label="Page"
    aria-current-label="Current page"
    backend-sorting
    :default-sort-direction="sortOrder"
    :default-sort="[sortField, sortOrder]"
    @page-change="onPageChanged"
    @sort="onSortChanged"
    @check="itemChecked"
    @check-all="allChecked"
  >
    <b-table-column v-slot="props" field="name" :label="$t('dispatchNotes.fields.name')">
      <router-link :to="{ name: 'dispatchNoteEdit', params: { id: props.row.id }}">
        {{ props.row.name }}
      </router-link>
    </b-table-column>
    <b-table-column v-slot="props" field="ident" :label="$t('dispatchNotes.fields.ident')">
      {{ props.row.ident }}
    </b-table-column>
    <b-table-column v-slot="props" field="dispatched_at" :label="$t('dispatchNotes.fields.dispatched_at')">
      {{ props.row.dispatched_at }}
    </b-table-column>
    <b-table-column v-slot="props" field="items_count" :label="$t('dispatchNotes.fields.items_count')">
      {{ props.row.items_count }}
    </b-table-column>
    <b-table-column field="id" :label="$t('dispatchNotes.fields.destroy')" centered>
      <template slot="header">
        <b-tooltip
          :label="$t('dispatchNotes.fields.destroy')"
        >
          <b-icon
            icon="trash"
            size="is-small"
          />
        </b-tooltip>
      </template>
      <template #default="props">
        <b-button @click="$emit('destroy', props.row.id, props.row.name)">
          <b-icon
            class="destroy"
            icon="trash"
            size="is-small"
            type="is-danger"
          />
        </b-button>
      </template>
    </b-table-column>
    <template slot="empty">
      <section class="section">
        <div class="content has-text-grey has-text-centered">
          <p>
            <b-icon
              icon="times-circle"
              size="is-large"
            />
          </p>
          <p>{{ $t('dispatchNotes.noRecord') }}</p>
        </div>
      </section>
    </template>
  </b-table>
</template>

<script>
import TableMixin from '../mixins/table.js'

export default {
  mixins: [TableMixin],
  data () {
    return {
      checkedRows: []
    }
  },
  methods: {
    itemChecked (rows) {
      this.$emit('check', rows)
    },
    allChecked (rows) {
      this.$emit('check', rows)
    }
  }
}
</script>
