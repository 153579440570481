<template>
  <div>
    <StorageService type="storein" />
  </div>
</template>

<script>
import StorageService from './shared/StorageService.vue'

export default {
  components: {
    StorageService
  }
}
</script>

<style scoped>
</style>
