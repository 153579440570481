<template>
  <div v-if="order.id" class="card">
    <div class="card-header">
      <p class="card-header-title order-refid">
        {{ order.refid }}
      </p>
      <p class="card-header-title order-state">
        <StatusTag :status="order.aasm_state" icon />
      </p>
    </div>
    <div class="card-content">
      <div class="content">
        <ul>
          <li v-for="attr in attrs" :key="attr.name">
            {{ $t(`order.fields.${attr.name}`) }}: <strong>{{ attr.value }}</strong>
          </li>
        </ul>
      </div>
    </div>
    <OrderBundles v-can:manage :bundle="order.bundle || {}" />
  </div>
</template>

<script>
import { mapState } from 'vuex'
import OrderBundles from './OrderBundles.vue'
import StatusTag from './StatusTag.vue'

export default {
  components: {
    OrderBundles, StatusTag
  },
  props: {
    order: {
      type: Object,
      required: true
    }
  },
  computed: {
    ...mapState([
      'user'
    ]),
    attrs () {
      const arr = [
        {
          name: 'created_at',
          value: this.order.created_at
        },
        {
          name: 'updated_at',
          value: this.order.updated_at
        }
      ]
      if (this.order.discarded_at) {
        arr.push({
          name: 'discarded_at',
          value: this.order.discarded_at
        })
      }
      if (this.user.admin) {
        arr.push({
          name: 'storedin',
          value: this.order.storedin
        },
        {
          name: 'storedout',
          value: this.order.storedout
        })
      }
      return arr
    }
  }
}
</script>

<style scoped>
ul {
  margin: 0;
  font-size: small;
  list-style: square;
}

.order-state {
  justify-content: end;
}
</style>
