<template>
  <div class="columns">
    <div class="column is-one-fifth">
      <SideHelp :items="['teaser', 'code', 'content']" scope="store" />
    </div>
    <div class="column">
      <h1 class="title">
        {{ $t(`store.${type}.title`) }}
      </h1>
      <div v-show="showBundles" class="columns">
        <div class="column is-4">
          <b-field :label="$t('orders.bundle')">
            <b-autocomplete
              rounded
              :data="bundles.available"
              :placeholder="$t('orders.chooseBundle')"
              icon="search"
              field="name"
              :loading="bundles.isFetching"
              @typing="getBundlesLazy"
              @select="bundleSelected"
            >
              <template slot-scope="props">
                {{ props.option.name }}
              </template>
              <template slot="empty">
                {{ $t('orders.noBoundleFound') }}
              </template>
            </b-autocomplete>
          </b-field>
        </div>
      </div>
      <div v-show="isBundleSelected || !showBundles" class="columns">
        <div class="column is-4">
          <div class="field">
            <div class="control">
              <FormLabel attr="code" :required="true" :scope="transScope" />
              <input
                ref="input"
                v-model.trim="code"
                type="text"
                name="code"
                class="input"
                :placeholder="$t('store.enter_code')"
              >
            </div>
          </div>
        </div>
      </div>
      <div v-show="isBundleSelected || !showBundles" class="columns">
        <div class="column">
          <div class="field">
            <b-switch
              v-model="autocommit"
              size="is-small"
            >
              {{ $t('store.autocommit') }}
            </b-switch>
          </div>
          <div v-if="canTakeback" class="field">
            <b-switch
              v-model="takeback"
              size="is-small"
            >
              {{ $t('store.takeback') }}
            </b-switch>
          </div>
          <transition name="fade">
            <div v-if="!autocommit">
              <div class="action-buttons actions-bottom">
                <b-button
                  type="is-primary"
                  icon-left="check"
                  @click="apiManualCommit"
                >
                  {{ $t('store.commit') }}
                </b-button>
              </div>
            </div>
          </transition>
        </div>
      </div>
      <div class="columns orders-table-for-store">
        <div class="column">
          <h1 v-if="isBundleSelected" class="subtitle">
            {{ $t(`store.status`) }}
          </h1>
          <OrdersTableForStore v-if="isBundleSelected" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers, mapState } from 'vuex'
import BundlesFetch from 'mixins/bundlesFetch'
import debounce from 'js/debounce'
import SideHelp from 'components/SideHelp.vue'
import FormLabel from 'components/FormLabel.vue'
import OrdersTableForStore from 'components/OrdersTableForStore.vue'
const { mapActions } = createNamespacedHelpers('orders')

export default {
  components: {
    SideHelp, FormLabel, OrdersTableForStore
  },
  mixins: [
    BundlesFetch
  ],
  props: {
    type: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      code: '',
      autocommit: true,
      takeback: false,
      isLoading: false
    }
  },
  computed: {
    ...mapState([
      'user', 'userLoaded'
    ]),
    codePresent () {
      return this.code.length > 0
    },
    showBundles () {
      return this.type === 'storeout'
    },
    canTakeback () {
      return this.type === 'storein'
    },
    query () {
      const obj = {
        code: this.code,
        takeback: this.takeback
      }
      if (this.isBundleSelected) {
        Object.assign(obj, {
          bundle_id: this.bundles.selected.id
        })
      }
      return obj
    },
    transScope () {
      return `${this.type}`
    }
  },
  watch: {
    isBundleSelected () {
      this.$refs.input.focus()
    },
    code () {
      if (this.autocommit) {
        this.apiAutoCommit()
      }
    }
  },
  methods: {
    ...mapActions({
      clearAll: 'clearAll',
      fetchOrders: 'fetch'
    }),
    bundleSelected (option) {
      this.bundles.selected = option
      this.refreshOrders()
    },
    apiSend () {
      const vm = this
      if (this.codePresent) {
        this.$api.storage.store(this.type, this.query,
          function (response) {
            vm.code = ''
            vm.$buefy.toast.open({
              message: vm.$t(`store.${vm.type}.successfulMessage`),
              type: 'is-success'
            })
            vm.refreshOrders()
          }
        )
      }
    },
    apiAutoCommit: debounce(function () {
      this.apiSend()
    }, 250),
    apiManualCommit () {
      this.apiSend()
    },
    refreshOrders () {
      if (this.isBundleSelected) {
        this.fetchOrders({
          reset: true,
          bundle: this.bundles.selected.id,
          scope: this.user.scope
        })
      }
    }
  }
}
</script>

<style>
#orderset {
  border: 0;
  box-shadow: none;
}
.no-result {
  color: #c60f13;
}
.multiselect {
  margin-bottom: 1em;
}
.info-box {
  padding: 10px;
  margin-bottom: 1em;
  background-color: #f2d5b5;
  border-radius: 5px;
  border: 1px solid #da793c;
  color: #b91c32;
}
.info-box-takeback {
  text-align: center;
}
.orders-for-store {
  margin-bottom: 2em;
}
</style>
