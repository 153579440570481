export default class Users {
  constructor (parent) {
    this.parent = parent
  }

  baseUrl (id = null) {
    if (id) {
      return `admin/users/${id}.json`
    } else {
      return 'admin/users.json'
    }
  }

  index (params, onSuccess) {
    this.parent.send('GET', `admin/users.json?${this.parent.serializeParams(params)}`, params, onSuccess)
  }

  producers (params, onSuccess) {
    this.parent.send('GET', `admin/producers.json?${this.parent.serializeParams(params)}`, params, onSuccess)
  }

  forNotify (onSuccess) {
    this.parent.send('GET', 'admin/users_for_notify.json', {}, onSuccess)
  }

  notify (params, onSuccess) {
    this.parent.send('POST', 'admin/users_notify.json', params, onSuccess)
  }

  destroy (id, onSuccess) {
    this.parent.send('DELETE', `admin/users/${id}.json`, {}, onSuccess)
  }

  show (id, onSuccess) {
    this.parent.send('GET', `admin/users/${id}.json`, {}, onSuccess)
  }

  settlements (id, params, onSuccess) {
    this.parent.send('GET', `admin/users/${id}/settlements.json?${this.parent.serializeParams(params)}`, {}, onSuccess)
  }

  dispatchNotes (id, params, onSuccess) {
    this.parent.send('GET', `admin/users/${id}/dispatch-notes.json?${this.parent.serializeParams(params)}`, {}, onSuccess)
  }

  update (params, onSuccess) {
    const id = params.id
    this.parent.send('PATCH', this.baseUrl(id), params, onSuccess)
  }
}
