<template>
  <div class="action-buttons">
    <button
      v-if="!bundle.id"
      :disabled="formInvalid"
      class="button is-primary"
      @click="$emit('create')"
    >
      <b-icon icon="plus-circle" size="is-small" />
      <span>{{ $t('bundle.actions.create') }}</span>
    </button>
    <button
      v-else
      v-can:modify="bundle"
      :disabled="formInvalid"
      class="button is-primary"
      @click="$emit('update')"
    >
      <b-icon icon="save" size="is-small" />
      <span>{{ $t('bundle.actions.update') }}</span>
    </button>
    <button v-if="bundle.id" class="button is-danger" @click="$emit('destroy')">
      <b-icon icon="trash" size="is-small" />
      <span>{{ $t('bundle.actions.destroy') }}</span>
    </button>
  </div>
</template>

<script>
export default {
  props: {
    bundle: {
      type: Object,
      required: true
    },
    vobj: {
      type: Object,
      required: true
    }
  },
  data () {
    return {}
  },
  computed: {
    formInvalid () {
      return this.vobj.$invalid
    }
  }
}
</script>

<style scoped>
</style>
