import Api from './Api'
import Vue from 'vue'
import { ToastProgrammatic as Toast } from 'buefy'
import Batch from './Batch.js'
import Bundles from './Bundles.js'
import Manifests from './Manifests.js'
import Maps from './Maps.js'
import Notes from './Notes.js'
import Orders from './Orders.js'
import Packages from './Packages.js'
import PrintSettings from './PrintSettings.js'
import Routes from './Routes.js'
import Settings from './Settings.js'
import Settlements from './Settlements.js'
import DispatchNotes from './DispatchNotes.js'
import Storage from './Storage.js'
import Users from './Users.js'
import Waypoints from './Waypoints.js'
import ApiAccount from './ApiAccount'
import Archives from './Archives.js'

Vue.mixin({
  beforeCreate () {
    const options = this.$options
    if (options.api) {
      this.$api = options.api
    } else if (options.parent && options.parent.$api) {
      this.$api = options.parent.$api
    }
  }
})

class AppApi extends Api {
  constructor () {
    super({
      baseURL: window.location.protocol + '//' + window.location.host,
      headers: {
        'X-CSRF-Token': document.querySelectorAll('meta[name=csrf-token]')[0].getAttribute('content')
      }
    })
    this.batch = new Batch(this)
    this.bundles = new Bundles(this)
    this.manifests = new Manifests(this)
    this.maps = new Maps(this)
    this.notes = new Notes(this)
    this.orders = new Orders(this)
    this.packages = new Packages(this)
    this.printSettings = new PrintSettings(this)
    this.settings = new Settings(this)
    this.storage = new Storage(this)
    this.users = new Users(this)
    this.routes = new Routes(this)
    this.settlements = new Settlements(this)
    this.dispatchNotes = new DispatchNotes(this)
    this.waypoints = new Waypoints(this)
    this.apiAccount = new ApiAccount(this)
    this.archives = new Archives(this)
  }

  showToast (message) {
    Toast.open({
      message: message,
      type: 'is-danger',
      duration: 5000
    })
  }

  accountParams (form) {
    return {
      account: {
        company: form.company,
        phone_num: form.phone_num,
        nip: form.nip
      }
    }
  }

  passwordParams (form) {
    return {
      password: {
        password: form.password,
        password_confirmation: form.passwordConfirmation
      }
    }
  }

  serializeParams (queryObj) {
    return Object.keys(queryObj)
      .filter((key) => queryObj[key])
      .map((key) => `${key}=${queryObj[key]}`).join('&')
  }

  searchPackage (number, onSuccess) {
    this.send('GET', `search?number=${number}`, {}, onSuccess)
  }

  accountShow (onSuccess) {
    this.send('GET', 'home/account.json', {}, onSuccess)
  }

  accountUpdate (form, onSuccess) {
    this.send('POST', 'home/account.json', this.accountParams(form), onSuccess)
  }

  passwordChange (form, onSuccess) {
    this.send('POST', 'home/password.json', this.passwordParams(form), onSuccess)
  }

  currentUser (onSuccess) {
    this.send('GET', 'current_user.json', {}, onSuccess)
  }

  smsCheckService (onSuccess) {
    this.send('GET', 'admin/sms.json', {}, onSuccess)
  }

  smsSendMessage (smsObj, onSuccess) {
    this.send('POST', 'admin/sms.json', {
      sms: smsObj
    }, onSuccess)
  }
}

const appApi = new AppApi()
export default appApi
