
const orderStatusIcon = function (status) {
  const icons = {
    producer: 'poi-blue.png',
    storedin: 'poi-red.png',
    storedout: 'poi-yellow.png'
  }
  return icons[status] || 'poi-green.png'
}

const config = {
  maxWaypoints: 40,
  maxLegWaypoints: 25
}

export {
  orderStatusIcon,
  config
}
