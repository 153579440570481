<template>
  <div class="route-leg">
    <div v-if="legs.length > 0" class="route mb-3">
      <b-field label="Korekta na odcinek (min.)">
        <b-numberinput v-model="wayPointAdj" size="is-small" step="5" :editable="false" />
      </b-field>
      <b-field label="Korekta na trasę (min.)">
        <b-numberinput v-model="routeAdj" size="is-small" step="5" :editable="false" />
      </b-field>
      <div class="route-total">
        <div route-total-title>
          {{ $t('map.calculatedRoute') }}
        </div>
        <div class="route-total-results">
          <ul>
            <li>{{ $t('map.duration') }}<strong>{{ totalDuration }}</strong></li>
            <li>{{ $t('map.distance') }}<strong>{{ totalDistance }}</strong></li>
          </ul>
        </div>
      </div>
      <div v-for="(leg, index) in legs" :key="index" class="leg">
        <div class="leg-address">
          {{ extractInfo(leg.start_address) }}
        </div>
        <div class="leg-results">
          <ul>
            <li>{{ $t('map.duration') }}<strong>{{ duration(leg.duration.value) }}</strong></li>
            <li>{{ $t('map.distance') }}<strong>{{ leg.distance.text }}</strong></li>
          </ul>
        </div>
      </div>
      <div class="leg-address">
        {{ extractInfo(legs[legs.length-1].end_address) }}
      </div>
    </div>
    <b-notification v-else type="is-warning" :closable="false">
      {{ $t('map.nonCalculatedRoute') }}
    </b-notification>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { totalDistance as disCalc, durationAdjust, totalDuration as totalDurCalc } from '../js/mapper_calc'

export default {
  data () {
    return {
      wayPointAdj: 0,
      routeAdj: 0
    }
  },
  computed: {
    ...mapState('map', [
      'legs'
    ]),
    totalDistance () {
      return disCalc(this.legs) + ' ' + this.$t('map.distance_unit')
    },
    totalDuration () {
      var duration = totalDurCalc(this.legs, this.wayPointAdj, this.routeAdj)
      return this.prettyDuration(duration)
    }
  },
  methods: {
    extractInfo (text) {
      const words = text.split(', ')
      return words[0] + ', ' + words[1]
    },
    prettyDuration (duration) {
      if (duration.min > 0) {
        return this.$t('map.duration_with_min', duration)
      } else {
        return this.$t('map.duration_without_min', { hours: duration.hours })
      }
    },
    duration (wayDur) {
      return this.prettyDuration(durationAdjust(wayDur, this.wayPointAdj))
    }
  }
}
</script>

<style scoped>
.section {
  padding: 1em 0;
}
.leg-results {
  padding: .6em;
  font-size: .8em;
}
.leg-address {
  padding: .6em;
  font-size: .8em;
  text-align: center;
  border: 1px solid #209cee;

  border-radius: .3em;
  font-weight: bold;
}
.route-total {
  margin-bottom: 1em;
  background-color: #9bc9f2;
  padding: .6em;
  text-align: center;
  border: 1px solid #209cee;
  border-radius: .3em;
}
</style>
